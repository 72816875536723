import React from 'react'
import "./care-instructions.css";

export default function CareInstructions() {
  return (
    <>
    <div className="care-container">
        <div className="care-container-heading ">
            <h1 className='heading'>Washing</h1>
        </div>
        <div className="first-div">
            <div className="content">
                <h3>Wash Temperature</h3>
                <p>Always wash your Supima cotton t-shirt in<br />
                    cold water. Hot water can cause the fabric to<br />
                    shrink and lose its softness.
                </p>
                <img src="/Logos/celsius.png" alt="" />
            </div>
            <div className="image"><img src="/Logos/temperat.webp" alt=""  /></div>
        </div>
        <div className="right-div-container" >
            <div className="right-content">
                <h3>Wash Cycle</h3>
                <p>Opt for a gentle or delicate wash cycle to <br />
                    prevent excessive wear and tear on the <br />
                    fabric.
                </p>
                <img src="/Logos/washing-machine.png" alt="" />
            </div>
            <div className="image"><img src="/Logos/was.webp" alt="" /></div>
        </div>
        <div className="first-div">
            <div className="content">
                <h3>Detergent</h3>
                <p>Use a mild detergent specifically designed <br />
                    for delicate fabrics. Avoid using harsh <br />
                    detergents or bleach, as they can damage <br />
                    the cotton fibers and cause color fading.
                </p>
                <img src="/Logos/laundry-detergent.png" alt="" />
            </div>
            <div className="image big-image"><img src="/Logos/Detergent5.webp" alt="" /></div>
        </div>
        <div className="right-div-container" >
            <div className="right-content">
                <h3>Turn Inside Out</h3>
                <p>Before washing, turn your t-shirt inside out. <br />
                    This helps protect the exterior from abrasion <br />
                    and prevents pilling.
                </p>
                <img src="/Logos/t-shirt.png" alt="" />
            </div>
            <div className="image big-image"><img src="/Logos/tshirt.webp" alt="" /></div>
        </div>
        <div className="care-container-heading care-container-heading1">
            <h1 className='heading'>Drying</h1>
        </div>
        <div className="first-div">
            <div className="content">
                <h3>Air Dry</h3>
                <p>For optimal care, air dry your Supima cotton <br />
                    t-shirt. Hang it on a clothesline or a drying <br />
                    rack in a shaded area. Avoid direct sunlight, <br />
                    as it can cause color fading.
                </p>
                <img src="/Logos/air.png" alt="" />
            </div>
            <div className="image big-image"><img src="/Logos/line dry.webp" alt="" /></div>
        </div>
        <div className="right-div-container" >
            <div className="right-content">
                <h3>Tumble Dry (Optional)</h3>
                <p>If you prefer to tumble dry, use a low heat <br />
                    setting. However, air drying is generally <br />
                    recommended to preserve the fabric's <br />
                    quality and prevent shrinkage. <br />
                </p>
                <img src="/Logos/drying-machine.png" alt="" />
            </div>
            <div className="image big-image"><img src="/Logos/line dry.webp" alt="" /></div>
        </div>
        <div className="care-container-heading care-container-heading1">
            <h1 className='heading'>Ironing</h1>
        </div>
        <div className="first-div">
            <div className="content">
                <h3>Iron on Low</h3>
                <p>If necessary, iron your Supima cotton t-shirt <br />
                    on a low heat setting. Ironing can help <br />
                    restore the fabric's shape and remove any <br />
                    wrinkles.
                </p>
                <img src="/Logos/iron.png" alt="" />
            </div>
            <div className="image"><img src="/Logos/Iron2.webp" alt="" /></div>
        </div>
        <div className="right-div-container" >
            <div className="right-content">
                <h3>Iron Inside Out</h3>
                <p>As with washing, iron your t-shirt inside out <br />
                to protect the exterior.
                </p>
            </div>
            <div className="image big-image"><img src="/Logos/Step-By-Step-Guide-To-Ironing-On-Letters-To-A-T-Shirt-Png.webp" alt="" /></div>
        </div>
    </div>
    </>
  )
}
