import React from 'react'

export default function Shippingpolicy() {
  return (
    <>
                <div className='termscondition-main'>
                <div className='container'>
                    <div className='termscondition'>
                        <h2>SHIPPING POLICY</h2>
                        <p>At NSTEE, we are dedicated to delivering your stylish selections in a timely and efficient manner. Our shipping policy outlines the details of our shipping procedures to ensure a smooth and satisfactory shopping experience:-</p>
                        <h3>1. Order Processing:</h3>
                        <ol type='a'>
                          <li>Orders are typically processed within 2 business days of receiving payment and order confirmation.</li>
                          <li>Orders placed on weekends or holidays will be processed on the following business day.</li>
                        </ol>
                        <h3>2. Shipping Rates:</h3>
                        <ol type='a'>
                          <li>Shipping rates are calculated based on the selected shipping method, destination, and order weight.</li>
                          <li>Orders above 3000 amount may qualify for free Standard Shipping within India.</li>
                        </ol>
                        <h3>3. Tracking Information:</h3>
                        <ol type='a'>
                          <li>Once your order is shipped, you will receive a shipping confirmation email containing tracking information.</li>
                          <li>You can track the status of your shipment using the provided tracking number on our website or the carrier's website.</li>
                        </ol>
                        <h3>4. Order Changes and Cancellations:</h3>
                        <ol type='a'>
                          <li>If you need to make changes to your order or cancel it, please contact us as soon as possible.</li>
                          <li>Order changes or cancellations may not be possible once the order has been processed for shipping.</li>
                        </ol>
                        <h3>5. Address Accuracy:</h3>
                        <ol type='a'>
                          <li>Please ensure that your shipping address is accurate and complete. We cannot be held responsible for delays or non-delivery due to incorrect or incomplete addresses.</li>
                        </ol>
                        <h3>6. Customs and Duties (International Orders):</h3>
                        <ol type='a'>
                          <li>For international orders, customs duties, taxes, and fees may be applicable upon delivery. These charges are the responsibility of the recipient and may vary depending on the destination country's regulations.</li>
                        </ol>
                        <h3>7. Order Tracking and Support:</h3>
                        <ol type='a'>
                          <li>For any inquiries or concerns regarding your order's status or shipment, please contact our customer service team at <a href='mailto:contact@nstee.in'> contact@nstee.in. </a> We're here to assist you</li>
                        </ol>
                        <h3>8. Delays and Force Majeure:</h3>
                        <ol type='a'>
                          <li>While we strive to deliver your order on time, unforeseen circumstances such as weather delays or carrier issues may impact delivery times. We appreciate your understanding in such situations.</li>
                        </ol>
                        <h3>9. Returns of Undeliverable Packages:</h3>
                        <ol type='a'>
                          <li>If a package is deemed undeliverable due to an incorrect address or other reasons, it will be returned to us. In such cases, we will reach out to you to arrange for reshipment. Additional shipping fees may apply.</li>
                          <li>Please note that NSTEE reserves the right to update or modify this shipping policy at any time. Any changes will be communicated on our website. We are committed to providing you with exceptional service and ensuring your orders are delivered to your satisfaction.</li>
                        </ol>
                        <p>Thank you for choosing NSTEE for your fashion needs. We appreciate your trust in our brand.</p>
                    </div>
                </div>
            </div>
    </>
  )
}
