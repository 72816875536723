import React from 'react'
import "./giftCard.css"

export default function GiftCard() {
  return (
    <>
    <div className="giftcard-main-container">
        <div className="giftcard-main-heading">
            <h1>Gift Card</h1>
        </div>
        <div className="giftcard-main-image">
            <div className="sub-img-container" id='sub-img-container'><img src="" alt="" /></div>
            <div className="sub-img-container" id='sub-text-container'><h5>Gift your friends the testimon of</h5></div>
        </div>
        <div className='giftcard-sub-heading'><h5>Standard Gift Cards</h5></div>
        <div className="std-giftcard">
            <div className="std-giftcard-left">
                <div className="std-giftcard-image"><span>₹2000</span></div>
                <div className="std-giftcard-image"><span>₹4000</span></div>
            </div>
            <div className="std-giftcard-right">
                <div className="std-giftcard-image"><span>₹6000</span></div>
                <div className="std-giftcard-image"><span>₹8000</span></div>
            </div>
        </div>
        <div className='giftcard-sub-heading'> <h5>Premium Gift Cards</h5></div>
        <div className="pre-giftcard">
            <div className="pre-giftcard-left">
                <div className="pre-giftcard-image"><span>₹10,000</span></div>
                <div className="pre-giftcard-image"><span>₹15,000</span></div>
            </div>
            <div className="pre-giftcard-right">
                <div className="pre-giftcard-image"><span>₹25,000</span></div>
                <div className="pre-giftcard-image"><span>₹50,000</span></div>
            </div>

        </div>
    </div>
    </>
  )
}
