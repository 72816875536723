import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar'
import moment from 'moment';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiurl } from '../../urls/url';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from 'jwt-decode';
import { saveAs } from 'file-saver';


function UserOrders() {
    const [orders, setOrders] = useState([])
    const [user, setUser] = useState({});
    const [pending, setpending] = useState(0);
    const [fulfilled, setfulfilled] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwtDecode(token); 
                // console.log(decoded)
                setUser(decoded)
                if (decoded?.vendor_id > 0) {
                    navigate('/'); 
                }
            } catch (error) {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    },[navigate]);
    useEffect(() => {
        if(user.user_id){
            axios.get(`${apiurl}/customer/orders/${user.user_id}/`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
              }
              }).then((res) => {
                setOrders(res.data)
                res.data.forEach((element, index) => {
                    if(element.order_status==="Pending"){
                        setpending(pending+1)
                    }
                    else if(element.order_status==="Fulfilled"){
                        setfulfilled(fulfilled+1)
                    }
                });
            }).catch(
                (error) => {
                  if(error.response){
                    if(error.response.status===401){
                      localStorage.removeItem("token");
                      navigate('/login');
                    }
                  }
                }
              );
        }
    }, [user])
    const downloadPdf=async(url)=>{
        try {
            const response = await fetch(url);
            const blob = await response.blob();
      
            // Use the FileSaver.js library to save the file directly
            saveAs(blob, 'Invoice.pdf'); // 'file.pdf' is the name of the downloaded file
          } catch (error) {
            console.error('Error downloading the PDF:', error);
        }

    }
    const fetchPdfUrl = async (o_id) => {
        // console.log(o_id)
        try {
          const response = await axios.post(
            `${apiurl}/shiprocket/invoice/generate/`,
            {
              "ids": [o_id],
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
      
          // Check if the response data and invoice_url are present
          if (response.data && response.data.invoice_url) {
            downloadPdf(response.data.invoice_url);
          } else {
            alert("Invoice currently not generated");
          }
        } catch (error) {
          // Handle error response
          console.error("Error fetching invoice URL:", error);
        }
      };
      const fetchPdf = async (o_id) => {
        console.log(o_id)
        try {
          const response = await axios.get(`${apiurl}/shiprocket-orders/${o_id}/`);
      
          // Check if the response data is an array and has items, or directly check for the existence of `order_id`
          console.log("gkhl",response.data);
          if (response.data &&response.data.length!==0 ) {
            
            fetchPdfUrl(response.data[0].order_id);
          } else {
            alert("Invoice Currently Not Generated");
          }
        } catch (error) {
          // Handle error response
          console.error("Error fetching order details:", error.response ? error.response.data : error.message);
        //   alert("Failed to fetch order details. Please try again later.");
        }
      };


    return (
        <div className="container mt-5">
            <section className="">
                <div className="row">
                    <Sidebar />
                    <div className="col-lg-9 mt-4">
                        <main className="mb-5" style={{}}>
                            <div className="container px-4">
                                <section className="mb-5">
                                    <h3 className="mb-3">
                                        {" "}
                                        <i className="fas fa-shopping-cart text-dark" /> Orders{" "}
                                    </h3>
                                    <div className="row gx-xl-5">
                                        <div className="col-lg-4 mb-4 mb-lg-0">
                                            <div
                                                className="rounded shadow"
                                                style={{ backgroundColor: "#B2DFDB" }}
                                            >
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center p-3">
                                                        <div className="">
                                                            <p className="mb-1"> <i
                                                                    className="fas fa-shopping-cart fs-6"
                                                                    style={{ color: "#004D40" }}
                                                                />&nbsp;Orders</p>
                                                            <h2 className="mb-0">
                                                                {orders.length}
                                                                <span
                                                                    className=""
                                                                    style={{ fontSize: "0.875rem" }}
                                                                ></span>
                                                            </h2>
                                                        </div>
                                                        {/* <div className="flex-grow-1 ms-5">
                                                            <div className="p-3 badge-primary rounded-4">
                                                                <i
                                                                    className="fas fa-shopping-cart fs-4"
                                                                    style={{ color: "#004D40" }}
                                                                />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-4 mb-lg-0">
                                            <div
                                                className="rounded shadow"
                                                style={{ backgroundColor: "#D1C4E9" }}
                                            >
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center p-3">
                                                        <div className="">
                                                            <p className="mb-1"><i className="fas fa-clock fs-6" style={{ color: "#6200EA" }}/>&nbsp;Pending Delivery </p>
                                                            <h2 className="mb-0">
                                                                {pending}
                                                                <span
                                                                    className=""
                                                                    style={{ fontSize: "0.875rem" }}
                                                                ></span>
                                                            </h2>
                                                        </div>
                                                        {/* <div className="flex-grow-1 ms-5">
                                                            <div className="p-3 badge-primary rounded-4">
                                                                <i className="fas fa-clock fs-4" style={{ color: "#6200EA" }}/>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-4 mb-lg-0">
                                            <div
                                                className="rounded shadow"
                                                style={{ backgroundColor: "#BBDEFB" }}
                                            >
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center p-3">
                                                        <div className="">
                                                            <p className="mb-1"><i
                                                                    className="fas fa-check-circle fs-6"
                                                                    style={{ color: "#01579B" }}
                                                                />&nbsp;Fulfilled Orders</p>
                                                            <h2 className="mb-0">
                                                                {fulfilled}
                                                                <span
                                                                    className=""
                                                                    style={{ fontSize: "0.875rem" }}
                                                                ></span>
                                                            </h2>
                                                        </div>
                                                        {/* <div className="flex-grow-1 ms-5">
                                                            <div className="p-3 badge-primary rounded-4">
                                                                <i
                                                                    className="fas fa-check-circle fs-4"
                                                                    style={{ color: "#01579B" }}
                                                                />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* Section: Summary */}
                                {/* Section: MSC */}
                                <section className="">
                                    <div className="row rounded shadow p-3 mt-5">
                                        <div className="col-lg-12 mb-4 mb-lg-0 h-100 table-responsive">
                                            <table className="table align-middle mb-0 bg-white">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>Order ID</th>
                                                        <th>Payment Status</th>
                                                        <th>Date</th>
                                                        <th>Order Status</th>
                                                        <th>Total</th>
                                                        <th>Invoice</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.map((o, index) => (
                                                        <tr>
                                                            <td>
                                                                <p className="fw-bold mb-1">{o.oid}</p>
                                                                
                                                            </td>
                                                            <td><p className="text-muted mb-0">{ o.payment_status.toUpperCase()}</p></td>
                                                            <td>
                                                                <p className="fw-normal mb-1">{moment(o.date).format('MM/DD/YYYY')}</p>
                                                            </td>
                                                            <td>
                                                                <p className="fw-normal mb-1">{o.order_status}</p>
                                                            </td>
                                                            <td>
                                                                <span className="fw-normal mb-1">₹{o.total}</span>
                                                            </td>
                                                            <td>
                                                                <Link><span style={{marginLeft:"10px",cursor:"pointer"}} onClick={()=>fetchPdf(o.oid)}><i className="fas fa-eye" /></span></Link>
                                                            </td>
                                                            <td>
                                                                <Link className="btn btn-link btn-sm btn-rounded" to={`/user-profile/order/detail/${o.oid}/`} style={{textDecoration:"none"}}>
                                                                    <i className="fas fa-eye" />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                        <canvas id="myChart" style={{ width: "100%" }} />
                                    </div>
                                </section>
                                {/* Section: MSC */}
                            </div>
                            {/* Container for demo purpose */}
                        </main>
                    </div>
                </div>
            </section>
            {/*Section: Wishlist*/}
        </div>

    )
}

export default UserOrders