import { React, useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom'
import axios from 'axios';
import { apiurl } from '../../urls/url';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from 'jwt-decode';
import { Cookies } from 'react-cookie';
// import UseProfileData from '../plugin/UseProfileData'


function Sidebar() {
    let [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [userprofile, setuserprofile] = useState();
    const navigate = useNavigate();

    const userprofileinfo = () => {
      axios.get(`${apiurl}/customer/setting/${user.user_id}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
      }
      })
          .then((res) => {
              console.log(res.data)
              setuserprofile(res.data)
              setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
              if(error.response){
                if(error.response.status===401){
                  localStorage.removeItem("token");
                  navigate('/login');
                }
              }
            }
          );
    }
    useEffect(()=>{
      if(user.user_id){
        userprofileinfo()
      }
    },[user])
    const logout = () => {
        const cookies = new Cookies();
        localStorage.removeItem('token')
    }


    useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
          try {
              const decoded = jwtDecode(token); 
              console.log(decoded)
              setUser(decoded)
              if (decoded?.vendor_id > 0) {
                  navigate('/'); 
              }
          } catch (error) {
              navigate('/login');
          }
      } else {
          navigate('/login');
      }
  },[navigate]);

    return (
        <div className="col-lg-3">
            {loading === false &&
                <>
                    <div className="d-flex justify-content-center align-items-center flex-column mb-4 shadow rounded-3 pt-4">
                        {/* <img
                            src={userprofile?.image}
                            style={{ width: 120, height: 120, borderRadius: "50%", objectFit: "contain" }}
                            alt=""
                        /> */}
                        <div className="text-center">
                            <h3 className="mb-1">{user?.full_name}</h3>
                            <h5 style={{fontSize:"15px"}}>{user?.email}</h5>
                            <p className="mt-0">
                                <Link to="/user-profile/settings/" className=' text-decoration-none'><i className='fas fa-edit me-2'></i> Edit Account</Link>
                            </p>
                        </div>
                    </div>
                    <ol className="list-group" >
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <Link to={'/user-profile/'} className="fw-bold text-dark text-decoration-none"> <i className='fas fa-user me-2'></i> Account</Link>
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <a href='/user-profile/orders/' className="fw-bold text-dark text-decoration-none"><i className='fas fa-shopping-cart me-2'></i>Orders</a>
                            </div>
                        </li>
                        {/* <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <Link to={'/customer/wishlist/'} className="fw-bold text-dark text-decoration-none"><i className='fas fa-heart fa-fade me-2'></i> Wishlist</Link>
                            </div>
                            <span className="badge bg-primary rounded-pill">14</span>
                        </li> */}
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <Link to={'/user-profile/notifications/'} className="fw-bold text-dark text-decoration-none"><i className='fas fa-bell fa-shake me-2'></i> Notification</Link>
                            </div>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <Link to={'/user-profile/settings/'} className="fw-bold text-dark text-decoration-none"><i className='fas fa-gear fa-spin me-2'></i> Setting</Link>
                            </div>
                        </li>
                        {/* <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <Link to={'/customer/notifications/'} className="fw-bold text-dark"><i className='fas fa-truck me-2'></i> Track Order</Link>
                            </div>
                        </li> */}
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <Link to="/" className="fw-bold text-danger text-decoration-none" onClick={logout}><i className='fas fa-sign-out me-2'></i> Logout</Link>
                            </div>
                        </li>
                    </ol>
                </>
            }
        </div>
    )
}

export default Sidebar