import React from 'react';
import './footer.css'
import { Link } from 'react-router-dom';


export default function Footer() {
    return (
        <>
            <div className='footer-main'>
                <div className="inner-container" style={{height:"60%"}}>
                    <div className='footer-heading' style={{fontSize:"22px"}}>QUICK SUPPORT</div>
                    {/* <div style={{fontSize:"18px"}}><a style={{border:"0.5px solid black",padding:"5px"}} href="">Contact</a></div> */}
                    <div><a href="mailto:contact@nstee.in"><img src="/Logos/mai.png" height={15} width={25}/>&nbsp;contact@nstee.in</a></div>
                    <div>Customer Care service is available <br /> Monday through Saturday from <br />9:00 AM to 6:00 PM</div>
                    {/* <div>Customer Care service is available Monday through Saturday from 9:00 AM to 6:00 PM</div> */}
                </div>
                <div className="inner-container" style={{height:"70%"}}>
                    <div className='footer-heading' style={{fontSize:"22px"}}>CUSTOMER SERVICE</div>
                    {/* <div><a href="">Payment</a></div> */}
                    {/* <div><Link to="/shppping-policy">Shipping</Link></div> */}
                    {/* <div><Link to="/return-policy">Policy Return</Link></div> */}
                    <div><Link to="/return-policy">Order Status &#38;<br />
                        Return Request</Link>
                    </div>
                    {/* <div><Link to="/gift-card">Gift Card</Link></div>
                    <div><Link to="/refers-and-earn">Refer And Earn</Link></div> */}
                    <div><Link to="/contact-us">Contact Us</Link></div>
                </div>
                <div className="inner-container" style={{height:"70%"}}>
                    <div className='footer-heading' style={{fontSize:"22px"}}>MORE INFORMATION</div>
                    {/* <div><Link to="/care-instructions">Care Instructions</Link></div> */}
                    <div><a href="/assets/Model Size.pdf">Size Guide</a></div>
                    <div><Link to="/terms-and-conditions">Terms &#38; Conditions</Link></div>
                    <div><Link to="/privacy-policy">Privacy Policy</Link></div>
                    <div><Link to="/shppping-policy">Shipping &#38; Return Policy</Link></div>
                    {/* <div><Link to="/faq's">FAQ</Link></div> */}
                    {/* <div><a href="">Cookie Policy</a></div> */}
                </div>
                <div className="inner-container" style={{height:"50%",width:"14rem"}} id='inner-container'>
                    <div className='footer-heading' style={{fontSize:"22px"}}>BRAND</div>
                    {/* <div><Link to="/about">About Us</Link></div> */}
                    <div><a href="">The Company</a></div>
                    {/* <div><a href="">Editorials</a></div> */}
                    <div><a href="">New Openings</a></div>
                </div>
                <div className="inner-container">
                    <div className='footer-heading' style={{fontSize:"22px"}}>SOCIALS</div>
                    <div className='social-logo'>
                        <a href='https://www.instagram.com/nstee.in/' target='_blank'>
                            <img src="/Logos/Instagram.png" alt="Instagram" height={30} width={30} />
                        </a>
                        <a href='https://www.facebook.com/nstee.in' target='_blank'>
                            <img src="/Logos/Facebook.png" alt="Facebook" height={30} width={30} />
                        </a>
                        <a href='https:/x.com/NSTEE_in/' target='_blank'>
                            <img src="/Logos/Twitter.png" alt="Twitter" height={30} width={30} />
                        </a>
                        <a href='https://in.pinterest.com/nstee_in/' target='_blank'>
                            <img src="/Logos/Pinterest.png" alt="Pinterest" height={30} width={30} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="second-cointainer">
                <div className='footer-heading' style={{fontSize:"22px", padding:"2px"}}>SAFETY PAYMENTS</div>
                <div className='second-cointainer-logo'>
                    <img src="/Logos/strip.png" alt="Visa" height={40} width={400} />
                </div>
            </div>
            <div className='footer-reserve'>
                <div className='container'>
                    <p>©2024 | NSTEE Private Limited | All Rights Reserved</p>
                </div>
            </div>
        </>
    )
}
