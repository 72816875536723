import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { jwtDecode } from "jwt-decode";
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function ShiprocketView() {
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState({});
  const [orders, setOrders] = useState();
  const [courierServices, setCourierServices] = useState([]);
  const [shippment_id,setShipment_id]=useState("")
  const [channel_order_id,setChannel_Order_Id]=useState()
  const [currentPage,setCurrentPage]=useState()
  const [totalPages,setTotalPages]=useState()
  const [loading, setLoading] = useState(true)
  // console.log(orders)


  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUser(decoded);
        if (decoded?.vendor_id === 0) {
        navigate("/");
        }
      } catch (error) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const getShiprocketOrders = async () => {
    try {
      const response = await axios.get(`${apiurl}/shiprocket/orders/1/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
          },
        }
      );
      console.log(response.data)
      setTotalPages(response.data.meta.pagination.total_pages)
      setCurrentPage(response.data.meta.pagination.current_page)
      setOrders(response.data);
      return response.data; // Process the response as needed
    } catch (error) {
      if (error.response) {
        console.error("Error Response:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
    }
  };
  useEffect(()=>{
    if(user.vendor_id){
      getShiprocketOrders()
    }
  },[user])
  const getShiprocketOrderspegination = async () => {
    if(currentPage < totalPages){
      try {
        const response = await axios.get(
          `${apiurl}/shiprocket/orders/${currentPage+1}`,
          {
            headers: {
              "Content-Type": "application/json",
               Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
            },
          }
        );
        setCurrentPage(response.data.meta.pagination.current_page)
        for(let i=0;i<response.data.data.length;i++){
          setOrders((prevOrders) => ({
            ...prevOrders, 
            data: [...prevOrders.data, response.data.data[i]],  
          }));
        }
        return response.data; // Process the response as needed
      } catch (error) {
        if (error.response) {
          console.error("Error Response:", error.response.data.data);
        } else {
          console.error("Error Message:", error.message);
        }
      }
    }
    
  };

  const cancelOrders = async (id) => {
    try {
      console.log(id)
      const response = await axios
        .post(`${apiurl}/shiprocket/orders/cancel/`,
          {
            "ids": [id], // Replace with your order IDs
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
            },
          }
        )
        .catch((error) => {
          console.log(error);
        });
        getShiprocketOrders();
    } catch (error) {
      if (error.response) {
        console.error("Error Response:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
    }
  };
  const fetchAllCourierServices = async (pickup_postcode, delivery_postcode, weight, order_id, cod,shipment_id,channel_order_id) => {
    setShipment_id(shipment_id)
    setChannel_Order_Id(channel_order_id)
    try {
      const response = await axios.get(`${apiurl}/shiprocket/courier-services/${pickup_postcode}/${delivery_postcode}/${weight}/${order_id}/${cod}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
          },
        }
      );
      setCourierServices(response.data.data.available_courier_companies); 
      
    } catch (error) {
      if (error.response) {
        console.error("Error Response:", error.response.data);
      } else {
        console.error("Error Message:", error.message);
      }
    }
  };

  const calculateEstimatedPickupDate = (etd, estimatedDeliveryDays) => {
        const deliveryDate = new Date(etd);
        const deliveryDays = parseInt(estimatedDeliveryDays, 10);
        const pickupDate = new Date(deliveryDate);
        pickupDate.setDate(deliveryDate.getDate() - deliveryDays + 1);

        // Format the pickup date to show only day, month, and year
        const formattedPickupDate = pickupDate.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });

        return formattedPickupDate; // Return formatted date as a string
    };


    const requestForShiiping= async (shipment_id,awb)=>{
        try {
            const response = await axios.post(
              `${apiurl}/shiprocket/request-shipping/`,
              {
                "shipment_id": [Number(shipment_id)]
              },
              {
                headers: {
                  "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
                },
              }
            );
            const res = await axios.put(
                `${apiurl}/shiprocket-orders/${Number(channel_order_id)}/`,
                {
                  "awb_code":awb
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              getShiprocketOrders()
          } catch (error) {
            if (error.response) {
              getShiprocketOrders()
              console.error("Error Response:", error.response.data);
            } else {
              console.error("Error Message:", error.message);
            }
          }
    }
    const generateAWB=async (courier_id)=>{
        try {
            const response = await axios.post(
              `${apiurl}/shiprocket/awb/generate/`,
              {
                "shipment_id":Number(shippment_id),
                "courier_id":Number(courier_id)
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
                },
              }
            );
            requestForShiiping(shippment_id,response.data.response.data.awb_code)
          } catch (error) {
            if (error.response) {
              console.error("Error Response:", error.response.data);
            } else {
              console.error("Error Message:", error.message);
            }
          }
    }
  return (
    <div className="container-fluid pt-5 bg-secondary" id="main">
      <div className="row row-offcanvas row-offcanvas-left h-100">
        <Sidebar />
        <div className="col-md-9 col-lg-10 main mt-4">
          <>
            <h4>
              <i className="bi bi-grid" /> Order Shipping
            </h4>
            <div className="dropdown">
              <Link to="/vendor/ship-rockets-order-update/new" className="btn btn-dark">
                Create Order
              </Link>
            </div>
          </>
          <div className="mb-3 mt-2 table-responsive bg-light" style={{height:"82vh"}}>
            <table className="table table-responsive">
              <thead className="table-dark">
                <tr>
                  <th scope="col">Order Detail</th>
                  <th scope="col">Customer Detail</th>
                  <th scope="col">Product Detail</th>
                  <th scope="col">Package Detail</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Pickup Address</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "10px" }}>
                {orders?.data.map((p, index) => (
                  <tr key={index}>
                    <td
                      className="p-3 bolder"
                      style={{ fontSize: "10px", fontWeight: "bolder" }}
                    >
                      <Link
                        to={`/vendor/ship-rockets-order-update/${p.id}`}
                        style={{ fontSize: "12px", fontWeight: "bolder" }}
                      >
                        {p.id}
                      </Link>
                      <br />
                      <br />
                      {p.created_at}
                    </td>
                    <td
                      className="p-3 bolder"
                      style={{ fontSize: "10px", fontWeight: "bolder" }}
                    >
                      <span style={{ fontSize: "12px", fontWeight: "bolder" }}>
                        {p.customer_name}
                      </span>
                      <br />
                      {p.customer_email} <br />
                      {p.customer_phone} <br />
                      RTO Risk:&nbsp;{" "}
                      <span
                        style={{
                          color: p.rto_prediction === "low" ? "green" : "red",
                          fontWeight: "bolder",
                          fontWeight: "bolder",
                          fontSize: "12px",
                        }}
                      >
                        {p.rto_prediction}
                      </span>
                    </td>
                    <td
                      className="p-3 bolder"
                      style={{ fontSize: "10px", fontWeight: "bolder" }}
                    >
                      {p.products[0].name.slice(0, 28)}
                      <br />
                      SKU:&nbsp;{p.products[0].channel_sku} <br />
                      QTY:&nbsp;{p.products[0].quantity}
                      <br />
                      HSN:&nbsp;{p.products[0].hsn}
                    </td>
                    <td
                      className="p-3 bolder"
                      style={{ fontSize: "10px", fontWeight: "bolder" }}
                    >
                      {p.others.dimensions} <br />
                      Dead wt:&nbsp;{p.others.weight}&nbsp;kg
                      <br />
                      Volume:&nbsp;{p.shipments[0].volumetric_weight}&nbsp;kg
                      <br />
                      AWB:<span style={{color:"green", fontWeight:"bolder"}}>&nbsp;{p.shipments[0].awb}</span>
                    </td>
                    <td
                      className="p-3 bolder"
                      style={{ fontSize: "12px", fontWeight: "bolder" }}
                    >
                      ₹{p.total}
                      <br />
                      <span
                        style={{
                          color: p.payment_method === "cod" ? "brown" : "green",
                          marginLeft: "10%",
                        }}
                      >
                        {p.payment_method}
                      </span>
                    </td>
                    <td
                      className="p-3 bolder"
                      style={{
                        fontSize: "12px",
                        color: "#0c9a93 ",
                        fontWeight: "bolder",
                      }}
                    >
                      {p.pickup_location}
                    </td>
                    <td
                      className="p-3 bolder"
                      style={{
                        color:
                          p.status === "CANCELED"
                            ? "red"
                            : p.status === "DELIVERED"
                            ? "green"
                            : "blue",
                        fontWeight: "bolder",
                      }}
                    >
                      {p.status}
                    </td>
                    <td>
                      {p.shipments[0].awb || p.status == "CANCELED" ? (
                        ""
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn btn-sm btn-dark mb-3"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={()=>fetchAllCourierServices(p.pickup_address_detail.pin_code,p.customer_pincode,p.shipments[0].weight,p.id,p.cod,p.shipments[0].id,p.channel_order_id)}
                          >
                            Ship Now
                          </button>
                          <br />
                        </>
                      )}
                      {p.status == "DELIVERED" || p.status == "CANCELED" ? (
                        ""
                      ) : (
                        <button
                          className="btn btn-sm btn-danger"
                          style={{ paddingLeft: "17px", paddingRight: "17px" }}
                          onClick={() => cancelOrders(p.id)}
                        >
                          Cancel
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
                {orders?.data.length < 1 && (
                  <h4 className="p-3 mt-4">No Orders Yet</h4>
                )}
              </tbody>
            </table>
            { currentPage < totalPages ?
            <div style={{height:"8%"}}>
              <button className="btn btn-dark btn-sm" style={{marginLeft:"35%",width:"30%"}} onClick={getShiprocketOrderspegination}>Load More</button>
            </div>
            :""
            }
          </div>
          <>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-xl ">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel" style={{fontWeight:"bolder"}}>
                      Available services
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                  <div className="mb-3 mt-2 table-responsive">
                    <table className="table table-responsive">
                        <thead className="table-dark">
                        <tr>
                        <th scope="col">Courier Partner</th>
                        <th scope="col">Rating</th>
                        <th scope="col">Expected Pickup</th>
                        <th scope="col">Estimated Delivery</th>
                        <th scope="col">Chargeable Weight</th>
                        <th scope="col">Charges</th>
                        <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {courierServices?.map((order, index) => (
                        <tr key={index} style={{fontSize:"12px"}}>
                            <td ><span style={{fontWeight:"bolder"}}>{order.courier_name}</span> <br />{order.is_surface ? "Surface" : "Air"}&nbsp;|&nbsp;Min-Weight:span <span style={{fontWeight:"bolder"}}>{order.min_weight} Kg </span><br />RTO Charges: ₹{order.rto_charges}</td>     
                            <td style={{fontWeight:"bolder"}}><i class="fa-solid fa-star"></i>&nbsp;{order.rating}</td>  
                            <td >{String(calculateEstimatedPickupDate(order.etd, order.estimated_delivery_days))}</td>    
                            <td>{order.etd}</td>    
                            <td >{order.charge_weight}&nbsp;KG</td>     
                            <td style={{fontWeight:"bolder"}}>₹{order.rate}</td>     
                            <td><button type="button" className=" btn btn-sm btn-dark" onClick={()=>generateAWB(order.courier_company_id)} data-bs-dismiss="modal" aria-label="Close">Ship Now</button></td>            
                        </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default ShiprocketView;
