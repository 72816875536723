import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar'
import Swal from 'sweetalert2'
import axios from 'axios';
import { apiurl } from '../../urls/url';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';


function UserSettings() {
    const [profileData, setProfileData] = useState({
        'full_name': '',
        'mobile': '',
        'email': '',
        'about': '',
        'country': '',
        'city': '',
        'state': '',
        'address': '',
        'p_image': '',
        "pid":"",
        "gender":""
    });
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({});
    const navigate=useNavigate()
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwtDecode(token); 
                // console.log(decoded)
                setUser(decoded)
                if (decoded?.vendor_id > 0) {
                    navigate('/'); 
                }
            } catch (error) {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    },[navigate]);
    useEffect(() => {
        // Fetch existing profile data
        const fetchProfileData = async () => {
            try {
                axios.get(`${apiurl}/user/profile/${user?.user_id}/`,{
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
                  }
                  }).then((res) => {
                    // setProfileData(res.data);
                    setProfileData({
                        'full_name': res.data?.full_name,
                        'email': res.data.user.email,
                        'phone': res.data.user.phone,
                        'about': res.data.about,
                        'country': res.data.country,
                        'city': res.data.city,
                        'state': res.data.state,
                        'address': res.data.address,
                        'p_image': res.data.image,
                        "pid":res.data.pid,
                        "gender":res.data.gender
                    })
                    console.log(res.data)
                }).catch(
                    (error) => {
                      if(error.response){
                        if(error.response.status===401){
                          localStorage.removeItem("token");
                          navigate('/login');
                        }
                      }
                    }
                  );
            } catch (error) {
                console.error('Error fetching profile data: ', error);
            }
        };
        if(user.user_id)

        fetchProfileData();
    }, [user]);


    const handleInputChange = (event) => {
        setProfileData({
            ...profileData,
            [event.target.name]: event.target.value
        })
    };

    const handleFileChange = (event) => {
        setProfileData({
            ...profileData,
            [event.target.name]: event.target.files[0]
        })
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const res = await axios.get(`${apiurl}/user/profile/${user?.user_id}/`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
          }
          }).catch(
            (error) => {
            setLoading(false)
              if(error.response){
                if(error.response.status===401){
                  localStorage.removeItem("token");
                  navigate('/login');
                }
              }
            }
          );
        const formData = new FormData();
        if (profileData.p_image && profileData.p_image !== res.data.image) {
            formData.append('image', profileData.p_image);
        }
        formData.append("user",user.user_id)
        formData.append('full_name', profileData.full_name);
        formData.append('about', profileData.about);
        formData.append('country', profileData.country);
        formData.append('city', profileData.city);
        formData.append('state', profileData.state);
        formData.append('address', profileData.address);
        formData.append('newsletter', false);

        formData.append("gender",profileData.gender)
        formData.append("pid",profileData.pid)

        try {
            await axios.patch(`${apiurl}/customer/setting/${user?.user_id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
                },
            })
            .then((res)=>{
                Swal.fire({
                    icon: 'success',
                    title: "Profile updated successfully",
                })
                setLoading(false)
                console.log(res.data)

            }).catch(
                (error) => {
                setLoading(false)
                  if(error.response){
                    if(error.response.status===401){
                      localStorage.removeItem("token");
                      navigate('/login');
                    }
                  }
                }
              );

        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <div>
            <main className="mt-5">
                <div className="container">
                    <section className="">
                        <div className="row">
                            <Sidebar />
                            <div className="col-lg-9 mt-4">
                                <section className="">
                                    <main className="mb-5" style={{}}>
                                        <div className="container px-4">
                                            {/* Section: Summary */}
                                            <section className="">
                                                <h3 className="mb-3">
                                                    {" "}
                                                    <i className="fas fa-gear fa-spin" /> Settings{" "}
                                                </h3>
                                                <form onSubmit={handleFormSubmit} method='POST' encType="multipart/form-data">
                                                    <div className="row">
                                                        {/* <div className="col-lg-12 mb-4">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                Profile Image
                                                            </label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                onChange={handleFileChange}
                                                                name='p_image'
                                                            />
                                                        </div> */}
                                                        <div className="col-lg-6">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                Full Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                value={profileData?.full_name}
                                                                onChange={handleInputChange}
                                                                name='full_name'

                                                            />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                Gender
                                                            </label>
                                                            <select name="gender" id="" onChange={handleInputChange} value={profileData?.gender} className="form-control" >
                                                                <option value="">--Select--</option>
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-lg-6 mt-3">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                Email address
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                value={profileData?.email}
                                                                name='email'
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mt-3">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                Mobile
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                value={profileData?.phone}
                                                                name='phone'
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-lg-6 mt-0">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                Address
                                                            </label>
                                                            <input
                                                                name='address'
                                                                value={profileData?.address}
                                                                type="text"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                onChange={handleInputChange}

                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mt-0">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                City
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                value={profileData?.city}
                                                                name='city'
                                                                onChange={handleInputChange}

                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mt-0">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                State
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                value={profileData?.state}
                                                                name='state'
                                                                onChange={handleInputChange}

                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mt-0">
                                                            <label
                                                                htmlFor="exampleInputEmail1"
                                                                className="form-label"
                                                            >
                                                                Country
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                value={profileData?.country}
                                                                name='country'
                                                                onChange={handleInputChange}

                                                            />
                                                        </div>
                                                    </div>
                                                    {loading === false &&
                                                        <button type="submit" className="btn btn-dark mt-3">
                                                            Save Changes
                                                        </button>
                                                    }

                                                    {loading === true &&
                                                        <button disabled className="btn btn-dark mt-3">
                                                            Saving... <i className='fas fa-spinner fa-spin'></i>
                                                        </button>
                                                    }
                                                </form>
                                            </section>
                                            {/* Section: Summary */}
                                            {/* Section: MSC */}
                                            {/* Section: MSC */}
                                        </div>
                                        {/* Container for demo purpose */}
                                    </main>
                                </section>
                            </div>
                        </div>
                    </section>
                    {/*Section: Wishlist*/}
                </div>
            </main>

        </div>
    )
}

export default UserSettings