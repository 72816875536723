import React, { useState, useEffect } from 'react'
import Sidebar from './Sidebar'
import { Navigate, useNavigate } from 'react-router-dom';
// import apiInstance from '../../utils/axios';
// import UserData from '../plugin/UserData';
import moment from 'moment';
import axios from 'axios';
import { apiurl } from '../../urls/url';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from 'jwt-decode';



function UserNotifications() {

    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({});
    const navigate=useNavigate()
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwtDecode(token); 
                // console.log(decoded)
                setUser(decoded)
                if (decoded?.vendor_id > 0) {
                    navigate('/'); 
                }
            } catch (error) {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    },[navigate]);
    

    // const axios = apiInstance
    // const userData = UserData()

    useEffect(() => {
        if(user.user_id){
            axios.get(`${apiurl}/customer/notification/${user?.user_id}/`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
              }
              }).then((res) => {
                setNotifications(res.data);
                if (notifications) {
                    setLoading(false)
                }
            })
            .catch(
                (error) => {
                  if(error.response){
                    if(error.response.status===401){
                      localStorage.removeItem("token");
                      navigate('/login');
                    }
                  }
                }
              );

        }
    }, [user])

    // console.log(notifications);

    return (
        <div>
            <main className="mt-5" style={{ marginBottom: 200 }}>
                <div className="container">
                    <section className="">
                        <div className="row">
                            <Sidebar />
                            <div className="col-lg-9 mt-3">
                                <section className="">
                                    <main className="mb-5" style={{}}>
                                        <div className="container px-4">
                                            {/* Section: Summary */}
                                            <section className="">
                                                <h3 className="mb-3">
                                                    {" "}
                                                    <i className="fas fa-bell" /> Notifications{" "}
                                                </h3>
                                                <div className="list-group">
                                                    {notifications.map((noti, index) => (
                                                        <a href="#" className="list-group-item list-group-item-action" aria-current="true" >
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <h5 className="mb-1">New Order!</h5>
                                                                <small>{moment(noti.date).format('MM-DD-YYYY')}</small>
                                                            </div>
                                                            <p className="mb-1">
                                                                Your order {noti?.order?.oid} was successfully placed
                                                            </p>
                                                            <small className=''>Total: ₹{noti?.order?.total}</small> <br />
                                                            <small className=''>Shipping: ₹{noti?.order?.shipping_amount}</small> <br />
                                                            <small className=''>Tax: ₹{noti?.order?.tax_fee}</small> <br />
                                                            <small className=''>Service Fee: ₹{noti?.order?.service_fee}</small> <br />
                                                        </a>
                                                    ))}

                                                    {notifications.length < 1 &&
                                                        <h6>No notifications yet</h6>
                                                    }
                                                </div>
                                            </section>
                                            {/* Section: Summary */}
                                            {/* Section: MSC */}
                                            {/* Section: MSC */}
                                        </div>
                                        {/* Container for demo purpose */}
                                    </main>
                                </section>
                            </div>
                        </div>
                    </section>
                    {/*Section: Wishlist*/}
                </div>
            </main>

        </div>
    )
}

export default UserNotifications