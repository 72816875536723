import React, { useState, useEffect } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';

// import apiInstance from '../../utils/axios';
// import UserData from '../plugin/UserData';
import Sidebar from './Sidebar';
import Swal from 'sweetalert2'
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Coupon from './Coupon';
import { jwtDecode } from "jwt-decode";

function EditCoupon() {
    const [coupon, setCoupon] = useState([])
    const param = useParams()
    const [user, setUser] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwtDecode(token); 
                setUser(decoded)
                if (decoded?.vendor_id === 0) {
                    navigate('/'); 
                }
            } catch (error) {
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const fetchData = async () => {
        try {
            await axios.get(`${apiurl}/vendor-coupon-detail/${user.vendor_id}/${param.coupon_id}`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
                }
            }).then((res) => {
                setCoupon(res.data);
            }).catch(
                (error) => {
                  if(error.response){
                    if(error.response.status===401){
                      localStorage.removeItem("token");
                      navigate('/login');
                    }
                  }
                }
              )
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if(user.vendor_id){
            fetchData();
        }
    }, [user]);

    const handleUpdateCouponChange = (event) => {
        setCoupon({
            ...coupon,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
        console.log(coupon);
    }

    const handleUpdateCoupon = async (e) => {
        e.preventDefault()
        const data={
            "vendor": 1,
            "code":coupon.code ,  
            "discount": coupon.discount,  
            "active": coupon.active  
        }
        console.log("data",data)
        await axios.patch(`${apiurl}/vendor-coupon-detail/${user.vendor_id}/${param.coupon_id}/`,data,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
            }
        }).then((res) => {
            // console.log(res.data);
            Swal.fire({
                icon: 'success',
                title: 'Coupon Updated',
            })
            navigate('/vendor/coupon/');
        }).catch(
            (error) => {
              if(error.response){
                if(error.response.status===401){
                  localStorage.removeItem("token");
                  navigate('/login');
                }
              }
            }
          )
    }
    return (
        <div className="container-fluid pt-5 bg-secondary" id="main" >
            <div className="row row-offcanvas row-offcanvas-left h-100">
                <Sidebar />
                <div className="col-md-9 col-lg-10 main mt-4">
                    <h4 className="mt-3 mb-4"><i className="bi bi-tag" /> Coupons</h4>
                    <form onSubmit={handleUpdateCoupon} className='card shadow p-3'>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name='code'
                                placeholder='Enter Coupon Code'
                                onChange={handleUpdateCouponChange}
                                value={coupon.code}
                            />
                            <div id="emailHelp" className="form-text">
                                E.g NSTEE2024
                            </div>
                        </div>
                        <div className="mb-3 mt-4">
                            <label htmlFor="exampleInputPassword1" className="form-label">
                                Discount
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="exampleInputPassword1"
                                name='discount'
                                placeholder='Enter Discount'
                                onChange={handleUpdateCouponChange}
                                value={coupon.discount}
                            />
                            <div id="emailHelp" className="form-text">
                                NOTE: Discount is in <b>percentage</b>
                            </div>
                        </div>
                        <div className="mb-3 form-check">
                            <input checked={coupon.active} onChange={handleUpdateCouponChange} name='active' type="checkbox" className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label" htmlFor="exampleCheck1">
                                Activate Coupon
                            </label>
                        </div>
                        <div className="d-flex">
                            <Link to="/vendor/coupon/" className="btn btn-secondary">
                                <i className='fas fa-arrow-left'></i> Go Back
                            </Link>
                            <button type="submit" className="btn btn-success ms-2">
                                Update Coupon <i className='fas fa-check-circle'></i>
                            </button>
                        </div>
                    </form>
                </div>


            </div>


        </div >
    )
}

export default EditCoupon