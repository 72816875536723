import React from 'react';
import Confirmpasswordform from '../../../comcomponents/confirmpasswordform';
import './confirmpassword.css'

export default function Confirmpassword() {
  return (
    <div className='confirmpassword-main'>
        <Confirmpasswordform></Confirmpasswordform>
    </div>
  )
}
