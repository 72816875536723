import React, { useEffect, useState } from 'react';
import './contact.css'
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { apiurl } from '../../../urls/url';

export default function Contact() {
  const [formData,setFormData]=useState(
    {
      "name":"",
      "phone_number":"",
      "email":"",
      "subject":"",
      "message":"",
    }
  )
    const validateForm = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
      const phoneRegex = /^\d{10}$/; // Assumes phone numbers are 10 digits
      // Name validation
      if (!formData.name.trim()) {
        toast.error("Name is required");
        // alert("Name is required")
        return false
      }
      else if(formData.name.length<3){
        toast.error("Name Atleast 3 Character");
        return false
      }

      // Phone number validation
      if (!formData.phone_number.trim()) {
        toast.error("Phone number is required");
        return false

      } else if (!phoneRegex.test(formData.phone_number)) {
        toast.error("Phone number must be 10 digits");
        return false
      }

      // Email validation
      if (!formData.email.trim()) {
        toast.error("Email is required");
        return false
      } else if (!emailRegex.test(formData.email)) {
        toast.error("Invalid email format");
        return false
      }

      // Subject validation
      if (!formData.subject.trim()) {
        toast.error("Subject is required");
        return false
      }

      // Message validation
      if (!formData.message.trim()) {
        toast.error("Message is required");
        return false
      }
      return true // If no errors, return true
  };

  const dataChange=(e)=>{
    setFormData({
      ...formData,
      [e.target.name]:e.target.value
    })
  }
  const formSubmit=(e)=>{
    e.preventDefault();
    if(validateForm()){
      try{
        axios.post(`${apiurl}/api/contact-us/`,formData)
      .then((response)=>{
        toast.success("Message sent successfully");
        setFormData({
          "name":"",
        "phone_number":"",
        "email":"",
        "subject":"",
        "message":"",
        })
      })
      .catch((err)=>{
        console.log(err)
      })
      }
      catch(err){
        console.log(err)
      }  
    }
    return
  }
  return (
    <>
      <div className='contact-main'>
        <div className='container'>
          <div className='row'>
          <ToastContainer />
            <div className='col-lg-7 p-0'>
              <div className='contactform p-5'>
                <h1 className='text-dark text-center'>Contact Us</h1>
                <input type='text' name='name' placeholder='Enter Your Full Name' onChange={dataChange} value={formData.name}/>
                <input type='text' name='phone_number' placeholder='Enter Your Phone Number' onChange={dataChange} value={formData.phone_number}/>
                <input type="email" name='email' placeholder='Enter a Valid Email address' onChange={dataChange} value={formData.email}/>
                <input type="text" name='subject' placeholder='Enter Subject' onChange={dataChange} value={formData.subject}/>
                <textarea name='message' placeholder='Enter Your message' onChange={dataChange} value={formData.message}/>
                {/* <input type="text" name="" id="" placeholder='Enter Your message' style={{marginTop:"20px"}}/> */}
                <button className='btn btn-dark w-100' onClick={formSubmit}>Submit</button>
              </div>
            </div>
            <div className=' col-lg-5 p-0 d-flex align-items-center justify-content-center'>
              <div className='contact-info'>
                <div>
                  <div className='text-center'>
                    <h5 style={{fontWeight:"bolder"}}>Thank You for reaching out to us!</h5>
                    <p>Please fill up the form and send us your query. <br />Our team will connect with you as soon as possible.</p>
                    {/* <p>Our team will connect with you as soon as possible.</p> */}
                    <h6><i className="fa-solid fa-location-dot"></i> 42 , 2nd Floor, Krishna Market, Kalkaji Extension,<br /> Kalkaji, Delhi, New Delhi, Delhi 110019</h6>
                    <h6><i className="fa-solid fa-envelope"></i> Contact US</h6>
                    <h6><a href='mailto:support@nstee.in'> support@nstee.in</a></h6>
                  </div>
                  <div className='social-icons-contact'>
                    <a href="https://x.com/NSTEE_in" target='blank'>
                      <div>
                        <i className="fa-brands fa-x-twitter"></i>
                      </div>
                    </a>
                    <a href="https://www.linkedin.com/company/nstees/" target='blank'>
                      <div>
                        <i className="fa-brands fa-linkedin"></i>
                      </div>
                    </a>
                    <a href="https://www.instagram.com/nstee.in/" target='blank'>
                      <div>
                        <i className="fa-brands fa-instagram"></i>
                      </div>
                    </a>
                    <a href="https://www.facebook.com/nstee.in" target='blank'>
                      <div>
                        <i className="fa-brands fa-facebook-f"></i>
                      </div>
                    </a>
                  </div>
                  {/* <div className='contact-googlemap'>
                      <iframe width="100%" height="150" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=digital%20promotion%20team%20dpt%20kalkaji,%20krishna%20market+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='contact-googlemap'>
          <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=digital%20promotion%20team%20dpt%20kalkaji,%20krishna%20market+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
        </div> */}
      </div>
    </>
  )
}
