import React, { useEffect, useRef, useState } from "react";
import "./cart.css";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  clearCart,
} from "../../../store/cartSlice";
import axios from "axios";
import { apiurl } from "../../../urls/url";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

export default function Cart(scroll) {
  const navigate = useNavigate();
  const scrolled = scroll.scroll;
  const [cartdata, setcartdata] = useState();
  const [total, settotal] = useState();
  const cartid = localStorage.getItem("randomString");
  const mycookies = useCookies(["access_token"]);
  const [quantity, setQuantity] = useState(0);
  const userid = localStorage.getItem("token");
  let id;
  // console.log(cartdata)
  if (userid) {
    id = jwtDecode(userid);
  }

  // const userdata = jwtDecode(mycookies[0].refresh_token)
  // const userid = userdata.user_id

  // console.log(scroll, 'scroll is worling')
  // console.log('consile is is here', id)

  const getcartdata = async () => {
    gettotal();
    if (!userid) {
      await axios
        .get(`${apiurl}/cart-list/${cartid}/`)
        .then((res) => {
          // console.log("cart data",res);
          setcartdata(res.data);
        })
        .catch((err) => {
          // console.log(err)
        });
    } else {
      await axios
        .get(`${apiurl}/cart-list/${cartid}/${id.user_id}`)
        .then((res) => {
          // console.log(res);
          setcartdata(res.data);
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  scroll.passfunction(getcartdata);

  const cartdelete = (cartid, itemid) => {
    // console.log('cart and item id is here', cartid, itemid)
    axios
      .delete(`${apiurl}/cart-delete/${cartid}/${itemid}`)
      .then((res) => {
        // console.log(res)
        getcartdata();
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  const gettotal = async () => {
    if (!userid) {
      axios
        .get(`${apiurl}/cart-detail/${cartid}/`)
        .then((res) => {
          // console.log(res, 'cart total values are here');
          settotal(res.data);
        })
        .catch((err) => {
          // console.log(err)
        });
    } else {
      axios
        .get(`${apiurl}/cart-detail/${cartid}/${id.user_id}`)
        .then((res) => {
          // console.log(res, 'cart total values are here');
          settotal(res.data);
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  const updatecart = (value, quantity) => {
    // console.log("cartvalue", value);
    axios
      .put(`${apiurl}/update-cart-quantity/`, {
        product_id: value.product.id,
        qty: quantity,
        cart_id: value.cart_id,
        size: value.size,
      })
      .then((res) => {
        getcartdata();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // const handleIncrease = (value) => {
  //     setQuantity(prevQuantity => prevQuantity + 1);
  //     updatecart(value)
  // };

  // // Handler to decrease the quantity
  // const handleDecrease = (value) => {
  //     setQuantity(prevQuantity => (prevQuantity > 0 ? prevQuantity - 1 : 0));
  //     updatecart(value)
  // };

  const overlayRef = useRef(null);
  const cartRef = useRef(null);

  const handleClick = () => {
    getcartdata();
    // console.log(cartRef.current.classList)
    if (overlayRef.current.classList.contains("cart-overlay")) {
      overlayRef.current.classList.remove("cart-overlay");
      overlayRef.current.classList.add("removeoverlay");
    } else {
      overlayRef.current.classList.add("cart-overlay");
      overlayRef.current.classList.remove("removeoverlay");
    }

    if (!cartRef.current.classList.contains("hidecart")) {
      cartRef.current.classList.add("hidecart");
    } else {
      cartRef.current.classList.remove("hidecart");
    }
  };

  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    if (cartdata) {
      const initialQuantities = cartdata.reduce((acc, item) => {
        acc[item.id] = item.qty;
        return acc;
      }, {});
      setQuantities(initialQuantities);
    }
  }, [cartdata]);

  const handleDecrease = (value) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      if (newQuantities[value.id] > 1) {
        newQuantities[value.id] -= 1;
        updatecart(value, newQuantities[value.id]);
      }
      return newQuantities;
    });
  };

  const handleIncrease = (value) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      newQuantities[value.id] += 1;
      updatecart(value, newQuantities[value.id]);
      return newQuantities;
    });
  };

  const handleQuantityChange = (id, value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue > 0) {
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities, [id]: parsedValue };
        const item = cartdata.find((item) => item.id === id);
        if (item) {
          updatecart(item, parsedValue);
        }
        return newQuantities;
      });
    }
  };

  const cartcheck = () => {
    // console.log(cartdata)
    if (!cartdata || cartdata.length === 0) {
      toast.error("Cart is empty. Please add products to your cart.");
    } else {
      handleClick();
      navigate("/check-out");
    }
  };

  // console.log("cartvalue", cartdata);

  return (
    <>
      <button className="cart-button position-relative" onClick={handleClick}>
        {/* <span className="material-symbols-outlined" id='cart-icon'>
          shopping_bag
        </span> */}
        {/* <img src="/Logos/bag.png" alt="bag" id="cart-icon" height={20} width={20} className="bag-icon"/> */}
        <img
          src={scrolled ? "/Logos/black bag.png" : "/Logos/white bag.png"}
          alt="bag"
          id="cart-icon"
          height={20}
          width={20}
          className="bag-icon"
        />
        {/* <img src={scrolled ? '/assets/images/icons/cartone.png' : '/assets/images/icons/carttwo.png'} /> */}
      </button>
      {cartdata && cartdata.length > 0 && (
        <div className="card-value">
          {" "}
          <span>{cartdata ? cartdata.length : 0}</span>
        </div>
      )}
      <div className="cart-main hidecart" ref={cartRef}>
        {/* <div className='row'> */}
        {/* <div className='col-5 pe-0'>
            <div className='complete-collection-main'>
              <h5 className='text-center pt-4'>Complete The Collection:</h5>
            </div>
          </div> */}
        <div className="col-12 cart-right ps-0">
          <div className="cart-container px-3 pt-4">
            <div className="clodseicon">
              <i
                className="fa-regular fa-circle-xmark"
                onClick={handleClick}
              ></i>
            </div>
            <h3 className="mb-4">Your Cart</h3>

            {cartdata?.length === 0 ? (
              <p>&nbsp;</p>
            ) : (
              <>
                {cartdata?.map((value, index) => (
                  <div className="row mb-3" key={index}>
                    <div className="col-sm-3 p-0 text-center">
                      <img
                        src={`${value.product.image}`}
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-sm-4">
                      <h5>{value.product.title}</h5>
                      <p>{value.product.description}</p>
                      <p style={{ fontSize: "16px" }} className="mobile">
                        ₹{" "}
                        {cartdata
                          ? Math.ceil(Math.floor(value.total) / value.qty)
                          : 0}{" "}
                        × {value.qty} =&nbsp;₹{Math.floor(value.total)}{" "}
                      </p>
                      <div className="cartdisplaymobile col-sm-4 text-start">
                        <h4
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            fontSize: "16px",
                          }}
                        >
                          Size:&nbsp;{value.size}
                          <div
                            className="quantity"
                            style={{ height: "30px", marginTop: "0px" }}
                          >
                            <span
                              style={{ fontSize: "30px" }}
                              onClick={() => handleDecrease(value)}
                            >
                              -
                            </span>
                            <input
                              type="number"
                              value={quantities[value.id] || value.qty}
                              onChange={(e) =>
                                handleQuantityChange(
                                  value.id,
                                  parseInt(e.target.value, 10)
                                )
                              }
                            ></input>
                            <span onClick={() => handleIncrease(value)}>+</span>
                          </div>
                          <i
                            className="fa-solid fa-trash font"
                            style={{ fontSize: "16px", marginTop: "3px" }}
                            onClick={() => cartdelete(value.cart_id, value.id)}
                          ></i>
                        </h4>
                      </div>
                      <p style={{ fontSize: "15px" }} className="desktop">
                        Size:&nbsp;{value.size}
                      </p>
                      <div className="quantity disable">
                        <span onClick={() => handleDecrease(value)}>-</span>
                        <input
                          type="number"
                          value={quantities[value.id] || value.qty}
                          onChange={(e) =>
                            handleQuantityChange(
                              value.id,
                              parseInt(e.target.value, 10)
                            )
                          }
                        ></input>
                        <span onClick={() => handleIncrease(value)}>+</span>
                      </div>
                    </div>
                    <div className="cartdisplaydesktop col-sm-4 text-center">
                      <h6>
                        ₹{" "}
                        {cartdata
                          ? Math.ceil(Math.floor(value.total) / value.qty)
                          : 0}{" "}
                        × {value.qty}= ₹&nbsp;{Math.floor(value.total)}
                      </h6>
                    </div>
                    <div className="col-sm-1 text-center disable">
                      <i
                        className="fa-solid fa-trash"
                        onClick={() => cartdelete(value.cart_id, value.id)}
                      ></i>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          {cartdata?.length === 0 ? (
            <div class="empty-cart-message" style={{marginTop:"20%"}}>
              <h4 style={{textAlign:'center', fontWeight:"500"}}>Your Cart is Empty</h4>
              <p style={{width:"70%",marginLeft:"17%",textAlign:"center",fontSize:"12px"}}>It looks like you haven't added anything to your cart yet.</p>
              <p style={{textAlign:"center"}}>
                <Link to="/shop" class="btn checkout-btn" style={{width:"70%"}} onClick={handleClick} >
                  Go to Shop
                </Link>
              </p>
            </div>
          ) : (
            <>
              <div className="cart-last p-3">
                <h4>
                  Total <span>₹{Math.ceil(Number(total?.total))}</span>
                </h4>
              </div>
              <div className="checkout-btn-div p-3">
                <button className="checkout-btn" onClick={cartcheck}>
                  Check Out
                </button>
              </div>
            </>
          )}
          <div></div>
        </div>
        {/* </div> */}
      </div>
      <div
        className="removeoverlay"
        ref={overlayRef}
        onClick={handleClick}
      ></div>
    </>
  );
}
