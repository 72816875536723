import React, { useState,useEffect } from 'react';
import './ordertrack.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Ordertrack() {

  const [trackorderinfo, settrackorderinfo] = useState()
  const [awbnumber, setawbnumber] = useState()
  const navigate = useNavigate();
  const getordertrack = async () => {
    try {
      if(!awbnumber){
        alert("Please enter AWB number");
        return
      }
      const response = await axios.get(`https://backend.nstee.in/api/v1/shiprocket/track/${awbnumber}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(response.data.tracking_data.shipment_track_activities){
        navigate('/track-order-info/', { state: { data: response.data } })
      }
      else{
        alert("No tracking data available Currently for this AWB number")
      }
      settrackorderinfo(response.data);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data?.message || "Error in tracking order.");
        console.error("Error Response:", err.response.data);
      } else if (err.request) {
        toast.error("Network Error: Please check your internet connection.");
        console.error("Network Error:", err.request);
      } else {
        toast.error("An unexpected error occurred.");
        console.error("Error Message:", err.message);
      }
    }
  };

  return (
    <>
        <div className='track-order-main'>
            <div className="track-order">
                <h5 className='text-white text-start'>Enter Your AWB Number to proceed <img src='/assets/images/icons/truck.png' className='img-fluid'></img></h5>
                <input type='text' value={awbnumber} onChange={(e) => setawbnumber(e.target.value)} placeholder='AWB No.'></input>
                <input type='submit' value='Submit' onClick={getordertrack}/>
                <ToastContainer />
            </div>
        </div>
    </>
  )
}
