import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './product.css'
import Carousel from 'react-bootstrap/Carousel';
import Subscribe from '../../comcomponents/subscribe';
import Headermain from '../../layout/header/headermain';
import { useDispatch, useSelector } from 'react-redux';
import { json, Link, useNavigate, useParams } from 'react-router-dom';
import { setSelectedProduct } from '../../../store/selectproductslicer';
import { addToCart, incrementQuantity, decrementQuantity } from '../../../store/cartSlice';
//import GetCurrentAddress from '../../comcomponents/UserCountry';
import { Cookies, CookiesProvider, useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import CartID from '../../comcomponents/cartID';
import axios from 'axios';
import { apiurl } from '../../../urls/url';
import { fetchProducts } from '../../../store/productslice';

export default function Product(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [products, setproducts] = useState()
    const product = useSelector((state) => state.selectedProduct);
    const cart = useSelector((state) => state.cart || []); // Add fallback to an empty array
    const cartItem = cart.find((item) => item.id === parseInt(id));
    const [quantity, setQuantity] = useState(cartItem ? cartItem.quantity : 1);
    const location = JSON.parse(localStorage.getItem('location'))
    const cart_id = localStorage.getItem('randomString')
    const mycookies = useCookies(['access_token'])
    const access_token = mycookies[0].access_token
    const refresh_token = mycookies[0].refresh_token
    const [cartquan, setcartquan] = useState(1)
    const [selectedcolor, setselectedcolor] = useState()
    const [selectedsize, setselectedsize] = useState('XS')
    const [star, setStar] = useState(0)
    const [totalRating, setTotalRating] = useState(0)
    let [scroll_value, setscroll_value] = useState(0)
    const [delivery,setDelivery]=useState(0)
    const { productName } = useParams();
    const [pin, setpin] = useState()
    const handleSizeClick = (size) => {
        setselectedsize(size);
    };
    // console.log(pin)
    useEffect(()=>{
        if(product==null || product==""){
            axios.get(`${apiurl}/products/${productName}`)
            .then((res) => {
                dispatch(setSelectedProduct(res.data))
            })
            .catch((error)=>{
                console.log(error)
            })
        }else if(product.slug!==productName){
            axios.get(`${apiurl}/products/${productName}`)
            .then((res) => {
                dispatch(setSelectedProduct(res.data))
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    },[])
    const setpincode=(e)=>{
        setpin(e.target.value)
    }
    const check_pincode=(e)=>{
        if(pin.length!==6){
            alert("Fill Valid Pin Code")
            return
        }
        e.preventDefault();
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
    
        fetch(`${apiurl}/shiprocket/fetch-address/${pin}/`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.status === 200) {
                if (result.data.available_courier_companies.length > 0) {
                  setDelivery(result.data.available_courier_companies[0].estimated_delivery_days)
                }
              }
            })
            .catch((error) => console.log("error", error));
    }
    function selectColor(color) {
        let element = document.getElementById(color)
        let allElement = document.getElementsByClassName("colors-div")
        for (let i = 0; i < allElement.length; i++) {
            allElement[i].classList.remove("Highlight");
            allElement[i].firstElementChild.classList.remove("displayselectelement")
        }
        element.classList.add("Highlight");
        let innerHtml = element.firstElementChild
        innerHtml.classList.add("displayselectelement")
    }
    useEffect(() => {
        if (product) { // Check if product is not null
            selectColor(product.product_color);
            setTotalRating(product.rating_count);
            setStar(product.product_rating);
        }
        // selectColor(product.product_color)
        // setTotalRating(products.rating_count)
        // setStar( products.product_rating)
    }, [])

    useEffect(() => {
        setproducts(product);
        dispatch(setSelectedProduct(product))
    }, [dispatch])

    const userid = localStorage.getItem('token')
    let user_id;

    if (userid) {
        user_id = jwtDecode(userid)
    }

    // console.log((user_id?.user_id), 'user id console is here')

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch(`/api/products/${id}`);
                const data = await response.json();
                // console.log('Fetched product data:', data);
                dispatch(setSelectedProduct(data));
            } catch (error) {
                // console.error('Failed to fetch product', error);
            }
        };

        if (!product || product.id !== parseInt(id)) {
            fetchProduct();
        }
    }, [id, dispatch, product]);

    if (!product) {
        return <div>Loading...</div>;
    }
    const handleIncrease = () => {
        setcartquan(cartquan + 1);
    };

    const handleDecrease = () => {
        if (cartquan > 1) {
            setcartquan(cartquan - 1);
        } else {
            window.alert('atleaset one product is rquired')
        }
    };

    // console.log(location.country, 'location is here')
    const getpdata = (product) => {
        if (!selectedsize) {
            toast.error('please select a color to add product into cart');
        } else {
            if (!user_id?.user_id) {
                axios.post(`${apiurl}/cart-view/`, {
                    "product": product.id,
                    "user": null,
                    "qty": cartquan,
                    "price": product.price,
                    "shipping_amount": product.shipping_amount,
                    "country": 'India',
                    "size": selectedsize,
                    "color": product.product_color,
                    "cart_id": cart_id
                })
                    .then(response => {
                        // console.log('product created succes fully', response.data);
                        //toast.success('Added to Cart Successfully');
                        props.fetchCartData()
                    })
                    .catch(error => {
                        // console.error('There was an error submitting the form:', error);
                    });
            } else {
                axios.post(`${apiurl}/cart-view/`, {
                    "product": product.id,
                    "user": user_id?.user_id,
                    "qty": cartquan,
                    "price": product.price,
                    "shipping_amount": product.shipping_amount,
                    "country": 'India',
                    "size": selectedsize,
                    "color": product.product_color,
                    "cart_id": cart_id
                })
                    .then(response => {
                        // console.log('product created succes fully', response.data);
                        //toast.success('Added to Cart Successfully');
                        props.fetchCartData()
                    })
                    .catch(error => {
                        // console.error('There was an error submitting the form:', error);
                    });
            }
        }
        // console.log(formdata)
    }
    const selectocolorhandle = async (name) => {
        selectColor(name)
        axios.get(`${apiurl}/products/`)
            .then((res) => {
                // console.log(res.data, 'products data is here')
                setproducts(res.data); // Directly set res.data without JSON.parse
                // console.log(Array.isArray(res.data));
                const filtdproduct = res.data.filter(product => product.product_color == name); // Use res.data instead of products to filter
                // console.log(filtdproduct[0], 'filtered product is here')
                // Find the part before the last slash 
                setTotalRating(filtdproduct[0].rating_count);
                setStar(filtdproduct[0].product_rating);
                window.history.replaceState(null, "", `/product/${filtdproduct[0].slug}`);
                dispatch(setSelectedProduct(filtdproduct[0]));
            })
            .catch((err) => {
                console.log(err)
            })
    }
    // const findProduct = product.filter(product => product.slug == localStorage.getItem('productid'))
    // console.log(selectedsize, 'selected size console')
    // console.log("gggg",product)

    const style = {
        backgroundColor: product.OutdoorColor[0].color_code,
        boxShadow: `${product.OutdoorColor[0].color_code} 0px -12px 12px 5px`
    }

    const buynow = (product) => {
        if (!selectedsize) {
            toast.error('Please select a size to add the product to the cart.');
            return;
        }

        if (!user_id?.user_id) {
            axios.post(`${apiurl}/cart-view/`, {
                "product": product.id,
                "user": null,
                "qty": cartquan,
                "price": product.price,
                "shipping_amount": product.shipping_amount,
                "country": 'India',
                "size": selectedsize,
                "color": product.product_color,
                "cart_id": cart_id
            })
                .then(response => {
                    // toast.success('Added to Cart Successfully');
                    props.fetchCartData()
                    navigate('/check-out');
                })
                .catch(error => {
                    // Handle error
                    console.error('There was an error adding the product to the cart:', error);
                });
        } else {
            axios.post(`${apiurl}/cart-view/`, {
                "product": product.id,
                "user": user_id?.user_id,
                "qty": cartquan,
                "price": product.price,
                "shipping_amount": product.shipping_amount,
                "country": 'India',
                "size": selectedsize,
                "color": product.product_color,
                "cart_id": cart_id
            })
                .then(response => {
                    // toast.success('Added to Cart Successfully');
                    props.fetchCartData()
                    navigate('/check-out');
                })
                .catch(error => {
                    // Handle error
                    console.error('There was an error adding the product to the cart:', error);
                });
        }
    };

    var showprice = product.price * 12 / 100
    const oldPrice = parseFloat(product.old_price);
    const newPrice = parseFloat(product.price);

    const nameModifyString = (name) => {
        let name1 = name.split("(")
        return name1[0]
    };
    function generateArrayUpTo(n) {
        return Array.from({ length: n }, (_, i) => i);
    }

    const handleScrol = () => {
        console.log("svgsirgailwhegil")
        // const element = document.getElementsByClassName("home-shop-know")[0];
        // if (element) {
        //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // }

    }
    const scroll_to_specification = () => {
        const element = document.getElementsByClassName("div-logos")[0];
        const element1 = document.getElementsByClassName("div-logos")[0];
        element1.scrollIntoView({ behavior: 'smooth', block: 'start' });
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });

    }
    const imagescroll = (a) => {
        const element = document.getElementById("scroll-image");
        // console.log(scroll_value)
        if (1200 > scroll_value) {
            setscroll_value(scroll_value + a)
            element.style.transition = "transform ease-in-out .2s"
            element.style.transform = `translateX(-${scroll_value}px)`;

        }
        else {
            element.style.transition = "transform ease-in-out .2s"
            element.style.transform = `translateX(-${scroll_value}px)`;
            setscroll_value(0)
        }

    }
    return (
        <>
            {/* <GetCurrentAddress /> */}
            <CartID />
            {/* <Headermain /> */}
            <div className='prouct-main-div'>
                <div className='productheader'>
                    <Carousel className='desktop-productheader'>
                        {
                            product.gallery?.map((value, index) => {
                                // console.log(product, 'product data is here')
                                return (
                                    <Carousel.Item key={index}>
                                        <img src={`${value.image}`} loading="lazy" />
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                    <Carousel className='mobile-productheader'>
                        {
                            product.mobile_images?.map((value, index) => {
                                // console.log(product, 'product data is here')
                                return (
                                    <Carousel.Item key={index}>
                                        <img src={`${value.image}`} loading="lazy" />
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                    <style>
                        {
                            `
                                .carousel-indicators button:nth-child(1) {
                                background-image: url(${product.gallery[0]?.image});
                                    background-position: center;
                                    background-size: cover;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;
                                }
                                .carousel-indicators button:nth-child(2) {
                                background-image: url(${product.gallery[1]?.image});
                                    background-position: center;
                                    background-size: cover;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;
                                }
                                .carousel-indicators button:nth-child(3) {
                                background-image: url(${product.gallery[2]?.image});
                                    background-position: center;
                                    background-size: cover;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;
                                }
                            `}
                    </style>
                    <style>
                        {
                            `
                                .mobile-productheader .carousel-indicators button:nth-child(1) {
                                background-image: url(${product.mobile_images[0]?.image});
                                    background-position: center;
                                    background-size: cover;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;
                                }
                                .mobile-productheader .carousel-indicators button:nth-child(2) {
                                background-image: url(${product.mobile_images[1]?.image});
                                    background-position: center;
                                    background-size: cover;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;
                                }
                                .mobile-productheader .carousel-indicators button:nth-child(3) {
                                background-image: url(${product.mobile_images[2]?.image});
                                    background-position: center;
                                    background-size: cover;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;
                                }
                            `}
                    </style>
                </div>
                <div className='product-details'>
                    {/* <h3 className='heading'>{product.description}</h3> */}
                    <div className="heading-div">
                        <div className='discription-div'>
                            <h3 style={{fontFamily:"Helvetica"}} className='heading'>{product.description}</h3>
                        </div>
                        <div className='star-div' onClick={() => handleScrol()}>

                            {
                                generateArrayUpTo(star)?.map((value, index) => {
                                    // console.log(value.name,product.product_color)
                                    return (
                                        <img style={{objectFit:"fill"}} className='star-img' key={value} src="/Logos/star.png" height={40} width={40} loading="lazy"/>
                                    )
                                })
                            }
                            &nbsp;
                            {totalRating !== 0 && (
                                <h4 className="rating-count" style={{ fontWeight: "bold", marginTop: "15px", fontFamily: "Helvetica" }}>{product.product_rating.toFixed(1)}&nbsp;({totalRating})</h4>
                            )}
                        </div>
                    </div>
                    <div className='row px-5 pt-3'>
                        <div className='col-sm-6'>
                            {/* <div className='rating'>
                                <div>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-regular fa-star"></i>
                                </div>
                                <p>38 reviews</p>
                            </div> */}
                            <div className='colors mt-2'>
                                {/* <h6>color : {product.product_color}</h6> */}
                                <div className='colors-main'>
                                    {
                                        product.color?.map((value, index) => {
                                            // console.log(value.name,product.product_color)
                                            return (
                                                <div className='colors-div' key={index} id={value.name} onClick={() => selectocolorhandle(value.name)} style={{ backgroundColor: `${value.color_code}` }}>
                                                    <div className='nameofcolor' style={{ boxShadow: "none",fontFamily:"Helvetica !important" }}>{nameModifyString(value.name)}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='sizes' style={{ marginTop: "30px" }}>
                                <h6 style={{fontFamily:"Helvetica !important"}}>Size</h6>
                                <div className='sizes-main mt-3 mb-4'>
                                    {
                                        product.size?.map((value, index) => {
                                            return (
                                                <div key={index} className={`sizes-div ${selectedsize == value.name ? 'selected' : ''}`} onClick={() => handleSizeClick(value.name)}>
                                                    <p>{value.name}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='divsizespdf'>
                                <a href='/assets/Model Size.pdf' target='_blank'>
                                    <h6 style={{fontFamily:"Helvetica !important"}}>Size Guide <i className="fa-solid fa-ruler"></i></h6>
                                </a>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='product-price'>
                                <h1 style={{ fontFamily: "Helvetica" }}>₹{Math.ceil(showprice + Number(product.price))} <span className='mrp mobile-product-price' style={{ color: "black", marginLeft: "3px" }}>MRP</span><span className='lastprice mobile-product-price'>₹{Number(product.old_price)}</span> <span className='distext mobile-product-price'> {Math.ceil(((oldPrice - newPrice) / oldPrice) * 100)}% Off</span></h1>
                            </div>
                            <h6 style={{ fontSize: "12px",fontFamily: "Helvetica !important" }}>Inclusive of all taxes</h6>
                            <div className='product-info' style={{fontFamily: "Helvetica"}}>
                                <ul>
                                    <li className='instock' style={{fontFamily: "Helvetica"}}><i className="fa-solid fa-circle"></i>In Stock</li>
                                    <li><i className="fa-solid fa-circle" style={{fontFamily: "Helvetica"}}></i>Ships in 24hrs</li>
                                    <li className='ps-2' style={{fontFamily: "Helvetica"}}><i className="fa-solid fa-circle"></i>14 Days Return</li>
                                </ul>
                            </div>
                            {/* <div className='quantity'>
                                <h3 className='p-0'>Quantity</h3>
                                <div className='quantity-div'>
                                    <span onClick={handleDecrease} disabled={cartquan <= 1}>-</span>
                                    <input type='number' value={cartquan} />
                                    <spna onClick={handleIncrease}>+</spna>
                                </div>
                            </div> */}
                            <div className="pincode_check">
                                <div><i class="fa-sharp fa-solid fa-location-dot"></i>&nbsp;Delivery</div>
                                <div className='pincode_check_first'><input type="text" placeholder='Pincode' onChange={setpincode}/> <span className='pincode_check_btn' onClick={check_pincode} >check</span></div>
                            </div>
                            {delivery !== 0 && <div className='location_detail'>Delivered within {delivery} days</div>}
                            <div className='mobileaddtocart'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <button style={{fontFamily:"Helvetica !important"}} className='add-to-cart' onClick={() => getpdata(product)} >Add to Bag</button>
                                    </div>
                                    <div className='col-6'>
                                        <button style={{fontFamily:"Helvetica !important"}} className='buy-it-now' onClick={() => buynow(product)}>Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="logo-section">
                    <div style={{ fontFamily: "Helvetica !important" }} className="learn" onClick={() => scroll_to_specification()}>Learn More</div>
                    <div className="logo-section-img">
                        <div><img src="/Logos/return.png" loading="lazy" /></div>
                        <div><img src="/Logos/supima.png" className='logo-section-img1' loading="lazy" /></div>
                        <div><img src="/Logos/fast-delivery.png" loading="lazy" /></div>
                    </div>
                </div>
                <div className="specification">
                    <div className="inner-specification">
                        <div className="inner-specification-first">
                            <h2 style={{ fontFamily: "Helvetica" }}><b>Product Specifications</b></h2>
                            <ul style={{ fontFamily: "Helvetica !important" }}>
                                <li>Breathable Fabric that helps in airflow</li>
                                <li>Invisible Stitching increases the durability</li>
                                <li>Durable up to 200 Washes</li>
                                <li>Odor Resistance makes you smell fresh</li>
                                <li>Fitting so seamless you'll forget it's there.</li>
                            </ul>
                        </div>
                        <div className="inner-specification-second second-inner">
                            <div >
                                <h4><b>Fabric Type</b></h4>
                                <h6>Supima Cotton</h6>
                            </div>
                            <div >
                                <h4><b>Fit</b> </h4>
                                <h6>Regular</h6>
                            </div>
                        </div>
                    </div>
                    <div className="inner-specification-img">
                        <div style={{ height: "100%", width: "80%", display: 'flex', flexDirection: "row", overflow: "none", justifyContent: "start" }} id='scroll-image'>
                            <img src="/infocov/Green.jpg" loading="lazy" />
                            <img src="/infocov/Feather.jpg" loading="lazy" />
                            <img src="/infocov/Brown.jpg" loading="lazy" />
                        </div>
                    </div>
                    <div onClick={() => imagescroll(600)} className="slide-btn">
                        <img src="/Logos/angle.png" loading="lazy" />
                    </div>
                </div>
                <div className="specification-mobile">
                    <div className="mobile-inner-specification-first">
                        <h4 style={{ fontSize: "18px", fontFamily: "Helvetica !important" }}><b>Product Specifications</b></h4>
                        <ul style={{ fontSize: "15px", fontFamily: "Helvetica !important", fontWeight: "350" }}>
                            <li>Breathable Fabric that helps in airflow</li>
                            <li>Invisible Stitching increases the durability</li>
                            <li>Durable up to 200 Washes</li>
                            <li>Odor Resistance makes you smell fresh</li>
                            <li>Fitting so seamless you'll forget it's there.</li>
                        </ul>
                    </div>
                    <div className="mobile-inner-specification-img">
                        <img src="/infocov/Green.jpg" loading="lazy" />
                        <img src="/infocov/Feather.jpg" loading="lazy" />
                        <img src="/infocov/Brown.jpg" loading="lazy" />
                    </div>
                    {/* <h6 style={{ fontSize: "18px", fontWeight: "bold" }}> Tap Here for more details <img src="/Logos/down-arrow (1).png" height={20} width={25} /></h6> */}
                    <div className="mobile-inner-specification-second second-inner">
                        <div >
                            <h4 style={{ fontSize: "18px" }}><b>Fabric Type</b></h4>
                            <h6 style={{ fontSize: "15px" }}>Supima Cotton</h6>
                        </div>
                        <div >
                            <h4 style={{ fontSize: "18px" }}><b>Fit</b> </h4>
                            <h6 style={{ fontSize: "15px" }}>Regular</h6>
                        </div>
                    </div>
                </div>
                <div className="div-logos">
                    <h3><b>Best suitable for these occasions:</b></h3>
                    <div className="inner-div-logos">
                        <div>
                            <img src="/Logos/1.png" loading="lazy" />
                            <p>Beach</p>

                        </div>
                        <div>
                            <img src="/Logos/2.png" loading="lazy" />
                            <p>Meetings</p>
                        </div>
                        <div>
                            <img src="/Logos/3.png" loading="lazy" />
                            <p>Summers</p>
                        </div>
                        <div>
                            <img src="/Logos/4.png" loading="lazy" />
                            <p>Dates</p>
                        </div>
                    </div>
                    <div className="inner-div-logos">
                        <div>
                            <img src="/Logos/5.png" loading="lazy" />
                            <p>Travel</p>

                        </div>
                        <div>
                            <img src="/Logos/6.png" loading="lazy" />
                            <p>Sleeping</p>
                        </div>
                        <div>
                            <img src="/Logos/disco-ball 1.png" loading="lazy" />
                            <p>Party</p>
                        </div>
                        <div>
                            <img src="/Logos/travel 1.png" loading="lazy" />
                            <p>Outing</p>
                        </div>
                    </div>
                </div>
                <div className="detail-section">
                    <div className='inner-detail-section'>
                        <div className='detail-div'>
                            <h3><b>Breathability</b>&nbsp;<img src="/Logos/s1.png" height={40} width={40} loading="lazy" /></h3>
                            <p>It moves with you, offering a comfortable fit that doesn't restrict your movement. Its flexibility allows it to adapt to your body's contours, making it feel like a second skin.</p>
                        </div>
                        <div className='detail-div'>
                            <h3><b>Durability</b>&nbsp;<img src="/Logos/s2.png" height={40} width={40} loading="lazy" /></h3>
                            <p>It's reliable, always there to support you through life's adventures. Unlike fleeting trends, Supima's quality endures, offering lasting comfort and style that can be cherished for years to come.</p>
                        </div>
                    </div>
                    <div className='inner-detail-section'>
                        <div className='detail-div'>
                            <h3><b>Sustainability</b>&nbsp;<img src="/Logos/s3.png" height={40} width={40} loading="lazy" /></h3>
                            <p>Its production processes are carefully considered, minimizing harm to the planet. From sustainable farming practices to efficient manufacturing methods, Supima is a fabric that respects the delicate balance of nature.</p>
                        </div>
                        <div className='detail-div'>
                            <h3><b>Stretchability</b>&nbsp;<img src="/Logos/s4.png" height={40} width={40} loading="lazy" /></h3>
                            <p>It yields to your touch, molding effortlessly to your form, yet always returning to its original shape. Unlike a rigid embrace, Supima's flexibility offers a sense of freedom and comfort, allowing you to move with ease and grace.</p>
                        </div>
                    </div>
                </div>
                <div className="mobile-detail-section">
                    <div style={{ height: "21%" }} className='mobile-detail-div'>
                        <h3><b>Breathability</b>&nbsp;<img src="/Logos/s1.png" height={30} width={30} loading="lazy" /></h3>
                        <p>It moves with you, offering a comfortable fit that doesn't restrict your movement. Its flexibility allows it to adapt to your body's contours, making it feel like a second skin.</p>
                    </div>
                    <div className='mobile-detail-div'>
                        <h3 style={{ textAlign: "right" }}><img src="/Logos/s2.png" height={30} width={30} loading="lazy" />&nbsp;<b>Durability</b></h3>
                        <p style={{ textAlign: "right" }}>It's reliable, always there to support you through life's adventures. Unlike fleeting trends, Supima's quality endures, offering lasting comfort and style that can be cherished for years to come.</p>
                    </div>
                    <div style={{ height: "27%" }} className='mobile-detail-div'>
                        <h3><b>Sustainability</b>&nbsp;<img src="/Logos/s3.png" height={30} width={30} loading="lazy" /></h3>
                        <p>Its production processes are carefully considered, minimizing harm to the planet. From sustainable farming practices to efficient manufacturing methods, Supima is a fabric that respects the delicate balance of nature.</p>
                    </div>
                    <div className='mobile-detail-div'>
                        <h3 style={{ textAlign: "right" }}><img src="/Logos/s4.png" height={30} width={30} loading="lazy" />&nbsp;<b>Stretchability</b></h3>
                        <p style={{ textAlign: "right" }}>It yields to your touch, molding effortlessly to your form, yet always returning to its original shape. Unlike a rigid embrace, Supima's flexibility offers a sense of freedom and comfort, allowing you to move with ease and grace.</p>
                    </div>

                </div>
                <div className='outdore-images'>
                    <h2 className='text-center'>Super Comfy, Super Light </h2>
                    <div className='outdore-images-main'
                        style={style}
                    >
                        <div className='text-center'>
                            <img src={product.OutdoorImage[0].image} className='img-fluid rounded my-5 w-100 bigimage' loading="lazy" />
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 text-center pe-5'>
                                <img src={product.OutdoorImage[1].image} className='img-fluid rounded my-5 w-100' loading="lazy" />
                            </div>
                            <div className='col-sm-6 text-center ps-5'>
                                <img src={product.OutdoorImage[2].image} className='img-fluid rounded my-5 w-100' loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='home-shop-know'>
                    <div className='row'>
                        <div className='col-6 p-0'>
                            <img src='/assets/images/productimages/home/back 1.webp' className='img-fluid' loading="lazy" />
                        </div>
                        <div className='col-6 p-0'>
                            <img src='/assets/images/productimages/home/maroon 1.webp' className='img-fluid' loading="lazy" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 p-0'>
                            <img src='/assets/images/productimages/home/off white 1.webp' className='img-fluid' loading="lazy" />
                        </div>
                        <div className='col-6 p-0'>
                            <img src='/assets/images/productimages/home/green 1.webp' className='img-fluid' loading="lazy" />
                        </div>
                    </div>
                </div> */}
                {/* <Subscribe /> */}
            </div>
        </>
    )
}
