import React from 'react';
import './facbicinfo.css';

export default function Fabricinfo() {
    return (
        <>
            <div className='fabric-main'>
                <div className='fabric-first'>
                </div>
                {/* <div className='fabricsecond'>
                    <div className='container'>
                        <img src='/assets/images/productimages/fabric/feature-1.jpg' className='img-fluid'/>
                    </div>
                </div> */}
            </div>
        </>
    )
}
