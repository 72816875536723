import React, { useEffect, useState } from "react";
import "./checkout.css";
import axios from "axios";
import { apiurl } from "../../../urls/url";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

// import GetCurrentAddress from '../../comcomponents/UserCountry';
export default function Checkout(props) {
  const navigate = useNavigate();
  const [cartdata, setcartdata] = useState();
  const [carttotaldata, setcarttotaldata] = useState();
  const [coupon, setcoupon] = useState();
  const [checksameadd, setchecksameadd] = useState(false);
  const [orderid, setorderid] = useState();
  const userid = localStorage.getItem("token");
  const [shipping, setshipping] = useState("");
  const [add, setAdd] = useState("");
  const [states, setState] = useState([]);
  const [cities, setcities] = useState([]);
  const [discount, setdiscount] = useState("");
  const [o_id, setO_id] = useState("");
  const [shipping_type, setShipping_type] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [orderdata,setOrderData]=useState()
  const [couponcodeapply, setcouponcodeapply] = useState(false);
  var id;
  if (userid) {
    id = jwtDecode(userid);
  }
  // console.log(id)
  const [formData, setFormData] = useState({
    firstName: id ? id.full_name :"",
    lastName: "",
    country: "India",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phoneNo: "",
    email: id?.email,
  });

  const [shippingformData, setShippingFormData] = useState({
    firstName:"",
    lastName: "",
    country: "India",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phoneNo: "",
    email: "",
  });

  const fetchAddress = (pincode, setFormData) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${apiurl}/shiprocket/fetch-address-detail/${Number(pincode)}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.postcode_details) {
          setFormData((prevFormData) => ({
            ...prevFormData,  // Keep the previous form data
            city: result.postcode_details.city || prevFormData.city,
            state: result.postcode_details.state || prevFormData.state,
            country: result.postcode_details.country || prevFormData.country,
            pincode: result.postcode_details.postcode || prevFormData.pincode,
          }));
        }
      })
      .catch((error) => console.log("error", error));
  };

  const cartid = localStorage.getItem("randomString");
  const ShippingHandleChange = (e) => {
    const { name, value } = e.target;
    setShippingFormData({
      ...shippingformData,
      [name]: value,
    });
    if (name === "pincode" && value.length === 6) {
      fetchAddress(value,setShippingFormData);
    }
  };
  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "pincode" && value.length === 6) {
      fetchAddress(value,setFormData);
    }
  };

  const generateOrderDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add leading zero
    const day = String(currentDate.getDate()).padStart(2, '0'); // Add leading zero
    const hours = String(currentDate.getHours()).padStart(2, '0'); // Add leading zero
    const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // Add leading zero
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };  

  const getOrderData=(o_id)=>{
    axios.get(`${apiurl}/checkout/${o_id}/`)
    .then((res) => {
      setOrderData(res.data)
      console.log("orderdat",res.data)
      // console.log("data",createOrderData(formData, shippingformData, checksameadd, o_id,res.data))
      createOrder(createOrderData(formData, shippingformData, checksameadd, o_id,res.data))
    })
    .catch((err) => {
      console.log(err)
    });
  }
  const createOrderData = (formData, shippingformData, checksameadd, o_id,data) => {
    const orderdata=data
    const Data = {
      order_id: o_id,
      order_date: generateOrderDate(),
      pickup_location: "Home", 
      channel_id: "", 
      comment:"", 
      billing_customer_name: formData?.firstName,
      billing_last_name: formData?.lastName,
      billing_address: formData?.address,
      billing_address_2: formData?.address2 || "", 
      billing_city: formData?.city,
      billing_pincode: formData?.pincode,
      billing_state: formData?.state,
      billing_country: formData?.country,
      billing_email: formData?.email,
      billing_phone: formData?.phoneNo,
      shipping_is_billing: checksameadd,
      shipping_customer_name: checksameadd ? "" : shippingformData?.firstName,
      shipping_last_name: checksameadd ? "" : shippingformData?.lastName,
      shipping_address: checksameadd ? "" : shippingformData?.address,
      shipping_address_2: checksameadd ? "" : shippingformData?.address2 || "", 
      shipping_city: checksameadd ? "" : shippingformData?.city,
      shipping_pincode: checksameadd ? "" : shippingformData?.pincode,
      shipping_country: checksameadd ? "" : shippingformData?.country,
      shipping_state: checksameadd ? "" : shippingformData?.state,
      shipping_email: checksameadd ? "" : shippingformData?.email,
      shipping_phone: checksameadd ? "" : shippingformData?.phoneNo,
      order_items: [],
      payment_method: shipping_type === "POD" ? "Prepaid" : "COD",
      shipping_charges: Number(orderdata?.shipping_amount),
      giftwrap_charges: 0,
      transaction_charges: 0,
      total_discount:Number(orderdata?.saved),
      sub_total: Number(orderdata?.total-orderdata?.shipping_amount+Number(orderdata?.saved)),
      length: 30.5,
      breadth: 25.5,
      height:  5,
      weight: 0.15,
    };
    orderdata?.orderitem.forEach((product) => {
      Data.order_items.push({
        name:`${product.color} 100 Supima Cotton T-Shirts with Invisible Stitching`,
        sku:product.product.sku,
        units: product.qty,
        selling_price: 1999,
        discount: 0, // Optional field
        tax: "12" || "", // Optional field
        hsn: 61091000,
      });
    });
    console.log("shiping_data",Data)
    return Data;
  };



  const sendCreateOrderData=(data)=>{
    try{
      axios.post(`${apiurl}/shiprocket-orders/${Number(data.channel_order_id)}/`,{
        "product_order_id":data.channel_order_id,
        "order_id": data.order_id,
        "awb_code": data.awb_code,
        "shipment_id": data.shipment_id,
        "channel_order_id": data.channel_order_id
      })
        .then((res) => {
          // console.log(res)
          navigate("/thank-you");
        })
        .catch((err) => {
          navigate("/thank-you");
          console.log(err)
      });
    }
    catch(error){
      console.log(error)
    }
  }

  const createOrder = async (orderData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(orderData),
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${apiurl}/shiprocket/orders/create/`,
        requestOptions
      );
      const result = await response.json();
      console.log("result",result);
      sendCreateOrderData(result)
      return result
    } catch (error) {
      navigate("/thank-you");
      console.log("error", error);
      return error
    }
  };
  
  const handleCheckboxChange = (event) => {
    setchecksameadd(event.target.checked);
    if(event.target.checked){
      setShippingFormData({
        firstName: id ? id.full_name : formData.firstName ,
        lastName: formData.lastName,
        country: formData.country,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        pincode: formData.pincode,
        phoneNo: formData.phoneNo,
        email: id ? id.email : formData.email,
      })
    }
    else{
      setShippingFormData({
        firstName: "",
        lastName: "",
        country: "India",
        address: "",
        city: "",
        state: "",
        pincode: "",
        phoneNo: "",
        email: "",
      })
    }
    // console.log(checksameadd);
  };

  useEffect(() => {
    getcartdetails();
    carttotal();
  }, []);

  // console.log(cartid, 'cart id checkout')

  const getcartdetails = async () => {
    if (userid) {
      axios
        .get(`${apiurl}/cart-list/${cartid}/${id.user_id}`)
        .then((res) => {
          // console.log(res)
          setcartdata(res.data);
          carttotal();
        })
        .catch((err) => {
          // console.log(err)
        });
    } else {
      axios
        .get(`${apiurl}/cart-list/${cartid}`)
        .then((res) => {
          // console.log(res)
          setcartdata(res.data);
          carttotal();
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  const carttotal = () => {
    if (!userid) {
      axios
        .get(`${apiurl}/cart-detail/${cartid}/`)
        .then((res) => {
          // console.log(res, 'cart total values are here');
          setcarttotaldata(res.data);
        })
        .catch((err) => {
          // console.log(err)
        });
    } else {
      axios
        .get(`${apiurl}/cart-detail/${cartid}/${id.user_id}`)
        .then((res) => {
          // console.log(res, 'cart total values are here');
          setcarttotaldata(res.data);
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };
  const handleapplycoupon = (oid) => {
    axios
      .post(`${apiurl}/coupon/`, {
        order_oid: oid,
        coupon_code: coupon,
      })
      .then((res) => {
        // console.log(res)
        toast.success("Coupon Applied Sucessful");
        setcouponcodeapply(true)
        setdiscount(res.data.discount_amount);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Coupon is not valid");
      });
    // setcoupon('')
  };
  const applycoupon = async () => {
    if (o_id) {
      if(couponcodeapply){
        toast.error(
          "Coupon already applied. Please proceed to confirm your order."
        );
        return;
      }
      else{
        handleapplycoupon(o_id);
        return
      }
    }
    if (validateFormData(formData)) {
      if (userid) {
        axios
          .post(`${apiurl}/create-order/`, {
            full_name: formData.firstName + " " + formData.lastName,
            email: formData.email,
            mobile: formData.phoneNo,
            billing_address: formData.address,
            billing_city: formData.city,
            billing_state: formData.state,
            billing_country: formData.country,
            shipping_address: checksameadd ? formData.address : shippingformData.address,
            shipping_city: checksameadd ? formData.city : shippingformData.city,
            shipping_state: checksameadd ? formData.state : shippingformData.state,
            shipping_country: checksameadd ? formData.country : shippingformData.country,
            cart_id: cartid,
            user_id: id?.user_id,
            shipping_method: shipping_type,
          })
          .then((res) => {
            // console.log(res)
            // setorderid(res.data.order_oid)
            // setFormData({
            //     firstName: '' ,
            //     lastName: '',
            //     country: 'India',
            //     address: '',
            //     city: '',
            //     state: '',
            //     pincode: '',
            //     phoneNo: '',
            //     email: '',
            // })
            
            setIsDisabled(true);
            setO_id(res.data.order_oid);
            handleapplycoupon(res.data.order_oid);
            // handlePayment(res.data.order_oid)
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post(`${apiurl}/create-order/`, {
            full_name: formData.firstName + " " + formData.lastName,
            email: formData.email,
            mobile: formData.phoneNo,
            billing_address: formData.address,
            billing_city: formData.city,
            billing_state: formData.state,
            billing_country: formData.country,
            shipping_address: checksameadd ? formData.address : shippingformData.address,
            shipping_city: checksameadd ? formData.city : shippingformData.city,
            shipping_state: checksameadd ? formData.state : shippingformData.state,
            shipping_country: checksameadd ? formData.country : shippingformData.country,
            cart_id: cartid,
            user_id: null,
            shipping_method: shipping_type,
            })
          .then((res) => {
            // console.log(res)
            // setorderid(res.data.order_oid)
            // setFormData({
            //     firstName: '',
            //     lastName: '',
            //     country: 'India',
            //     address: '',
            //     city: '',
            //     state: '',
            //     pincode: '',
            //     phoneNo: '',
            //     email: '',
            // })
            // getOrderData(res.data.order_oid)
            setIsDisabled(true);
            setO_id(res.data.order_oid);
            handleapplycoupon(res.data.order_oid);
            // handlePayment(res.data.order_oid)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } 

    // console.log(carttotaldata, 'cart total in coupon')
  };

  const handlePayment = async (order_oid) => {
    if (shipping_type == "COD") {
      try {
        axios
          .post(`${apiurl}/codsucess-payment/`, {
            order_oid: order_oid,
            cart_id: cartid,
          })
          .then((res) => {
            // setFormData({
            //   firstName: "",
            //   lastName: "",
            //   country: "India",
            //   address: "",
            //   city: "",
            //   state: "",
            //   pincode: "",
            //   phoneNo: "",
            //   email: "",
            // });
            props.fetchCartData();
            // setShipping_type("");
            getOrderData(order_oid)
            // navigate("/thank-you"); // Redirect to the Thank You page
          })
          .catch((error) => {
            console.error("Error fetching COD-Sucess", error);
            alert("Error fetching COD-Sucess");
          });
      } catch (error) {
        console.error("Unexpected error occurred", error);
      }
    } else {
      try {
        // Create order on the backend
        const response = await axios.get(
          `${apiurl}/razorpay-checkout/${order_oid}`
        );
        const order = response.data;
        // console.log(order, "data response is here");
        const options = {
          key: order.key, // Replace with your Razorpay Key ID
          amount: order.amount,
          currency: order.currency,
          name: "NSTEE PVT LTD",
          description: "Payment",
          order_id: order.razorpay_order_id,
          handler: async function (response) {
            // console.log(response, "checkout data console is here");
            // Handle successful payment
            if (response.razorpay_payment_id) {
              // Optionally send response to your server for further validation
              console.log(response, "payment succes response data");
            } else {
              // Handle payment failure or cancellation
              //   console.error("Payment failed or was canceled");
              alert("Payment failed or was canceled");
            }

            try {
              // console.log(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature)
              const verifyResponse = await axios.post(
                `${apiurl}/payment-success/`,
                {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  cart_id: cartid,
                }
              );
              const verifyResult = verifyResponse.data;
              // console.log(verifyResult);
              // setO_id("");
              // setFormData({
              //   firstName: "",
              //   lastName: "",
              //   country: "India",
              //   address: "",
              //   city: "",
              //   state: "",
              //   pincode: "",
              //   phoneNo: "",
              //   email: "",
              // });
              props.fetchCartData();
              // setShipping_type("");
              getOrderData(order_oid)
              // navigate("/thank-you"); // Redirect to the Thank You page
            } catch (error) {
              // console.error("Payment verification failed", error);
              alert("Payment verification failed");
            }
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (error) {
        console.error("Error fetching checkout data", error);
        alert("Error fetching checkout data");
      }
    }
  };
    const validateFormData = (data) => {
      if (data.firstName?.trim() === "") {
          alert("Billing first name is required.");
          return false;
      }
      if (data.lastName?.trim() === "") {
          alert("Billing last name is required.");
          return false;
      }
      if (data.country?.trim() === "") {
          alert("Billing country is required.");
          return false;
      }
      if (data.address?.trim() === "") {
          alert("Billing address is required.");
          return false;
      }
      if (data.city?.trim() === "") {
          alert("Billing city is required.");
          return false;
      }
      if (data.state?.trim() === "") {
          alert("Billing state is required.");
          return false;
      }
      if (data.pincode?.trim() === "") {
          alert("Billing pincode is required.");
          return false;
      }
      if (!data.phoneNo?.trim() || data.phoneNo.trim().length !== 10) {
        alert("Billing phone number is required and must be exactly 10 digits.");
        return false;
      }
      if (data.email?.trim() === "") {
          alert("Billing email is required.");
          return false;
      }
      if (shipping === "") {
        alert("Shipping method is required.");
        return false;
      }
      if(!checksameadd){
        if (shippingformData.firstName?.trim() === "") {
          alert("Shipping first name is required.");
          return false;
        }
        if (shippingformData.lastName?.trim() === "") {
            alert("Shipping last name is required.");
            return false;
        }
        if (shippingformData.country?.trim() === "") {
            alert("Shipping country is required.");
            return false;
        }
        if (shippingformData.address?.trim() === "") {
            alert("Shipping address is required.");
            return false;
        }
        if (shippingformData.city?.trim() === "") {
            alert("Shipping city is required.");
            return false;
        }
        if (shippingformData.state?.trim() === "") {
            alert("Shipping state is required.");
            return false;
        }
        if (shippingformData.pincode?.trim() === "") {
            alert("Shipping pincode is required.");
            return false;
        }
        if (shippingformData.phoneNo?.trim() === "") {
            alert("Shipping phone number is required.");
            return false;
        }
        if (shippingformData.email?.trim() === "") {
            alert("Shipping email is required.");
            return false;
        }
      }
      return true; // All fields are valid
  };


  const checkout = () => {
    if (o_id) {
      handlePayment(o_id);
      return;
    }
    if (!validateFormData(formData)) {
      return;
    }
    if (userid) {
      axios
        .post(`${apiurl}/create-order/`, {
          full_name: formData.firstName + " " + formData.lastName,
          email: formData.email,
          mobile: formData.phoneNo,
          billing_address: formData.address,
          billing_city: formData.city,
          billing_state: formData.state,
          billing_country: formData.country,
          shipping_address: checksameadd ? formData.address : shippingformData.address,
          shipping_city: checksameadd ? formData.city : shippingformData.city,
          shipping_state: checksameadd ? formData.state : shippingformData.state,
          shipping_country: checksameadd ? formData.country : shippingformData.country,
          cart_id: cartid,
          user_id: id?.user_id,
          shipping_method: shipping_type,
        })
        .then((res) => {
          // console.log(res)
          setorderid(res.data.order_oid);
          // setFormData({
          //   firstName: "",
          //   lastName: "",
          //   country: "India",
          //   address: "",
          //   city: "",
          //   state: "",
          //   pincode: "",
          //   phoneNo: "",
          //   email: "",
          // });
          
          // setShipping_type("")
          setO_id(res.data.order_oid);
          handlePayment(res.data.order_oid);

        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${apiurl}/create-order/`, {
          full_name: formData.firstName + " " + formData.lastName,
          email: formData.email,
          mobile: formData.phoneNo,
          billing_address: formData.address,
          billing_city: formData.city,
          billing_state: formData.state,
          billing_country: formData.country,
          shipping_address: checksameadd ? formData.address : shippingformData.address,
          shipping_city: checksameadd ? formData.city : shippingformData.city,
          shipping_state: checksameadd ? formData.state : shippingformData.state,
          shipping_country: checksameadd ? formData.country : shippingformData.country,
          cart_id: cartid,
          user_id: null,
          shipping_method: shipping_type,
        })
        .then((res) => {
          setorderid(res.data.order_oid);
          setO_id(res.data.order_oid);
          handlePayment(res.data.order_oid);
          // setFormData({
          //   firstName: "",
          //   lastName: "",
          //   country: "India",
          //   address: "",
          //   city: "",
          //   state: "",
          //   pincode: "",
          //   phoneNo: "",
          //   email: "",
          // });
          // setShipping_type("")
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const cartdelete = (cartid, itemid) => {
    // console.log('cart and item id is here', cartid, itemid)
    axios
      .delete(`${apiurl}/cart-delete/${cartid}/${itemid}`)
      .then((res) => {
        // console.log(res)
        getcartdetails();
        props.fetchCartData();
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  const updatecart = (value, quantity) => {
    axios
      .put(`${apiurl}/update-cart-quantity/`, {
        product_id: value.product.id,
        qty: quantity,
        cart_id: value.cart_id,
        size: value.size,
      })
      .then((res) => {
        getcartdetails();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    if (cartdata) {
      const initialQuantities = cartdata.reduce((acc, item) => {
        acc[item.id] = item.qty;
        return acc;
      }, {});
      setQuantities(initialQuantities);
    }
  }, [cartdata]);

  const handleDecrease = (value) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      if (newQuantities[value.id] > 1) {
        newQuantities[value.id] -= 1;
        updatecart(value, newQuantities[value.id]);
      }
      return newQuantities;
    });
  };

  const handleIncrease = (value) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      newQuantities[value.id] += 1;
      updatecart(value, newQuantities[value.id]);
      return newQuantities;
    });
  };

  const handleQuantityChange = (id, value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue > 0) {
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities, [id]: parsedValue };
        const item = cartdata.find((item) => item.id === id);
        if (item) {
          updatecart(item, parsedValue);
        }
        return newQuantities;
      });
    }
  };

  const handleshipping = (ammount, a) => {
    setShipping_type(a);
    setshipping(ammount);
  };
  useEffect(() => {
    if (!cartdata || Object.keys(cartdata).length === 0) {
      setdiscount("");
      setshipping("");
    }
  }, [cartdata]);

  return (
    <>
      <div className="checkout-main">
        <h4 className="heading-dark text-center text-white mt-3 mb-5">
          CheckOut{" "}
          <img
            src="/assets/images/icons/carttwo.png"
            className="img-fluid heading-icon"
            alt="checkout-icon"
          />
        </h4>
        <div className="row">
          <div className="checkout-form col-sm-7 px-5">
            {/* {!userid ? <p className='text-white'>Do you wants to continue as guest or <Link to='/Login' className='text-warning'> login </Link></p> : <p></p>} */}
            <h4 className="text-white">Billing Address</h4>
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Phone No *"
                  name="phoneNo"
                  value={formData.phoneNo}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <input
              type="text"
              placeholder="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
            <input
              type="text"
              className="selectcountry"
              name="country"
              value={formData.country}
              onChange={handleChange}
              placeholder="Country"
            />
            <div className="row">
              <div className="col-6">
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  placeholder="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="billing-checkdiv">
              <input
                type="checkbox"
                checked={checksameadd}
                onChange={handleCheckboxChange}
              />
              <label for="blling" style={{ color: "white" }}>
              Shipping Address is Same as Billing Address
              </label>
            </div>
            {!checksameadd && (
            // { true && (
              <div className="shipping-info">
                <h4 style={{ color: "white" }}>Shipping Address</h4>
                <div className="row">
                  <div className="col-6">
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={shippingformData.firstName}
                      onChange={ShippingHandleChange}
                    ></input>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={shippingformData.lastName}
                      onChange={ShippingHandleChange}
                    ></input>
                  </div>
                </div>
                <div className="row">
                <div className="col-6">
                    <input
                      type="text"
                      placeholder="Pincode"
                      name="pincode"
                      value={shippingformData.pincode}
                      onChange={ShippingHandleChange}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      placeholder="Phone No *"
                      name="phoneNo"
                      value={shippingformData.phoneNo}
                      onChange={ShippingHandleChange}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-12">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={shippingformData.email}
                      onChange={ShippingHandleChange}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={shippingformData.address}
                  onChange={ShippingHandleChange}
                />
                <div className="row">
                <div className="col-12">
                    <input
                      type="text"
                      placeholder="Country"
                      name="country"
                      value={shippingformData.country}
                      onChange={ShippingHandleChange}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-6">
                    <input
                      type="text"
                      placeholder="City"
                      name="city"
                      value={shippingformData.city}
                      onChange={ShippingHandleChange}
                    />
                  </div>
                <div className="col-6">
                    <input
                      type="text"
                      placeholder="State"
                      name="state"
                      value={shippingformData.state}
                      onChange={ShippingHandleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  {/* <input type='button' value='submit' onClick={handleSubmit} /> */}
                </div>
              </div>
            )}
            <div className="shipping-type">
              <div className="standard">
                <input
                  type="radio"
                  value="prepaid"
                  name="shippingvalue"
                  disabled={isDisabled}
                  onClick={(e) => handleshipping(0, "POD")}
                ></input>
                <label for="standard shipping">
                  Fast Shipping:Enjoy Free Delivery
                </label>
              </div>
              <div className="fast">
                <input
                  type="radio"
                  value="cod"
                  name="shippingvalue"
                  onClick={(e) => handleshipping(100, "COD")}
                  disabled={isDisabled}
                ></input>
                <label for="Fast shipping">
                  COD (Cash On Delivery):Additional ₹100
                </label>
              </div>
              <input
                type="button"
                className="checkout"
                value="Confirm Order"
                onClick={checkout}
              />
            </div>
          </div>

          <div className="col-sm-5 px-5">
            <div className="order-info">
              <h5>Your Selection:</h5>

              {cartdata?.map((value, index) => {
                return (
                  <div className="orderinfo-main" key={index}>
                    <div className="order-info-image">
                      <img src={value.product.image} className="img-fluid" />
                    </div>
                    <div className="orderinfo-detail">
                      <div className="text-end">
                        <i
                          className="fa-solid fa-trash"
                          onClick={() => cartdelete(value.cart_id, value.id)}
                        ></i>
                      </div>
                      <p style={{ width: "93%" }}>
                        {value.product.description}
                      </p>
                      <div className="quantity">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDecrease(value)}
                        >
                          -
                        </span>
                        <input
                          type="number"
                          value={quantities[value.id] || value.qty}
                          onChange={(e) =>
                            handleQuantityChange(
                              value.id,
                              parseInt(e.target.value, 10)
                            )
                          }
                        ></input>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleIncrease(value)}
                        >
                          +
                        </span>
                      </div>
                      <h6>
                        Quantity : <span>{value.qty}</span>
                      </h6>
                      <h6>
                        Size : <span>{value.size}</span>
                      </h6>
                      <div className="order-info-price">
                        <h6>Total: </h6>{" "}
                        <h6>₹ {Math.ceil(Number(value.total))}/-</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="have-coupon">
                <h5 className="my-3">Have a Discount Coupon?</h5>
                <div className="maincoupondiv">
                  <input
                    type="text"
                    value={coupon}
                    onChange={(e) => setcoupon(e.target.value)}
                    placeholder="Add Discount Coupon"
                  />
                  <input
                    type="button"
                    value="Add Coupon"
                    onClick={applycoupon}
                  />
                  <ToastContainer />
                </div>
              </div>
            </div>
            <div className="carttotal-price">
              <div>
                <h5>Sub Total:</h5>{" "}
                <h5>₹ {Math.ceil(Number(carttotaldata?.total))}/-</h5>
              </div>
              {discount !== "" && discount !== undefined && (
                <div>
                  <h6>Discount:</h6>
                  <h6>₹ {Math.ceil(Number(discount))}/-</h6>
                </div>
              )}
              {shipping !== "" && shipping !== undefined && (
                <div>
                  <h6>Shipping:</h6>
                  <h6>₹ {Math.ceil(Number(shipping))}/-</h6>
                </div>
              )}
              {/* <div>
                                <h5>Tax:</h5> <h5>₹ {carttotaldata?.sub_total}/-</h5>
                            </div> */}
              <div>{/* <h6>Shipping</h6> <h6>{shipping}/-</h6> */}</div>
              {/* <div>
                                <h6>Discount Applied</h6> <h6> ₹ 1,199/-</h6>
                            </div> */}
              <div className="carttotal">
                <h5>Total:</h5>{" "}
                <h5>
                  ₹{" "}
                  {Math.ceil(
                    Number(carttotaldata?.total) +
                      Math.ceil(Number(shipping)) -
                      Math.ceil(Number(discount))
                  )}
                  /-
                </h5>
              </div>
            </div>
            <div className="shipping-type-1">
              <input
                type="button"
                className="checkout-1"
                value="Confirm Order"
                onClick={checkout}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
