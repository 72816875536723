import React from 'react';
import './forgotpassword.css';
import Forgotpasswordform from '../../../comcomponents/fogotpasswordform';

export default function Forgotpassword() {
  return (
    <div className='forgotpasswrd-main'>
        <Forgotpasswordform></Forgotpasswordform>
    </div>
  )
}
