import React from 'react';
import './ordertrackinfo.css';
import { useLocation } from 'react-router-dom';

export default function Ordertrackinfo(props) {
    const location = useLocation();
    const { data } = location.state || {};
    function formatDateTimeship(dateTimeString) {
        const date = new Date(dateTimeString);
        
        // Extracting date components
        const day = date.getDate().toString();   // e.g., "12"
        const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();  // e.g., "Sep"
        
        // Formatting time to 12-hour format with AM/PM
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');  // e.g., "12"
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;  // Convert to 12-hour format and handle midnight (0 hours)
    
        // Constructing the formatted date and time string
        return `${day}-${month} ${hours}:${minutes}${ampm}`;
    }
    
    function formatDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        
        // Extracting date components
        const day = date.getDate().toString().padStart(2, '0');   // e.g., "26"
        const month = date.toLocaleString('default', { month: 'short' });  // e.g., "Sep"
        const year = date.getFullYear();  // e.g., "2024"
        
        // Formatting time to 12-hour format with AM/PM
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');  // e.g., "15"
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;  // Convert to 12-hour format and handle midnight (0 hours)
      
        // Constructing the formatted date and time string
        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    }
    return (
        <>
            <div className="order-track-details">
                <div className='help-div pt-5'>
                    <div className='order-track-userinfo'>
                        <div className='inner-detail'>
                            {
                                data?.tracking_data?.shipment_track[0].current_status==="Delivered" ?
                                <div style={{marginBottom:"12px"}}>
                                    <h3 style={{fontWeight:"bolder"}}>&nbsp;Status</h3>
                                    <h2 style={{color:"green"}}><img src="/Logos/check.png" alt="logo" height={35} width={35} />&nbsp;{data?.tracking_data?.shipment_track[0].current_status}</h2>
                                </div>
                                :
                                <div style={{marginBottom:"12px"}}>
                                    <h3 style={{ fontWeight:"bolder"}}>&nbsp;Delivered In</h3>
                                    <h2 style={{color:"blue", fontSize:"20px",fontWeight:"bolder"}}><img src="/Logos/on-time.png" alt="logo" height={30} width={30} />&nbsp;&nbsp;{formatDateTime(data?.tracking_data?.shipment_track[0].edd).split(" ")[0]}</h2>
                                </div>  
                            }
                            <h4 style={{fontWeight:"bolder",borderBottom:"1px solid black", paddingBottom:"15px"}}><img src="/Logos/tracking1.png" alt="logo" height={40} width={40} />Last Status</h4>
                            <div style={{fontWeight:"bolder"}}>Data: <span style={{color:"#4A91C4"}}>{formatDateTime(data?.tracking_data?.shipment_track_activities[0].date)}</span></div>
                            <div style={{fontWeight:"bolder"}}>Activity: <span style={{color:"#4A91C4"}}>{data?.tracking_data?.shipment_track_activities[0].activity}</span></div>
                            <div style={{fontWeight:"bolder"}}>Location: <span style={{color:"#4A91C4"}}>{data?.tracking_data?.shipment_track_activities[0].location }</span></div>
                            <div style={{fontWeight:"bolder"}}>Status: <span style={{ color: data?.tracking_data?.shipment_track[0].current_status === "Delivered" ? "green" : "#4A91C4" }}>{data?.tracking_data?.shipment_track_activities[0]["sr-status-label"]}</span></div>
                            <br />
                            <h4 style={{fontWeight:"bolder",borderBottom:"1px solid black", paddingBottom:"15px"}}><img src="/Logos/tracking.png" alt="logo" height={40} width={40} /> &nbsp;Delivery Detail</h4>
                            <div>Courier Name: <span>{data?.tracking_data?.shipment_track[0].courier_name}</span></div>
                            <div>Est. Delivery: <span style={{color:"blue"}}>{formatDateTime(data?.tracking_data?.shipment_track[0].edd).split(" ")[0]}</span></div>
                            <div>Originated From: <span>{data?.tracking_data?.shipment_track[0].origin}</span></div>
                            <div>Destination: <span>{data?.tracking_data?.shipment_track[0].destination}</span></div>
                            <div>Packages: <span>{data?.tracking_data?.shipment_track[0].packages}&nbsp;P</span></div>
                            <div>Weight: <span>{data?.tracking_data?.shipment_track[0].weight}Kg</span></div>
                            <br />
                            <h4 style={{fontWeight:"bolder",borderBottom:"1px solid black", paddingBottom:"15px"}}><img src="/Logos/shopping-bag.png" alt="logo" height={30} width={30} />Order Detail</h4>
                            <div>Order ID:<span>&nbsp;{data?.tracking_data?.shipment_track[0].order_id}</span></div>
                            <div>AWB ID:&#9;<span>{data?.tracking_data?.shipment_track[0].awb_code}</span></div>
                            <div>Shipment ID:&#9;<span>{data?.tracking_data?.shipment_track[0].shipment_id}</span></div>
                            <div>
                            Current Status:&nbsp;
                                <span style={{ color: data?.tracking_data?.shipment_track[0].current_status === "Delivered" ? "green" : "#4A91C4",fontSize:"18px" }}>
                                    <img src="/Logos/check.png" alt="logo" height={20} width={20} />&nbsp;{data?.tracking_data?.shipment_track[0].current_status}
                                </span>
                            </div>
                            
                        </div> 
                    </div>
                    <div className='order-track-details-main' style={{height:"60rem" ,overflowY:"scroll",overflowX:"hidden", scrollbarWidth: "none"}}>
                        <h2 className='status_heading'>Order Status</h2>
                        <div className="order-track-show">
                        {
                            data?.tracking_data?.shipment_track_activities.map((activity, index) => (
                                <React.Fragment key={index}>
                                <div className='box'>
                                    <i className="fa-solid fa-check"></i>
                                    <div className='right-abs'>
                                    <h6 style={{fontWeight:"bolder",color:"blue",color: activity["sr-status-label"] === "DELIVERED" ? "#006400" : "#4A91C4"}}>{activity["sr-status-label"]}</h6> 
                                    <h6 style={{fontWeight:"bolder"}}>{activity.location}</h6>
                                    </div>
                                    <div style={{left: "-140%",top:"7px"}}>
                                        <h6 style={{fontWeight:"bolder"}}>{formatDateTimeship(activity.date).split(" ")[0]}</h6>
                                        <h6 style={{fontWeight:"bolder", fontSize:"13px", marginLeft:"10px"}}>{formatDateTimeship(activity.date).split(" ")[1]}</h6>
                                    </div>
                                </div>
                                
                                {
                                    index !== data?.tracking_data?.shipment_track_activities.length - 1 && (
                                        <div className='box-big'>
                                            
                                        </div>
                                    )
                                }
                                </React.Fragment>
                            ))
                            }


                            {/* // <div className='box'>
                            //     <i className="fa-solid fa-check"></i>
                            //     <div>
                            //         <h6>19-05-24</h6>
                            //         <h6>Order Placed</h6>
                            //     </div>
                            // </div>
                            // <div className='box-big'>

                            // </div> */}
                            {/* <div className='box'>
                                <i className="fa-solid fa-check"></i>
                                <div>
                                    <h6>19-05-24</h6>
                                    <h6>Order Placed</h6>
                                </div>
                            </div>
                            <div className='box-big'>

                            </div>
                            <div className='box'>
                                <div>
                                    <h6>19-05-24</h6>
                                    <h6>Order Placed</h6>
                                </div>
                            </div>
                            <div className='box-big'>

                            </div>
                            <div className='box'>
                                <div>
                                    <h6>19-05-24</h6>
                                    <h6>Order Placed</h6>
                                </div>
                            </div>
                            <div className='box-big'>

                            </div>
                            <div className='box'>
                                <div>
                                    <h6>19-05-24</h6>
                                    <h6>Order Placed</h6>
                                </div>
                            </div>
                            <div className='box-big'>

                            </div>
                            <div className='box'>
                                
                                <div>
                                    <h6>19-05-24</h6>
                                    <h6>Order Placed</h6>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className='join-throughmail'>
                        <h5 className='text-white'>Join Our mailing list for Cool offers & Discounts</h5>
                        <input type='email' placeholder='Your Mail' />
                        <input type='button' value='Submit' />
                    </div> */}
                </div>
            </div>
        </>
    )
}
