import React, { useState, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from "jwt-decode";

function Reviews() {
  const [reviews, setReviews] = useState([])
  const [updateReviews, setUpdateReviews] = useState({ reply: "" })
  const [user, setUser] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
        try {
            const decoded = jwtDecode(token); 
            setUser(decoded)
            if (decoded?.vendor_id === 0) {
                navigate('/'); 
            }
        }catch (error) {
            navigate('/login');
        }
    } else {
        navigate('/login');
    }
}, [navigate]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiurl}/vendor-reviews/${user.vendor_id}/`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
        }
    })
    .catch(
      (error) => {
        if(error.response){
          if(error.response.status===401){
            localStorage.removeItem("token");
            navigate('/login');
          }
        }
      }
    )
      setReviews(response.data);
      // console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if(user.vendor_id){
      fetchData();
    }
  }, [user]);

  const handleReplyChange = (event) => {
    console.log(updateReviews.reply);
    setUpdateReviews({
      ...updateReviews,
      [event.target.name]: event.target.value
    })
  }

  const handleReplySubmit = async (reviewId) => {
    reviewId.preventDefault()
    const formdata = new FormData()

    formdata.append('reply', updateReviews.reply)
    await axios.patch(`${apiurl}/vendor-reviews/${user.vendor_id}/${reviewId}`, formdata,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
      }
    }).then((res) => {
      console.log(res.data);
    }).catch(
      (error) => {
        if(error.response){
          if(error.response.status===401){
            localStorage.removeItem("token");
            navigate('/login');
          }
        }
      }
    )
  }
  // console.log(updateReviews);

  return (
    <div className="container-fluid pt-5 bg-secondary" id="main" >
      <div className="row row-offcanvas row-offcanvas-left h-100">
        <Sidebar />
        <div className="col-md-9 col-lg-10 main mt-5">
          <h4 className='text-center'>
            <i className="fas fa-star" /> Reviews and Rating
          </h4>
          <section
            className="p-4 p-md-5 text-center text-lg-start shadow-1-strong rounded"
            style={{
              backgroundImage:
                "url(https://mdbcdn.b-cdn.net/img/Photos/Others/background2.webp)"
            }}
          >
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-10">
                {reviews.map((review, index) => (
                  <div className="card mt-3 mb-3">
                    <div className="card-body m-3">
                      <div className="row">
                        <div className="col-lg-4 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
                          <img
                            src={review.profile.image}
                            className="rounded-circle img-fluid shadow-1"
                            alt="woman avatar"
                            style={{ width: 200, height: 200, objectFit: "cover" }}
                          />
                        </div>
                        <div className="col-lg-8">
                          <p className="text-dark  mb-2">
                            <b>Review: </b>
                            {review.review}
                          </p>
                          <p className="text-dark mb-2 d-flex">
                            <b>Reply: {""} </b>
                            {review.reply === null
                              ? <span className='ms-2'> No Response</span>
                              : <span className='ms-2'> {review.reply}</span>
                            }
                          </p>
                          <p className="text-dark mb-2">
                            <strong>Name</strong>:{review.profile.full_name}
                          </p>
                          <p className=" mb-2">
                            <b>Product</b>: {review?.product?.title}
                          </p>
                          <p className=" mb-0">
                            Rating:
                            {review.rating == 1 &&
                              <>
                                <span className='me-2 ms-2'>1</span>
                                < i className="fas fa-star" />
                              </>
                            }

                            {review.rating == 2 &&
                              <>
                                <span className='me-2 ms-2'>2</span>
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                              </>
                            }

                            {review.rating == 3 &&
                              <>
                                <span className='me-2 ms-2'>3</span>
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                              </>
                            }

                            {review.rating == 4 &&
                              <>
                                <span className='me-2 ms-2'>4</span>
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                              </>
                            }

                            {review.rating == 5 &&
                              <>
                                <span className='me-2 ms-2'>5</span>
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                                < i className="fas fa-star" />
                              </>
                            }
                          </p>
                          <div className="d-flex mt-3">
                            <div class="btn-group">
                              <Link to={`/vendor/reviews/${review.id}/`} class="btn btn-primary " >
                                <i className='fas fa-eye'></i> View Review
                              </Link>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {reviews < 1 &&
                  <h5 className='mt-4 p-3'>No reviews yet</h5>
                }
              </div>
            </div>
          </section>
        </div>

      </div>
    </div>
  )
}

export default Reviews