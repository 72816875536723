import React, { useState, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import moment from 'moment';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from "jwt-decode";
import Sidebar from './Sidebar';

function Contactus() {
    const [orders, setOrders] = useState(null)
    const [user, setUser] = useState({});
    const navigate = useNavigate();
    const [page,setPage]=useState(1)
    const [load,setload]=useState(true)
    console.log(orders)
    useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
        try {
            const decoded = jwtDecode(token); 
            setUser(decoded)
            if (decoded?.vendor_id === 0) {
                navigate('/'); 
            }
        } catch (error) {
            navigate('/login');
        }
    } else {
        navigate('/login');
    }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiurl}/api/contact-us/`,{
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
                    }
                })
                .catch(
                    (error) => {
                      if(error.response){
                        if(error.response.status===401){
                          localStorage.removeItem("token");
                          navigate('/login');
                        }
                      }
                    })
                console.log(response.data)
                setOrders(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if(user.vendor_id){
            fetchData();
        }
    }, [user]);
    function formatDateTime(dateString) {
        const date = new Date(dateString);
      
        // Extract day, month, year
        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
      
        // Extract time components
        const hours = String(date.getHours()).padStart(2, '0'); // Ensure two digits
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure two digits
        const seconds = String(date.getSeconds()).padStart(2, '0'); // Ensure two digits
      
        // Return the formatted date and time
        return {
          formattedDate: `${day}-${month}-${year}`, // Formatted date as dd-mm-yyyy
          formattedTime: `${hours}:${minutes}:${seconds}` // Formatted time as hh:mm:ss
        };
    }
    return (
        <div className="container-fluid pt-5 bg-dark" id="main" >
            <div className="row row-offcanvas row-offcanvas-left h-100 ">
                <Sidebar />
                <div className="col-md-9 col-lg-10 main bg-light mt-5">
                    <div className="mb-3 mt-3" style={{ marginBottom: 300 }}>
                        <div style={{height:"82vh",scrollbarWidth:"none"}} className='table-responsive'>
                            <h4> Contact Us  </h4>

                            <table className="table">
                                <thead className="table-dark">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Received</th>
                                    </tr>
                                </thead>
                                <tbody style={{fontSize:"13px"}}>
                                    {orders?.map((o, index) => (
                                        <tr key={index}>
                                            <th scope="row">{o.name}</th>
                                            <td>{o.phone_number}</td>
                                            <td>{o.email}</td>
                                            <td style={{width:"20%"}}>{o.subject}</td>
                                            <td style={{width:"40%"}}>{o.message}</td>
                                            <td>{formatDateTime(o.created_at).formattedDate} <br />{formatDateTime(o.created_at).formattedTime}</td>
                                        </tr>
                                    ))}

                                    {orders < 1 &&
                                        <h5 className='mt-4 p-3'>No orders yet</h5>
                                    }
                                </tbody>
                            </table>
                            {/* <div style={{height:"8%",display:load? "block" :"none"}}>
                                <button className="btn btn-dark btn-sm" style={{marginLeft:"35%",width:"30%"}} onClick={fetchDataPagination}>Load More</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactus