import React, { useState, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import moment from 'moment';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from "jwt-decode";
import Sidebar from './Sidebar';

function Orders() {
    const [orders, setOrders] = useState(null)
    const [user, setUser] = useState({});
    const navigate = useNavigate();
    const [page,setPage]=useState(1)
    const [load,setload]=useState(true)
    console.log(orders)
    useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
        try {
            const decoded = jwtDecode(token); 
            setUser(decoded)
            if (decoded?.vendor_id === 0) {
                navigate('/'); 
            }
        } catch (error) {
            navigate('/login');
        }
    } else {
        navigate('/login');
    }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiurl}/vendor/orders/${user.vendor_id}/`,{
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
                    }
                })
                .catch(
                    (error) => {
                      if(error.response){
                        if(error.response.status===401){
                          localStorage.removeItem("token");
                          navigate('/login');
                        }
                      }
                    })
                setOrders(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if(user.vendor_id){
            fetchData();
        }
    }, [user]);
    const fetchDataPagination = async () => {
        if(!load){
            return alert("No More Data")
        }
        try {
            const response = await axios.get(`${apiurl}/vendor/orders/${user.vendor_id}/?page=${page+1}`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
                }
            })
            .then((response)=>{
                setPage(page+1)
                setOrders((prevOrders) => [
                ...prevOrders,   // Spread the existing orders
                ...response.data       // Append the new data (ensure this is an array)
            ]);  
            })
            .catch(
                (error) => {
                    setload(false)
                  if(error.response){
                    if(error.response.status===401){
                      localStorage.removeItem("token");
                      navigate('/login');
                    }
                }
            })
            // setOrders(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div className="container-fluid pt-5 bg-dark" id="main" >
            <div className="row row-offcanvas row-offcanvas-left h-100 ">
                <Sidebar />
                <div className="col-md-9 col-lg-10 main bg-light mt-5">
                    <div className="mb-3 mt-3" style={{ marginBottom: 300 }}>
                        <div style={{height:"82vh",scrollbarWidth:"none"}} className='table-responsive'>
                            <h4><i class="bi bi-cart-check-fill"></i> All Orders  </h4>

                            <table className="table">
                                <thead className="table-dark">
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders?.map((o, index) => (
                                        <tr key={index}>
                                            <th scope="row">{o.oid}</th>
                                            <td>{o.full_name}</td>
                                            <td>{moment(o.date).format("MM/DD/YYYY")}</td>
                                            <td>{o.order_status}</td>
                                            <td>
                                                <Link to={`/vendor/product/${o.oid}/`} className="btn btn-dark mb-1">
                                                    <i className="fas fa-eye" />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}

                                    {orders < 1 &&
                                        <h5 className='mt-4 p-3'>No orders yet</h5>
                                    }
                                </tbody>
                            </table>
                            <div style={{height:"8%",display:load? "block" :"none"}}>
                                <button className="btn btn-dark btn-sm" style={{marginLeft:"35%",width:"30%"}} onClick={fetchDataPagination}>Load More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Orders