import React, { useState, useEffect,useRef } from 'react'
import Swal from 'sweetalert2'
import Sidebar from './Sidebar';
import { useNavigate,useNavigation, useParams } from 'react-router-dom';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from "jwt-decode";

function AddShiprocketOrder() {
    const params=useParams()
    const [shiprocketOrder, setShiprocketOrder] = useState([])
    const [billing, setbilling] = useState([])
    const [specifications, setSpecifications] = useState([{
        discount: "",
        hsn: 0,
        name:"",
        selling_price: "",
        sku: "",
        tax: "",
        units: 0
    }]);
    const [courierDetail, setCourierDetail] = useState({
        "payment_method":"",
        "shipping_charges":0,
        "total_discount":0,
        "sub_total":0,
        "length":0,
        "breadth":0,
        "height":0,
        "weight":0,
    });
    const [oderInfo,setOrderInfo]=useState({})
    const [user, setUser] = useState({});
    const navigate = useNavigate()


    useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
          try {
              const decoded = jwtDecode(token); 
              setUser(decoded)
              if (decoded?.vendor_id === 0) {
                  navigate('/'); 
              }
          } catch (error) {
              navigate('/login');
          }
      } else {
          navigate('/login');
      }
  }, [navigate]);
    const handleAddMore = (setStateFunction) => {
        setStateFunction((prevState) => [...prevState, {}]);
    };
    const handleRemove = (index, setStateFunction) => {
        setStateFunction((prevState) => {
            const newState = [...prevState];
            newState.splice(index, 1);
            return newState;
        });
    };

    const handleInputChange = (index, field, value, setStateFunction) => {
        setStateFunction((prevState) => {
            const newState = [...prevState];
            newState[index][field] = value;
            return newState;
        });
    };

    const handleOrderinfoInputChange = (event) => {
        setOrderInfo({
            ...oderInfo,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };
    const handleCourierInputChange = (event) => {
        setCourierDetail({
            ...courierDetail,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };
    const handleBillingInputChange = (event) => {
        setbilling({
            ...billing,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };

    const handleProductInputChange = (event) => {
        setShiprocketOrder({
            ...shiprocketOrder,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };
    const validateSpecifications = (specs) => {
        return specs.every((item) => {
          if (
            !item.name || 
            !item.sku || 
            !item.selling_price || 
            item.units <= 0 || 
            item.hsn <= 0
          ){
            return false;
          }
          return true;
        });
    };
    const sendCreateOrderData=(data)=>{
        try{
          axios.post(`${apiurl}/shiprocket-orders/${Number(data.channel_order_id)}/`,{
            "product_order_id":data.channel_order_id,
            "order_id": data.order_id,
            "awb_code": data.awb_code,
            "shipment_id": data.shipment_id,
            "channel_order_id": data.channel_order_id
          })
            .then((res) => {
              navigate("/vendor/ship-rockets-order/");
            })
            .catch((err) => {
              console.log(err)
            });
        }
        catch(error){
          console.log(error)
        }
      }

    const createAdhocOrder = async (orderData) => {
        const isEmpty = (value) => !value || value.trim() === "";

        // Helper function to validate email
        const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

        // Helper function to validate phone number (basic check for 10 digits)
        const isValidPhone = (phone) => /^[0-9]{10}$/.test(phone);

        // Validate the form fields
        if (isEmpty(oderInfo.order_id)) return alert("Order ID is required.");
        if (isEmpty(oderInfo.order_date)) return alert("Order date is required.");
        if (isEmpty(oderInfo.pickup_code)) return alert("Pickup location is required.");
        
        // Validate billing details
        if (isEmpty(billing.billing_name)) return alert("Billing name is required.");
        if (isEmpty(billing.billing_address)) return alert("Billing address is required.");
        if (isEmpty(billing.billing_city)) return alert("Billing city is required.");
        if (isEmpty(billing.billing_pincode)) return alert("Billing pincode is required.");
        if (isEmpty(billing.billing_state)) return alert("Billing state is required.");
        if (isEmpty(billing.billing_country)) return alert("Billing country is required.");
        if (isEmpty(billing.billing_email) || !isValidEmail(billing.billing_email)) return alert("A valid billing email is required.");
        if (isEmpty(billing.billing_phone) || !isValidPhone(billing.billing_phone)) return alert("A valid 10-digit billing phone number is required.");

        // Validate shipping details if shipping is not the same as billing
        if (!shiprocketOrder.shipping_is_billing) {
            if (isEmpty(shiprocketOrder.customer_name)) return alert("Shipping name is required.");
            if (isEmpty(shiprocketOrder.customer_address)) return alert("Shipping address is required.");
            if (isEmpty(shiprocketOrder.customer_city)) return alert("Shipping city is required.");
            if (isEmpty(shiprocketOrder.customer_pincode)) return alert("Shipping pincode is required.");
            if (isEmpty(shiprocketOrder.customer_state)) return alert("Shipping state is required.");
            if (isEmpty(shiprocketOrder.customer_country)) return alert("Shipping country is required.");
            if (isEmpty(shiprocketOrder.customer_email) || !isValidEmail(shiprocketOrder.customer_email)) return alert("A valid shipping email is required.");
            if (isEmpty(shiprocketOrder.customer_phone) || !isValidPhone(shiprocketOrder.customer_phone)) return alert("A valid 10-digit shipping phone number is required.");
        }
        // Validate courier details
        if (isEmpty(courierDetail.payment_method)) return alert("Payment method is required.");
        if (courierDetail.sub_total <= 0) return alert("Sub total must be greater than 0.");
        if (courierDetail.weight <= 0) return alert("Weight must be greater than 0.");
        if(!validateSpecifications(specifications)){
            return alert("Product Item Field is Required")
        }
        try {
          const response = await axios.post(
            `${apiurl}/shiprocket/orders/create/`,
            {
                order_id: oderInfo.order_id ,
                order_date: oderInfo.order_date,
                pickup_location: oderInfo.pickup_code,
                channel_id: '',
                comment: '',
                billing_customer_name: billing.billing_name ,
                billing_last_name: "",
                billing_address: billing.billing_address,
                billing_address_2: "",
                billing_city: billing.billing_city,
                billing_pincode: billing.billing_pincode,
                billing_state: billing.billing_state,
                billing_country: billing.billing_country,
                billing_email: billing.billing_email,
                billing_phone: billing.billing_phone ,
                shipping_is_billing: shiprocketOrder.shipping_is_billing,
                shipping_customer_name: shiprocketOrder.shipping_is_billing ? "" :shiprocketOrder.customer_name,
                shipping_last_name: "",
                shipping_address: shiprocketOrder.shipping_is_billing ? "" :shiprocketOrder.customer_address,
                shipping_address_2: '',
                shipping_city: shiprocketOrder.shipping_is_billing ? "" :shiprocketOrder.customer_city,
                shipping_pincode: shiprocketOrder.shipping_is_billing ? "" :shiprocketOrder.customer_pincode,
                shipping_country: shiprocketOrder.shipping_is_billing ? "" :shiprocketOrder.customer_country,
                shipping_state: shiprocketOrder.shipping_is_billing ? "" :shiprocketOrder.customer_state,
                shipping_email: shiprocketOrder.shipping_is_billing ? "" :shiprocketOrder.customer_email,
                shipping_phone: shiprocketOrder.shipping_is_billing ? "" :shiprocketOrder.customer_phone,
                order_items:specifications,
                payment_method: courierDetail.payment_method,
                shipping_charges: courierDetail.shipping_charges,
                giftwrap_charges: 0,
                transaction_charges: 0,
                total_discount: courierDetail.total_discount,
                sub_total: Number(courierDetail.sub_total-courierDetail.shipping_charges)+ Number(courierDetail.total_discount),
                length: courierDetail.length,
                breadth: courierDetail.breadth,
                height: courierDetail.height,
                weight: courierDetail.weight,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
              },
            }
        );
          console.log("Order created:", response.data);
          sendCreateOrderData(response.data)
        } catch (error) {
          if (error.response) {
            console.error("Error Response:", error.response.data);
          } else {
            console.error("Error Message:", error.message);
          }
        }
      };
      
      
    return (
        <div>
            <div className="container-fluid pt-5 bg-secondary" id="main">
                <div className="row row-offcanvas row-offcanvas-left h-100">
                    <Sidebar />
                    {/*/col*/}
                    <div className="col-md-9 col-lg-10 main mt-4">
                        <div className="container">
                                <div className="tab-content" id="pills-tabContent">
                                <div>
                                        <ul
                                            className="nav nav-pills mb-3 d-flex justify-content-center mt-5"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="pills-home-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-home"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-home"
                                                    aria-selected="true"
                                                >
                                                    Shipping Detail
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-profile-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-profile"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-profile"
                                                    aria-selected="false"
                                                >
                                                    Billing Detail
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-contact-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-contact"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-contact"
                                                    aria-selected="false"
                                                >
                                                    Item Detail
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-size-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-size"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-size"
                                                    aria-selected="false"
                                                >
                                                    Courier Detail
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-color-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-color"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-color"
                                                    aria-selected="false"
                                                >
                                                    Pickup Details
                                                </button>
                                            </li>
                                                
                                        </ul>
                                        <button className='btn btn-dark w-50' type="button" style={{marginLeft:"25%"}} onClick={createAdhocOrder}>Create Order</button>
                                    </div>
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-home"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">

                                                    <div className="card-body">
                                                        <div className="row text-dark">
                                                            <div className="mb-3 ms-3 form-check">
                                                                <input checked={shiprocketOrder.shipping_is_billing} onChange={handleProductInputChange} name='shipping_is_billing' type="checkbox" className="form-check-input" id="publicaly" />
                                                                <label className="form-check-label" htmlFor="publicaly">
                                                                Shipping is Billing
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Full Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_name"
                                                                    id=""
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder.customer_name}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Phone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_phone"
                                                                    id=""
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder.customer_phone}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                   Email
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_email"
                                                                    id=""
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder.customer_email}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Address
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id=""
                                                                    cols={30}
                                                                    rows={2}
                                                                    defaultValue={""}
                                                                    name="customer_address"
                                                                    value={shiprocketOrder.customer_address|| ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Pincode
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_pincode"
                                                                    id=""
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder.customer_pincode}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_city"
                                                                    id=""
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder.customer_city}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    State
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_state"
                                                                    id=""
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder.customer_state}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Country
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_country"
                                                                    id=""
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder.customer_country}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <div className="row text-dark">
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Full Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_name"
                                                                    id=""
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing.billing_name}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Phone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_phone"
                                                                    id=""
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing.billing_phone}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                   Email
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_email"
                                                                    id=""
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing.billing_email}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Address
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id=""
                                                                    cols={30}
                                                                    rows={2}
                                                                    defaultValue={""}
                                                                    name="billing_address"
                                                                    value={billing.billing_address|| ''}
                                                                    onChange={handleBillingInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Pincode
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_pincode"
                                                                    id=""
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing.billing_pincode}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_city"
                                                                    id=""
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing.billing_city}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    State
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_state"
                                                                    id=""
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing.billing_state}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Country
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_country"
                                                                    id=""
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing.billing_country}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-contact"
                                        role="tabpanel"
                                        aria-labelledby="pills-contact-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                        <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        {specifications?.map((specification, index) => (
                                                            <>
                                                            <div className="row text-dark">
                                                                <div className="col-lg-3 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        SKU
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.sku || ''}
                                                                        onChange={(e) => handleInputChange(index, 'sku', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Taxe in %
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.tax || ''}
                                                                        onChange={(e) => handleInputChange(index, 'tax', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Units
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.units || ''}
                                                                        onChange={(e) => handleInputChange(index, 'units', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Hsn
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.hsn || ''}
                                                                        onChange={(e) => handleInputChange(index, 'hsn', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Selling Price
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.selling_price || ''}
                                                                        onChange={(e) => handleInputChange(index, 'selling_price', e.target.value, setSpecifications)}
                                                                    />
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="row text-dark">
                                                                <div className="col-lg-10 mb-2">
                                                                        <label htmlFor="" className="mb-2">
                                                                            Name
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={specification.name || ''}
                                                                            onChange={(e) => handleInputChange(index, 'name', e.target.value, setSpecifications)}

                                                                        />
                                                                </div>
                                                                <div className="col-lg-1 mb-2">
                                                                    <button type='button' onClick={() => handleRemove(index, setSpecifications)} className='btn btn-danger mt-4 '>Remove</button>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            </>
                                                        ))}

                                                        {specifications?.length < 1 &&
                                                            <h4>No Item</h4>
                                                        }
                                                        <button type='button' onClick={() => handleAddMore(setSpecifications)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Item
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="tab-pane fade"
                                        id="pills-size"
                                        role="tabpanel"
                                        aria-labelledby="pills-size-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <div className="row text-dark">
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Payment Methode
                                                                </label>
                                                                <select name="payment_method" id="" className="form-control" onChange={handleCourierInputChange} value={courierDetail.payment_method}>
                                                                    <option value="">--SELECT--</option>
                                                                    <option value="prepaid">Prepaid</option>
                                                                    <option value="COD">COD</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Shipping Charge
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="shipping_charges"
                                                                    id=""
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.shipping_charges}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                Total Discount
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="total_discount"
                                                                    id=""
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.total_discount}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Total
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="sub_total"
                                                                    id=""
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.sub_total}
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Length
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="length"
                                                                    id=""
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.length}
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Breadth
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="breadth"
                                                                    id=""
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.breadth}
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                height
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="height"
                                                                    id=""
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.height}
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Weight
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="weight"
                                                                    id=""
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.weight}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-color"
                                        role="tabpanel"
                                        aria-labelledby="pills-color-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <div className="row text-dark">
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                Pickup Location
                                                                </label>
                                                                <select name="pickup_code" id="" value={oderInfo.pickup_code} className='form-control' onChange={handleOrderinfoInputChange}>
                                                                    <option value="">--SELECT--</option>
                                                                    <option value="Home">Home</option>
                                                                    <option value="Primary">Primary</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Order ID
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="order_id"
                                                                    id=""
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.order_id}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                Order Date
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    name="order_date"
                                                                    id=""
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.order_date}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddShiprocketOrder