import React, { useState, useEffect,useRef } from 'react'
import Swal from 'sweetalert2'
import Sidebar from './Sidebar';
import { useNavigate,useNavigation, useParams } from 'react-router-dom';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from "jwt-decode";

function UpdateShiprocketOrder() {
    const params=useParams()
    const [shiprocketOrder, setShiprocketOrder] = useState([])
    const [billing, setbilling] = useState([])
    const [specifications, setSpecifications] = useState([{
        discount: "",
        hsn: 0,
        name:"",
        selling_price: "",
        sku: "",
        tax: "",
        units: 0
    }]);

    // console.log(shiprocketOrder)
    const [courierDetail, setCourierDetail] = useState({
        "payment_method":"",
        "shipping_charges":0,
        "total_discount":0,
        "sub_total":0,
        "length":0,
        "breadth":0,
        "height":0,
        "weight":0,
    });
    const [oderInfo,setOrderInfo] = useState({})
    const [user, setUser] = useState({});

    
    const navigate = useNavigate()
    useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
          try {
              const decoded = jwtDecode(token); 
              setUser(decoded)
              if (decoded?.vendor_id === 0) {
                  navigate('/'); 
              }
          } catch (error) {
              navigate('/login');
          }
    } else {
          navigate('/login');
    }
  }, [navigate]);


    const getShiprocketOrders = async () => {
        try {
            const response = await axios.get(`${apiurl}/shiprocket/SpecificOrders/${params.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
                    },
            });
            if(response.data){
                setShiprocketOrder(response.data.data);
                setbilling(response.data.data.others)
                setSpecifications(response.data.data.products ? response.data.data.products :[])
                setCourierDetail(
                {
                    "payment_method":response.data.data.payment_method,
                    "shipping_charges":response.data.data.others.shipping_charges,
                    "total_discount":response.data.data.discount,
                    "sub_total":response.data.data.total,
                    "length":30.5,
                    "breadth":25.5,
                    "height":5,
                    "weight":response.data.data.shipments.volumetric_weight,
                })
                setOrderInfo(response.data.data.pickup_address)
            }
        } catch (error) {
        console.log(error)
        }
    };
    useEffect(() => {
        if(user.vendor_id){
            getShiprocketOrders();
        }
    }, [user]);
    
    const handleAddMore = (setStateFunction) => {
        setStateFunction((prevState) => [...prevState, {}]);
    };
    const handleRemove = (index, setStateFunction) => {
        setStateFunction((prevState) => {
            const newState = [...prevState];
            newState.splice(index, 1);
            return newState;
        });
    };

    const handleInputChange = (index, field, value, setStateFunction) => {
        setStateFunction((prevState) => {
            const newState = [...prevState];
            newState[index][field] = value;
            return newState;
        });
    };

    const handleOrderinfoInputChange = (event) => {
        setOrderInfo({
            ...oderInfo,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };
    const handleCourierInputChange = (event) => {
        setCourierDetail({
            ...courierDetail,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };
    const handleBillingInputChange = (event) => {
        setbilling({
            ...billing,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };

    const handleProductInputChange = (event) => {
        setShiprocketOrder({
            ...shiprocketOrder,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };
        const validateShippingDetails = (orderId) => {
            console.log(shiprocketOrder.customer_phone)
            const errors = [];
            if (!orderId) {
            errors.push("Order ID is required.");
            }
        
            if (!shiprocketOrder.customer_name || shiprocketOrder.customer_name.trim() === "") {
            errors.push("Customer name is required.");
            }
        
            if (!shiprocketOrder.customer_phone || !/^[6-9]\d{9}$/.test(shiprocketOrder.customer_phone)) {
            errors.push("Valid customer phone is required (10 digits).");
            }
        
            if (!shiprocketOrder.customer_address || shiprocketOrder.customer_address.trim() === "") {
            errors.push("Shipping address is required.");
            }
        
            if (shiprocketOrder.customer_address_2 && shiprocketOrder.customer_address_2.trim() === "") {
            errors.push("Shipping address line 2 should not be empty if provided.");
            }
        
            if (!shiprocketOrder.customer_city || shiprocketOrder.customer_city.trim() === "") {
            errors.push("Shipping city is required.");
            }
        
            if (!shiprocketOrder.customer_state || shiprocketOrder.customer_state.trim() === "") {
            errors.push("Shipping state is required.");
            }
        
            if (!shiprocketOrder.customer_country || shiprocketOrder.customer_country.trim() === "") {
            errors.push("Shipping country is required.");
            }
        
            if (!shiprocketOrder.customer_pincode || !/^\d{6}$/.test(shiprocketOrder.customer_pincode)) {
            errors.push("Valid 6-digit shipping pincode is required.");
            }
            return errors;
        };
      


    const updateOrderAddress = async (orderId) => {
        const validationErrors = validateShippingDetails(orderId);
        if (validationErrors.length > 0) {
            alert("Validation failed with the following errors:\n" + validationErrors.join("\n"));
            return
        }
        try {
          const response = await axios.post(
            `${apiurl}/shiprocket/customer-address/update/`,
            {
              order_id: orderId, // Dynamic order ID
              shipping_customer_name: shiprocketOrder.customer_name,
              shipping_phone: shiprocketOrder.customer_phone,
              shipping_address: shiprocketOrder.customer_address,
              shipping_address_2: shiprocketOrder.customer_address_2,
              shipping_city: shiprocketOrder.customer_city,
              shipping_state: shiprocketOrder.customer_state,
              shipping_country: shiprocketOrder.customer_country,
              shipping_pincode: shiprocketOrder.customer_pincode,
              shipping_email:shiprocketOrder.customer_email
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
              },
            }
          );
          alert("Update Sucessfully")
        //   console.log("Address updated:", response.data);
          getShiprocketOrders()
        } catch (error) {
          if (error.response) {
            console.error("Error Response:", error.response.data);
          } else {
            console.error("Error Message:", error.message);
          }
        }
    };

    const updatePickupLocation = async (orderId, newPickupLocation) => {
        console.log("fsdgdf",orderId,newPickupLocation)
        try {
          const response = await axios.patch(
            `${apiurl}/shiprocket/pickup-location/update/`,
            {
              order_id: [orderId], // Dynamic order ID array
              pickup_location: newPickupLocation, // Dynamic pickup location
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
              },
            }
          );
          alert("Update Sucessfully")
          getShiprocketOrders()
          console.log("Pickup location updated:", response.data);
        } catch (error) {
          if (error.response) {
            console.error("Error Response:", error.response.data);
          } else {
            console.error("Error Message:", error.message);
          }
        }
    };
    return (
        <div>
            <div className="container-fluid pt-5 bg-secondary" id="main">
                <div className="row row-offcanvas row-offcanvas-left h-100">
                    <Sidebar />
                    {/*/col*/}
                    <div className="col-md-9 col-lg-10 main mt-4">
                        <div className="container">
                                <div className="tab-content" id="pills-tabContent">
                                <div>
                                        <ul
                                            className="nav nav-pills mb-3 d-flex justify-content-center mt-5"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="pills-home-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-home"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-home"
                                                    aria-selected="true"
                                                >
                                                    Shipping Detail
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-profile-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-profile"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-profile"
                                                    aria-selected="false"
                                                >
                                                    Billing Detail
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-contact-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-contact"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-contact"
                                                    aria-selected="false"
                                                >
                                                    Item detail
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-size-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-size"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-size"
                                                    aria-selected="false"
                                                >
                                                    Courier Detail
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-color-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-color"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-color"
                                                    aria-selected="false"
                                                >
                                                    Pickup Details
                                                </button>
                                            </li>
                                                
                                        </ul>
                                    </div>
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-home"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">

                                                    <div className="card-body">
                                                        <div className="row text-dark">
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Full Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_name"
                                                                    id=""
                                                                    disabled={shiprocketOrder?.status === "CANCELED"}
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder?.customer_name}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Phone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_phone"
                                                                    id=""
                                                                    disabled={shiprocketOrder?.status === "CANCELED"}
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder?.customer_phone}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                   Email
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_email"
                                                                    id=""
                                                                    disabled={shiprocketOrder?.status === "CANCELED"}
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder?.customer_email}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Address
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id=""
                                                                    disabled={shiprocketOrder?.status === "CANCELED"}
                                                                    cols={30}
                                                                    rows={2}
                                                                    defaultValue={""}
                                                                    name="customer_address"
                                                                    value={shiprocketOrder?.customer_address|| ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Pincode
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_pincode"
                                                                    id=""
                                                                    disabled={shiprocketOrder?.status === "CANCELED"}
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder?.customer_pincode}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_city"
                                                                    id=""
                                                                    disabled={shiprocketOrder?.status === "CANCELED"}
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder?.customer_city}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    State
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_state"
                                                                    id=""
                                                                    disabled={shiprocketOrder?.status === "CANCELED"}
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder?.customer_state}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Country
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="customer_country"
                                                                    id=""
                                                                    disabled={shiprocketOrder?.status === "CANCELED"}
                                                                    onChange={handleProductInputChange}
                                                                    value={shiprocketOrder?.customer_country}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2" style={{ display: shiprocketOrder?.status === "CANCELED" ? "none" : "block" }}>
                                                                <button type='button' className='btn btn-dark w-100' onClick={()=>updateOrderAddress(params.id)}>Update Shipping Detail</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <div className="row text-dark">
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Full Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_name"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing?.billing_name}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Phone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_phone"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing?.billing_phone}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                   Email
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_email"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing?.billing_email}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Address
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id=""
                                                                    disabled
                                                                    cols={30}
                                                                    rows={2}
                                                                    defaultValue={""}
                                                                    name="billing_address"
                                                                    value={billing?.billing_address|| ''}
                                                                    onChange={handleBillingInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Pincode
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_pincode"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing?.billing_pincode}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_city"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing?.billing_city}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    State
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_state"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing?.billing_state}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Country
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="billing_country"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleBillingInputChange}
                                                                    value={billing?.billing_country}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-contact"
                                        role="tabpanel"
                                        aria-labelledby="pills-contact-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                        <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        {specifications?.map((specification, index) => (
                                                            <>
                                                            
                                                            <div className="row text-dark">
                                                                <div className="col-lg-3 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        SKU
                                                                    </label>
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.channel_sku || ''}
                                                                        onChange={(e) => handleInputChange(index, 'channel_sku', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Taxe in %
                                                                    </label>
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.tax_percentage || ''}
                                                                        onChange={(e) => handleInputChange(index, 'tax_percentage', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Units
                                                                    </label>
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.quantity || ''}
                                                                        onChange={(e) => handleInputChange(index, 'quantity', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Hsn
                                                                    </label>
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.hsn || ''}
                                                                        onChange={(e) => handleInputChange(index, 'hsn', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Selling Price
                                                                    </label>
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.selling_price || ''}
                                                                        onChange={(e) => handleInputChange(index, 'selling_price', e.target.value, setSpecifications)}
                                                                    />
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="row text-dark">
                                                                <div className="col-lg-11 mb-2">
                                                                        <label htmlFor="" className="mb-2">
                                                                            Name
                                                                        </label>
                                                                        <input
                                                                            disabled
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={specification.name || ''}
                                                                            onChange={(e) => handleInputChange(index, 'name', e.target.value, setSpecifications)}

                                                                        />
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            </>
                                                        ))}

                                                        {specifications?.length < 1 &&
                                                            <h4>No Item</h4>
                                                        }
                                                        {/* <button type='button' onClick={() => handleAddMore(setSpecifications)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Item
                                                        </button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="tab-pane fade"
                                        id="pills-size"
                                        role="tabpanel"
                                        aria-labelledby="pills-size-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <div className="row text-dark">
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Payment Methode
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="payment_method"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.payment_method}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Shipping Charge
                                                                </label>
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="shipping_charges"
                                                                    id=""
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.shipping_charges}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                Total Discount
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="total_discount"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.total_discount}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Total
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="sub_total"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.sub_total}
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Length
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="length"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.length}
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Breadth
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="breadth"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.breadth}
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                height
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="height"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.height}
                                                                />
                                                            </div>
                                                            <div className="col-lg-3 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Weight
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="weight"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleCourierInputChange}
                                                                    value={courierDetail.weight}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-color"
                                        role="tabpanel"
                                        aria-labelledby="pills-color-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4"><i class="fab fa-first-order"></i>&nbsp;{params.id}</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <div className="row text-dark">
                                                        <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2" style={{fontWeight:"bolder"}}>
                                                                Pickup Location
                                                                </label>
                                                                <select name="pickup_code" disabled={shiprocketOrder?.status === "CANCELED"} id="" value={oderInfo.pickup_code} className='form-control' onChange={handleOrderinfoInputChange}>
                                                                    <option value="Home">Home</option>
                                                                    <option value="Primary">Primary</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="name"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.name }
                                                                />
                                                            </div>
                                                            {/* <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                   Company Id
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="company_id"
                                                                    id=""
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.company_id }
                                                                />
                                                            </div> */}
                                                            
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Pin Code
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="pin_code"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.pin_code}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                Email
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="email"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.email}
                                                                />
                                                            </div>
                                                            
                                                            
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Address
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id=""
                                                                    disabled
                                                                    cols={30}
                                                                    rows={2}
                                                                    defaultValue={""}
                                                                    name="address"
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.address}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Secondary Address
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id=""
                                                                    disabled
                                                                    cols={30}
                                                                    rows={2}
                                                                    defaultValue={""}
                                                                    name="address_2"
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.address_2}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Phone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="phone"
                                                                    disabled
                                                                    id=""
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.phone}
                                                                />
                                                            </div>
                                                            
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="city"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.city}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    State
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="state"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.state}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Country
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="country"
                                                                    id=""
                                                                    disabled
                                                                    onChange={handleOrderinfoInputChange}
                                                                    value={oderInfo.country}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2" style={{ display: shiprocketOrder?.status === "CANCELED" ? "none" : "block" }}>
                                                                <button type='button' className='btn btn-dark w-100 mt-3' onClick={()=>updatePickupLocation(params.id, oderInfo.pickup_code)}>Update pickup Address</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UpdateShiprocketOrder