import React from 'react'
import Sidebar from "../useradmin/Sidebar"
import { Link, useNavigate } from 'react-router-dom';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { apiurl } from '../../urls/url';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from 'jwt-decode';


  function Account() {
    const [user, setUser] = useState({});
    const [userprofile, setuserprofile] = useState();
    const navigate = useNavigate();
    const userprofileinfo = () => {
      axios.get(`${apiurl}/customer/setting/${user.user_id}`,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
      }
      })
        .then((res) => {
              console.log(res.data)
              setuserprofile(res.data)
        })
        .catch(
            (error) => {
              if(error.response){
                if(error.response.status===401){
                  localStorage.removeItem("token");
                  navigate('/login');
                }
              }
            }
          );
    }
    useEffect(()=>{
      if(user.user_id){
        userprofileinfo()
      }
    },[user])
    useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
          try {
              const decoded = jwtDecode(token); 
              // console.log(decoded)
              setUser(decoded)
              if (decoded?.vendor_id > 0) {
                  navigate('/'); 
              }
          } catch (error) {
              navigate('/login');
          }
      } else {
          navigate('/login');
      }
  },[navigate]);
  return (
    <div>
      <main className="mt-5" style={{ marginBottom: "17px" }}>
        <div className="container">
          <section className="">
            <div className="row">
              <Sidebar />
              <div className="col-lg-9 mt-5">
                <main className="mb-5" style={{}}>
                  <div className="container px-4">
                    <section className=""></section>
                    <section className="">
                      <div className="row rounded shadow p-3">
                        <h2>Hi {userprofile?.full_name}, </h2>
                        <div className="col-lg-12 mb-4 mb-lg-0 h-100">
                          From your account dashboard. you can easily check &amp; view
                          your <Link to="/user-profile/orders/">orders</Link>, manage your{" "}
                          <a href="">
                            <shipping address="" a="">
                              ,{" "}
                            </shipping>
                          </a>
                          <Link to="/forgotpassword/">change password</Link> and{" "}
                          <Link to="/user-profile/settings/">edit account</Link> infomations.
                        </div>
                      </div>
                    </section>
                    {/* Section: MSC */}
                  </div>
                  {/* Container for demo purpose */}
                </main>
              </div>
            </div>
          </section>
          {/*Section: Wishlist*/}
        </div>
      </main>
    </div>
  )
}

export default Account