import React from 'react';
import './registration.css'
import Registerform from '../../../comcomponents/registerform';

export default function Registration() {
  return (
    <div className='registration-main'>
        <Registerform></Registerform>
    </div>
  )
}
