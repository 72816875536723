import React from 'react'
import "./refersAndEarn.css"

export default function RefersAndEarn() {
  return (
    <>
    <div className="refer-earn">
        <div className="refers-heading">
            <h2>Refer and Earn</h2>
            <h5>Refer anyone and earn Referral Points in your wallet</h5>
        </div>
        <div className="refers-image">
            <img src="/home/Banner.webp" alt="" />
        </div>
        <div style={{ boxShadow: "0px -10px 40px 45px #f6f7f9",width:"100%",backgroundColor:"#f6f7f9",padding:"20px"}}></div>
        <div className="refer-first-content">
            <h2>How Will it work?</h2>
            <h5>1. Generate Your Unique Coupon Code:</h5>
            <ul >
                <li>Share your unique coupon code with your friends and family</li>
                <li>Look for a section labeled "Generate Referral Code" or similar.</li>
                <li>Click on the button or link to generate your personalized coupon code.</li>
            </ul>
            <h5>2. Encourage Friends to Use the Coupon:</h5>
            <ul>
                <li>Explain the benefits of using the coupon code, such as discounts, free products, or other rewards.</li>
                <li>Provide any necessary details about how to use the coupon, such as the expiration date or minimum purchase requirements.</li>
            </ul>
        </div>
        <div className="refer-first-content">
            <h2>How You’ll Earn?</h2>
            <h5>1. Track Referrals:</h5>
            <ul >
                <li>The referral program platform will likely provide a dashboard or tracking
                system where you can monitor the number of referrals you've generated.</li>
                <li>Some platforms may also show you the status of referrals, such as whether
                they've made a purchase or signed up for a service.</li>
            </ul>
            <h5>2. Track Referrals:</h5>
            <ul>
                <li>Per Referral: You earn a fixed amount of points for each new referral who
                completes a specific action (e.g., making a purchase or signing up).</li>
                <li>Percentage of Sales: You receive a percentage of the total sales made by your
                referrals.</li>
            </ul>
        </div>
        <div className="refer-first-content" style={{marginBottom:"5rem"}}>
            <h2>Breakdown</h2>
            <ul style={{ listStyleType: "disc", }}>
                <li>On Every Purchase from your Referral Code you’ll get a total of <span>100 points</span> in your wallet
                to redeem.</li>
                <li>So, <span>100 Points = ₹50</span> that means you’ll get ₹25 on each purchase from your Referral
                Code.</li>
                <li>You can redeem the Points at a threshold limit of 800 Points.</li>
            </ul>
        </div>
    </div>
    </>
  )
}
