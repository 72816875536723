import React, { useState } from 'react';
import './subscribe.css';
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';
import { apiurl } from '../../urls/url';

export default function Subscribe() {
    const [email,setemail]=useState("")
    const hnadleSubmit=(e)=>{
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
        if (!email.trim()) {
            toast.error("Email is required");
            return
        } else if (!emailRegex.test(email)) {
            toast.error("Invalid email format");
            return
        }
        try{
            axios.post(`${apiurl}/api/signup/`,{"email":email})
            .then((res)=>{
                toast.success("You have been subscribed successfully")
                setemail("")
            })
            .catch((error)=>{
                if (error.response.status==400 && error.response.data.error=="Email is already subscribed."){
                    toast.info("Email is already subscribed.")
                }
                console.log(error)
            })
        }
        catch(error){
            console.log(error)
        }
    }
    return (
        <>
            <div className='subscribeus'>
                <ToastContainer/>
                <h1>SUBSCRIBE FOR DAILY DISCOUNTS AND OFFERS</h1>
                <p>Subscribe with your email for Daily updates about our Discounts,<br /> Sales, and Offers. Get a wide variety of coupon codes on your Mail </p>
                <input type='email' placeholder='Your Email' onChange={(e)=>setemail(e.target.value)} value={email}></input>
                <input type="button" value='Subscribe' onClick={hnadleSubmit} />
            </div>
        </>
    )
}
