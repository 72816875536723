import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { apiurl } from "../urls/url";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Sidebar from "./Sidebar";

function ViewProduct() {
  const { param } = useParams();

  const [product, setProduct] = useState([]);
  const [specifications, setSpecifications] = useState([{ title: "", content: "" },]);
  const [colors, setColors] = useState([
    { name: "", color_code: "", image: null },
  ]);
  const [user, setUser] = useState({});
  const [sizes, setSizes] = useState([{ name: "", price: 0.0 }]);
  const [gallery, setGallery] = useState([{ image: null }]);
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [outdoor, setoutdoor] = useState([{ image: null }]);
  const [mobile, setmobile] = useState([{ image: null }]);
  const [outdoorColor, setOutdoorColor] = useState([{ color_name: "", color_code: ""},]);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
        try {
            const decoded = jwtDecode(token); 
            console.log(decoded)
            setUser(decoded)
            if (decoded?.vendor_id === 0) {
                navigate('/'); 
            }
        } catch (error) {
            navigate('/login');
        }
    } else {
        navigate('/login');
    }
}, [navigate]);

  useEffect(() => {
    // Fetch product details from the backend
    const fetchProductDetails = () => {
      setProduct({
        ...product,
        image: product.image,
      });
    };

    fetchProductDetails();
  }, [user]);

  useEffect(() => {
    const fetchCategory = async () => {
      try{
        axios.get(`${apiurl}/category/`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
        }
        }).then((res) => {
          // console.log(res.data)
          setCategory(res.data);
        }).catch(
          (error) => {
            if(error.response){
              if(error.response.status===401){
                localStorage.removeItem("token");
                navigate('/login');
              }
            }
          }
        );
      }catch(e){
      }
    };
    if(user.vendor_id){
      fetchCategory();
    }
  }, [user]);

  useEffect(() => {
    const fetchProductItems = async () => {
      try{
        axios.get(`${apiurl}/vendor-product-edit/${user.vendor_id}/${param}/`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
        }
        }).then((res) => {
          setProduct(res.data);
          setColors(res.data.color);
          setSizes(res.data.size);
          setSpecifications(res.data.specification);
          setGallery(res.data.gallery);
          setoutdoor(res.data.OutdoorImage);
          setmobile(res.data.mobile_images);
          setOutdoorColor(res.data.OutdoorColor);
        })
        .catch(
          (error) => {
            if(error.response){
              if(error.response.status===401){
                localStorage.removeItem("token");
                navigate('/login');
              }
            }
          }
        );
      }
      catch(error){
      }
    };
    if(user.vendor_id){
      fetchProductItems();
    }
  }, [user]);


  return (
    <div>
      <div className="container-fluid pt-5 bg-secondary" id="main">
        <div className="row row-offcanvas row-offcanvas-left h-100">
          <Sidebar />
          {/*/col*/}
          <div className="col-md-9 col-lg-10 main mt-4">
            <div className="container">
              <form
                className="main-body"
                method="POST"
                encType="multipart/form-data"
              >
                <div>
                  <ul
                    className="nav nav-pills mb-3 d-flex justify-content-center mt-5 p-2"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Basic Information
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Product Image
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        Specifications
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-size-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-size"
                        type="button"
                        role="tab"
                        aria-controls="pills-size"
                        aria-selected="false"
                      >
                        Size
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-color-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-color"
                        type="button"
                        role="tab"
                        aria-controls="pills-color"
                        aria-selected="false"
                      >
                        Color
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-outdoor-image-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-outdoor-image"
                        type="button"
                        role="tab"
                        aria-controls="pills-outdoor-image"
                        aria-selected="false"
                      >
                        Outdoor Image
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-mobile-image-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-mobile-image"
                        type="button"
                        role="tab"
                        aria-controls="pills-mobile-image"
                        aria-selected="false"
                      >
                        Mobile Image
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-outdoor-color-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-outdoor-color"
                        type="button"
                        role="tab"
                        aria-controls="pills-color"
                        aria-selected="false"
                      >
                        Outdoor Color
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row gutters-sm shadow p-4 rounded">
                      <h4 className="mb-4">Product Details</h4>
                      <div className="col-md-4 mb-3">
                        <div className="card h-100">
                          <div className="card-body">
                            <div className="d-flex flex-column align-items-center text-center">
                              {product.image && product.image.preview ? (
                                <img
                                  src={product.image.preview}
                                  alt="Product Thumbnail Preview"
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    objectFit: "contain",
                                    borderRadius: 10,
                                  }}
                                />
                              ) : (
                                <img
                                  src={product.image}
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    objectFit: "contain",
                                    borderRadius: 10,
                                  }}
                                  alt=""
                                />
                              )}

                              <div className="mt-3">
                                {product.title !== "" && (
                                  <h4 className="text-dark">{product.title}</h4>
                                )}
                                {product.title === "" && (
                                  <h4 className="text-dark">Product Title</h4>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="card mb-3">
                          <div className="card-body">
                            <div className="row text-dark">
                              <div className="col-lg-12 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  name="title"
                                  value={product.title || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-12 mb-2">
                                <label htmlFor="" className="mb-2">
                                  Description
                                </label>
                                <textarea
                                  className="form-control"
                                  id=""
                                  cols={30}
                                  rows={4}
                                  defaultValue={""}
                                  name="description"
                                  value={product.description || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label htmlFor="" className="mb-2">
                                  Category
                                </label>
                                <select
                                  className="select form-control"
                                  id=""
                                  name="category"
                                  value={product.category?.id || ""}
                                  disabled
                                >
                                  <option value="">- Select -</option>
                                  {category?.map((c, index) => (
                                    <option key={index} value={c.id}>
                                      {c.title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Brand
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="brand"
                                  value={product.brand || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Sale Price
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="price"
                                  value={product.price || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Regular Price
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="old_price"
                                  value={product.old_price || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Shipping Amount
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="shipping_amount"
                                  value={product.shipping_amount || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Stock Qty
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="stock_qty"
                                  value={product.stock_qty || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Tags
                                </label>
                                <br />
                                <input
                                  type="text"
                                  className="form-control"
                                  name="tags"
                                  value={product.tags || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Type
                                </label>
                                <select
                                  name="type"
                                  id=""
                                  className="form-control"
                                  value={product.type || ""}
                                  disabled
                                >
                                  <option value="">-Select-</option>
                                  <option value="regular">Regular</option>
                                  <option value="auction">Auction</option>
                                  <option value="offer">Offer</option>
                                </select>
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Product Color
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="product_color"
                                  value={product.product_color || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Product Size
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="product_size"
                                  value={product.product_size || ""}
                                  disabled
                                />
                              </div>
                              <div className="col-lg-6 mb-2 ">
                                <label htmlFor="" className="mb-2">
                                  Status
                                </label>
                                <br />
                                <select
                                  name="status"
                                  id=""
                                  className="form-control"
                                  value={product.status || ""}
                                  disabled
                                >
                                  <option value="">-Select-</option>
                                  <option value="draft">Draft</option>
                                  <option value="disabled">Disabled</option>
                                  <option value="in_review">In Review</option>
                                  <option value="published">Published</option>
                                </select>
                              </div>
                              <div className="col-lg-6 mb-2 mt-4 pt-2">
                                <label htmlFor="" className="mb-2">
                                  In Stock
                                </label>{" "}
                                &nbsp;
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="in_stock"
                                  checked={product.in_stock} // Using `checked` for checkbox // You need to handle the change event
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row gutters-sm shadow p-4 rounded">
                      <h4 className="mb-4">Product Image</h4>
                      <div className="col-md-12">
                        <div className="card mb-3">
                          <div className="card-body">
                            {gallery?.map((item, index) => (
                              <div className="row text-dark mb-5">
                                <div className="col-lg-3">
                                  {item.image &&
                                    (item.image.preview ? (
                                      <img
                                        src={item.image.preview}
                                        alt="Product Thumbnail Preview"
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "cover",
                                          borderRadius: 10,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={item.image}
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "contain",
                                          borderRadius: 10,
                                        }}
                                        alt=""
                                      />
                                    ))}
                                  {!item.image && (
                                    <img
                                      src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                                      alt={`Preview for gallery item ${
                                        index + 1
                                      }`}
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: 5,
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-lg-6 mb-2 pt-4">
                                  <label htmlFor="" className="mb-2 text-dark" style={{fontWeight:"bolder",fontSize:"18px"}}>
                                    Image Url:
                                  </label>
                                  <a href={item.image} target="_blank" className="d-block text-decoration-none text-dark" style={{fontWeight:"bolder", fontSize:"15px"}}>{item.image}</a>
                                </div>
                              </div>
                            ))}
                            {gallery < 1 && <h4>No Images Selected</h4>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="row gutters-sm shadow p-4 rounded">
                      <h4 className="mb-4">Specifications</h4>
                      <div className="col-md-12">
                        <div className="card mb-3">
                          <div className="card-body">
                            {specifications?.map((specification, index) => (
                              <div className="row text-dark">
                                <div className="col-lg-3 mb-2">
                                  <label htmlFor="" className="mb-2">
                                    Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={specification.title || ""}
                                    disabled
                                  />
                                </div>
                                <div className="col-lg-6 mb-2">
                                  <label htmlFor="" className="mb-2">
                                    Content
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={specification.content || ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            ))}

                            {specifications?.length < 1 && (
                              <h4>No Specification Form</h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-size"
                    role="tabpanel"
                    aria-labelledby="pills-size-tab"
                  >
                    <div className="row gutters-sm shadow p-4 rounded">
                      <h4 className="mb-4">Sizes</h4>
                      <div className="col-md-12">
                        <div className="card mb-3">
                          <div className="card-body">
                            {sizes?.map((s, index) => (
                              <div className="row text-dark">
                                <div className="col-lg-3 mb-2">
                                  <label htmlFor="" className="mb-2">
                                    Size
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name=""
                                    placeholder="XXL"
                                    id=""
                                    value={s.name || ""}
                                    disabled
                                  />
                                </div>
                                <div className="col-lg-6 mb-2">
                                  <label htmlFor="" className="mb-2">
                                    Price
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="$20"
                                    className="form-control"
                                    name=""
                                    id=""
                                    value={s.price || ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            ))}
                            {sizes < 1 && <h4>No Size Added</h4>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-color"
                    role="tabpanel"
                    aria-labelledby="pills-color-tab"
                  >
                    <div className="row gutters-sm shadow p-4 rounded">
                      <h4 className="mb-4">Color</h4>
                      <div className="col-md-12">
                        <div className="card mb-3">
                          <div className="card-body">
                            {colors?.map((c, index) => (
                              <div className="row text-dark mb-3">
                                <div className="col-lg-5 mb-2">
                                  <label htmlFor="" className="mb-2">
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name=""
                                    placeholder="Green"
                                    id=""
                                    value={c.name || ""}
                                    disabled
                                  />
                                </div>
                                <div className="col-lg-5 mb-2">
                                  <label htmlFor="" className="mb-2">
                                    Code
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="#f4f7f6"
                                    className="form-control"
                                    name=""
                                    id=""
                                    value={c.color_code || ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            ))}

                            {colors < 1 && <h4>No Colors Added</h4>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-outdoor-image"
                    role="tabpanel"
                    aria-labelledby="pills-outdoor-image-tab"
                  >
                    <div className="row gutters-sm shadow p-4 rounded">
                      <h4 className="mb-4">Outdoor Image</h4>
                      <div className="col-md-12">
                        <div className="card mb-3">
                          <div className="card-body">
                            {outdoor.map((item, index) => (
                              <div className="row text-dark mb-5">
                                <div className="col-lg-4">
                                  {item.image &&
                                    (item.image.preview ? (
                                      <img
                                        src={item.image.preview}
                                        alt="Product Thumbnail Preview"
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "cover",
                                          borderRadius: 10,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={item.image}
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "contain",
                                          borderRadius: 10,
                                        }}
                                        alt=""
                                      />
                                    ))}

                                  {!item.image && (
                                    <img
                                      src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                                      alt={`Preview for gallery item ${
                                        index + 1
                                      }`}
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: 5,
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-lg-6 mb-2">
                                  <label htmlFor="" className="mb-2 pt-4" style={{fontWeight:"bolder", fontSize:"18px"}}>
                                    Image Url:
                                  </label>
                                  <a href={item.image} target="_blank" className="d-block text-decoration-none text-dark" style={{fontWeight:"bolder", fontSize:"15px"}}>{item.image}</a>
                                </div>
                              </div>
                            ))}
                            {gallery < 1 && <h4>No Images Selected</h4>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-mobile-image"
                    role="tabpanel"
                    aria-labelledby="pills-mobile-image-tab"
                  >
                    <div className="row gutters-sm shadow p-4 rounded">
                      <h4 className="mb-4">Mobile Image</h4>
                      <div className="col-md-12">
                        <div className="card mb-3">
                          <div className="card-body">
                            {mobile.map((item, index) => (
                              <div className="row text-dark mb-5">
                                <div className="col-lg-4">
                                  {item.image &&
                                    (item.image.preview ? (
                                      <img
                                        src={item.image.preview}
                                        alt="Product Thumbnail Preview"
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "cover",
                                          borderRadius: 10,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={item.image}
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          objectFit: "contain",
                                          borderRadius: 10,
                                        }}
                                        alt=""
                                      />
                                    ))}

                                  {!item.image && (
                                    <img
                                      src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                                      alt={`Preview for gallery item ${
                                        index + 1
                                      }`}
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: 5,
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="col-lg-6 mb-2">
                                  <label htmlFor="" className="mb-2 pt-4" style={{fontWeight:"bolder", fontSize:"18px"}}>
                                    Image Url:
                                  </label>
                                  <a href={item.image} target="_blank" className="d-block text-decoration-none text-dark" style={{fontWeight:"bolder", fontSize:"15px"}}>{item.image}</a>
                                </div>
                                <div className="col-lg-3 mt-2">
                                </div>
                              </div>
                            ))}
                            {gallery < 1 && <h4>No Images Selected</h4>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-outdoor-color"
                    role="tabpanel"
                    aria-labelledby="pills-outdoor-color-tab"
                  >
                    <div className="row gutters-sm shadow p-4 rounded">
                      <h4 className="mb-4">Outdoor Color</h4>
                      <div className="col-md-12">
                        <div className="card mb-3">
                          <div className="card-body">
                            {outdoorColor.map((c, index) => (
                              <div className="row text-dark mb-3">
                                <div className="col-lg-5 mb-2">
                                  <label htmlFor="" className="mb-2">
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name=""
                                    placeholder="Green"
                                    id=""
                                    value={c.color_name || ""}
                                    disabled
                                  />
                                </div>
                                <div className="col-lg-5 mb-2">
                                  <label htmlFor="" className="mb-2">
                                    Code
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="#f4f7f6"
                                    className="form-control"
                                    name=""
                                    id=""
                                    value={c.color_code || ""}
                                    disabled
                                  />
                                </div>
                              </div>
                            ))}

                            {colors < 1 && <h4>No Colors Added</h4>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
