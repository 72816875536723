import React, { useState, useEffect,useRef } from 'react'
import Swal from 'sweetalert2'
import Sidebar from './Sidebar';
import { useNavigate,useNavigation } from 'react-router-dom';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { jwtDecode } from "jwt-decode";

function AddProduct() {
    const [specifications, setSpecifications] = useState([{ title: '', content: '' }]);
    const [colors, setColors] = useState([{ name: '', color_code: '', image: null }]);
    const [sizes, setSizes] = useState([{ name: '', price: 0.00 }]);
    const [gallery, setGallery] = useState([{ image: null }]);
    const [category, setCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [outdoor, setoutdoor] = useState([{ image: null }]);
    const [mobile, setmobile] = useState([{ image: null }]);
    const [outdoorColor, setOutdoorColor] = useState([{ name: '', color_code: ''}]);
    const [user, setUser] = useState({});
    const [product, setProduct] = useState({
        title: '',
        image: null,
        description: '',
        category: '',
        tags: '',
        brand: '',
        price: '',
        old_price: '',
        shipping_amount: '',
        stock_qty: '',
        in_stock:false,
        type :"",
        product_color:"",
        product_size:"",
        sku:"",
        slug:"",
        vendor: user.vendor_id,
        status:"published",
        // vendor: userData?.vendor_id
    });
    
    const navigate = useNavigate()
    useEffect(() => {
      const token = localStorage.getItem("token");
      if (token) {
          try {
              const decoded = jwtDecode(token); 
              setUser(decoded)
              if (decoded?.vendor_id === 0) {
                  navigate('/'); 
              }
          } catch (error) {
              navigate('/login');
          }
      } else {
          navigate('/login');
      }
  }, [navigate]);


    
    const handleAddMore = (setStateFunction) => {
        setStateFunction((prevState) => [...prevState, {}]);
    };
    // console.log(mobile[0].image.file)
    function getFormattedDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleRemove = (index, setStateFunction) => {
        setStateFunction((prevState) => {
            const newState = [...prevState];
            newState.splice(index, 1);
            return newState;
        });
    };

    const handleInputChange = (index, field, value, setStateFunction) => {
        setStateFunction((prevState) => {
            const newState = [...prevState];
            newState[index][field] = value;
            return newState;
        });
    };

    const handleImageChange = (index, event, setStateFunction) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setStateFunction((prevState) => {
                    const newState = [...prevState];
                    newState[index].image = { file, preview: reader.result };
                    return newState;
                });
            };

            reader.readAsDataURL(file);
        } else {
            setStateFunction((prevState) => {
                const newState = [...prevState];
                newState[index].image = null; // Set image to null
                newState[index].preview = null; // Optionally set preview to null
                return newState;
            });
        }
    };

    const handleProductInputChange = (event) => {
        setProduct({
            ...product,
            [event.target.name]: event.target.value,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        })
    };

    const handleProductFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setProduct({
                    ...product,
                    image: {
                        file: event.target.files[0],
                        preview: reader.result
                    }
                });
            };
            reader.readAsDataURL(file);
        }
    }


    useEffect(() => {
        const fetchCategory = async () => {
            try{
                axios.get(`${apiurl}/category/`,{
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
                    }
                }).then((res) => {
                    setCategory(res.data)
                })
                .catch(
                    (error) => {
                      if(error.response){
                        if(error.response.status===401){
                          localStorage.removeItem("token");
                          navigate('/login');
                        }
                      }
                    }
                )
            }
            catch(error){
                console.log(error)
            }
        }
        if(user.vendor_id){
            fetchCategory()
        }
    }, [user])

    const validateFields = (gallery, colors, sizes, outdoor, outdoorColor) => {
        const errors = [];
        // Validate gallery images
        gallery.forEach((item, index) => {
            if (!item.image || !item.image.file) {
                errors.push(`Gallery item is missing an image.`);
            }
        });
    
        // Validate colors
        colors.forEach((item, index) => {
            if (!item) {
                errors.push(`Color item is missing.`);
            }
        });
    
        // Validate sizes
        sizes.forEach((item, index) => {
            if (!item) {
                errors.push(`Size item is missing.`);
            }
        });
    
        // Validate outdoor images
        outdoor.forEach((item, index) => {
            if (!item || !item.image || !item.image.file) {
                errors.push(`Outdoor image item is missing an image.`);
            }
        });
    
        // Validate outdoor colors
        outdoorColor.forEach((item, index) => {
            if (!item || !item.name || !item.color_code) {
                errors.push(`Outdoor color item index is missing name or color code.`);
            }
        });
    
        return errors;
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (product.title == "" || product.description == "" || product.price == "" || product.category === null || product.shipping_amount == "" || product.stock_qty == "" || product.image === null || product.tags=="" || product.tags=="" || product.brand=="" || product.old_price=="" || product.type=="" || product.product_color=="" || product.product_size=="" || product.sku=="" || product.slug=="" || product.status=="") {
            // If any required field is missing, show an error message or take appropriate action
            console.log("Please fill in all required fields");
            // setIsLoading(false)

            Swal.fire({
                icon: 'warning',
                title: 'Missing Fields!',
                text: "All fields are required to create a product",
            })
            return;
        }
        const errors=validateFields(gallery, colors, sizes, outdoor, outdoorColor)
        if (errors.length > 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Fields!',
                text: errors,
            })
            return;
        }

        const formData = new FormData(); 
        // Append product fields to the FormData
        formData.append('title', product.title);
        formData.append('image', product.image.file); // Assuming product.image.file is a file object
        formData.append('description', product.description);
        formData.append('category', product.category);
        formData.append('tags', product.tags);
        formData.append('brand', product.brand);
        formData.append('price', product.price);
        formData.append('old_price', product.old_price);
        formData.append('shipping_amount', product.shipping_amount);
        formData.append('stock_qty', product.stock_qty);
        formData.append('product_color', product.product_color);
        formData.append('product_size', product.product_size);
        formData.append('type', product.type);
        formData.append('sku', product.sku);
        formData.append('slug', product.slug);
        formData.append('date', getFormattedDate()); // Assuming getFormattedDate() returns a date string
        formData.append('featured', false); // Booleans will be converted to string ('true'/'false')
        formData.append('hot_deal',false);
        formData.append('special_offer', false);
        formData.append('digital', false);
        formData.append('views',Number(0)); // Set to empty string if null
        formData.append('orders', Number(0));
        formData.append('saved', Number(0));
        formData.append('rating',Number(0));
        formData.append('status', product.status);
        // formData.append('pid', " ");
        formData.append('specification', []);
        formData.append('vendor', user.vendor_id);
        

        gallery.forEach((item, index) => {
                if (item.image) {
                    // console.log("gallery",item.image.file)
                    formData.append(`gallery[${index}][image]`, item.image.file);
                }
            });
        colors.forEach((item, index) => {
            if (item) {
                formData.append(`colors[${index}][name]`, item.name); // Append name
                formData.append(`colors[${index}][color_code]`, item.color_code); // Append color_code
            }
        });
        
        // Append sizes to FormData
        sizes.forEach((item, index) => {
            if (item) {
                formData.append(`sizes[${index}][name]`, item.name); // Append name
                formData.append(`sizes[${index}][price]`, item.price); // Append price
            }
        });

        outdoor.forEach((item, index) => {
            if (item && item.image && item.image.file) {
                formData.append(`outdoor_images[${index}][image]`, item.image.file); // Append image URL
            }
        });

        outdoorColor.forEach((item, index) => {
            if (item) {
                formData.append(`outdoor_colors[${index}][name]`, item.name); // Append color_name
                formData.append(`outdoor_colors[${index}][color_code]`, item.color_code); // Append color_code
                // formData.append(`outdoor_colors[${index}][product]`, ""); // Append product
            }
        });

        mobile.forEach((item, index) => {
            if (item && item.image) {
                formData.append(`mobile_images[${index}][image]`, item.image.file); // Append image file
            }
        });
        try{
            const response = await axios.post(`${apiurl}/vendor-product-create/${user.vendor_id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
                },
            })
            .then((response)=>{
                navigate("/vendor/products/")
                Swal.fire({
                icon: 'success',
                title: 'Product Created Successfully',
                text: 'This product has been successfully created',
            });
            const data =response.json();
            })
            .catch(
                (error) => {
                    // console.log(error)
                  if(error.response){
                    if(error.response.status===401){
                      localStorage.removeItem("token");
                      navigate('/login');
                    }
                  }
                }
            );
            // navigate('/vendor/products/')
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsLoading(false)
        }
    };

    return (
        <div>
            <div className="container-fluid pt-5 bg-secondary" id="main">
                <div className="row row-offcanvas row-offcanvas-left h-100">
                    <Sidebar />
                    {/*/col*/}
                    <div className="col-md-9 col-lg-10 main mt-4">
                        <div className="container">
                            <form className="main-body" method='POST' encType="multipart/form-data" onSubmit={handleSubmit}>
                                <div className="tab-content" id="pills-tabContent">
                                <div>
                                        <ul
                                            className="nav nav-pills mb-3 d-flex justify-content-center mt-5"
                                            id="pills-tab"
                                            role="tablist"
                                        >
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="pills-home-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-home"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-home"
                                                    aria-selected="true"
                                                >
                                                    Basic Information
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-profile-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-profile"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-profile"
                                                    aria-selected="false"
                                                >
                                                    Product Image
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-contact-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-contact"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-contact"
                                                    aria-selected="false"
                                                >
                                                    Specifications
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-size-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-size"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-size"
                                                    aria-selected="false"
                                                >
                                                    Size
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-color-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-color"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-color"
                                                    aria-selected="false"
                                                >
                                                    Color
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-outdoor-image-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-outdoor-image"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-outdoor-image"
                                                    aria-selected="false"
                                                >
                                                    Outdoor Image
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-mobile-image-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-mobile-image"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-mobile-image"
                                                    aria-selected="false"
                                                >
                                                   Mobile Image
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-outdoor-color-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-outdoor-color"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-color"
                                                    aria-selected="false"
                                                >
                                                    Outdoor Color
                                                </button>
                                            </li>
                                                
                                        </ul>
                                        <div className="d-flex justify-content-center mb-5 ">
                                            {isLoading === false &&
                                                <button type='submit' className="btn btn-dark w-50">
                                                    Create Product <i className="fa fa-check-circle" />{" "}
                                                </button>
                                            }

                                            {isLoading === true &&
                                                <button disabled className="btn btn-dark w-50">
                                                    Creating... <i className="fa fa-spinner fa-spin" />{" "}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-home"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4">Product Details</h4>
                                            <div className="col-md-4 mb-3">
                                                <div className="card h-100">
                                                    <div className="card-body">
                                                        <div className="d-flex flex-column align-items-center text-center">
                                                            {product.image && product.image.preview ? (
                                                                <img
                                                                    src={product.image.preview}
                                                                    alt="Product Thumbnail Preview"
                                                                    style={{ width: '100%', height: '200px', objectFit: 'contain', borderRadius: 10 }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                                                                    style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: 10 }}
                                                                    alt=""
                                                                />
                                                            )}

                                                            <div className="mt-3">
                                                                {product.title !== "" &&
                                                                    <h4 className="text-dark">{product.title}</h4>
                                                                }
                                                                {product.title === "" &&
                                                                    <h4 className="text-dark">Product Title</h4>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-8">
                                                <div className="card mb-3">

                                                    <div className="card-body">

                                                        <div className="row text-dark">
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Product Thumbnail
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name="image"
                                                                    id=""
                                                                    onChange={handleProductFileChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Title
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id=""
                                                                    name="title"
                                                                    value={product.title || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-12 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Description
                                                                </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    id=""
                                                                    cols={30}
                                                                    rows={4}
                                                                    defaultValue={""}
                                                                    name="description"
                                                                    value={product.description || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                                {/* <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data="<p>Hello from CKEditor&nbsp;5!</p>"
                                                                    onReady={editor => {
                                                                        // You can store the "editor" and use when it is needed.
                                                                        console.log('Editor is ready to use!', editor);
                                                                    }}
                                                                    onChange={(event) => handleProductInputChange()}
                                                                    onBlur={(event, editor) => {
                                                                        console.log('Blur.', editor);
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        console.log('Focus.', editor);
                                                                    }}
                                                                /> */}
                                                            </div>
                                                            <div className="col-lg-6 mb-2">
                                                                <label htmlFor="" className="mb-2">
                                                                    Category
                                                                </label>
                                                                <select
                                                                    className="select form-control"
                                                                    id=""
                                                                    name="category"
                                                                    value={product.category || ''}
                                                                    onChange={handleProductInputChange}
                                                                >
                                                                    <option value="">- Select -</option>
                                                                    {category.map((c, index) => (
                                                                        <option key={index} value={c.id}>{c.title}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Brand
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="brand"
                                                                    value={product.brand || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Sale Price
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="price"
                                                                    value={product.price || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Regular Price
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="old_price"
                                                                    value={product.old_price || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Shipping Amount
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="shipping_amount"
                                                                    value={product.shipping_amount || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Stock Qty
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="stock_qty"
                                                                    value={product.stock_qty || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Type
                                                                </label>
                                                                <select name="type" id="" className="form-control" value={product.type || ''} onChange={handleProductInputChange}>
                                                                    <option value="">-Select-</option>
                                                                    <option value="regular">Regular</option>
                                                                    <option value="auction">Auction</option>
                                                                    <option value="offer">Offer</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                Product Color
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="product_color"
                                                                    value={product.product_color || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                Product Size
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="product_size"
                                                                    value={product.product_size || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                SKU
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="sku"
                                                                    value={product.sku || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                                <span style={{ fontSize: 12 }} className='text-danger'>NOTE: SKU must be unique</span>
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                Slug
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="slug"
                                                                    value={product.slug || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                                <span style={{ fontSize: 12 }} className='text-danger'>NOTE: Slug must be unique</span>
                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Tags
                                                                </label>
                                                                <br />
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="tags"
                                                                    value={product.tags || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                                <span style={{ fontSize: 12 }} className='text-muted'>NOTE: Seperate tags with comma</span>

                                                            </div>
                                                            <div className="col-lg-6 mb-2 ">
                                                                <label htmlFor="" className="mb-2">
                                                                    Status
                                                                </label>
                                                                <br />
                                                                <select name="status" id="" className="form-control" value={product.status || ''} onChange={handleProductInputChange}>
                                                                    <option value="">-Select-</option>
                                                                    <option value="draft">Draft</option>
                                                                    <option value="disabled">Disabled</option>
                                                                    <option value="in_review">In Review</option>
                                                                    <option value="published">Published</option>
                                                                </select>
                                                                {/* <br />
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="tags"
                                                                    value={product.tags || ''}
                                                                    onChange={handleProductInputChange}
                                                                />
                                                                <span style={{ fontSize: 12 }} className='text-muted'>NOTE: Seperate tags with comma</span> */}

                                                            </div>
                                                            <div className="col-lg-6 mb-2 mt-4 pt-2">
                                                                <label htmlFor="" className="mb-2">
                                                                In Stock
                                                                </label> &nbsp;
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name="in_stock"
                                                                    checked={product.in_stock} // Using `checked` for checkbox
                                                                    onChange={handleProductInputChange} // You need to handle the change event
                                                                />
                                                            </div>
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-profile"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4">Product Image</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        {gallery.map((item, index) => (

                                                            <div className="row text-dark mb-5">
                                                                <div className="col-lg-3">
                                                                    {item.image && (
                                                                        <img
                                                                            src={item.image.preview}
                                                                            alt={`Preview for gallery item ${index + 1}`}
                                                                            style={{ width: '100%', height: '100px', objectFit: 'contain', borderRadius: 5 }}
                                                                        />
                                                                    )}

                                                                    {!item.image && (
                                                                        <img
                                                                            src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                                                                            alt={`Preview for gallery item ${index + 1}`}
                                                                            style={{ width: '100%', height: '100px', objectFit: 'cover', borderRadius: 5 }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="col-lg-6 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Product Image
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name=""
                                                                        id=""
                                                                        onChange={(e) => handleImageChange(index, e, setGallery)}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-3 mt-2">
                                                                    <button onClick={() => handleRemove(index, setGallery)} type='button' className='btn btn-danger mt-4'>Remove</button>
                                                                </div>

                                                            </div>
                                                        ))}

                                                        {gallery < 1 &&
                                                            <h4>No Images Selected</h4>
                                                        }

                                                        <button type='button' onClick={() => handleAddMore(setGallery)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Images
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-contact"
                                        role="tabpanel"
                                        aria-labelledby="pills-contact-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4">Specifications</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">

                                                        {specifications.map((specification, index) => (

                                                            <div className="row text-dark">
                                                                <div className="col-lg-3 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Title
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.title || ''}
                                                                        onChange={(e) => handleInputChange(index, 'title', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-6 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Content
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={specification.content || ''}
                                                                        onChange={(e) => handleInputChange(index, 'content', e.target.value, setSpecifications)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-3 mb-2">
                                                                    <button type='button' onClick={() => handleRemove(index, setSpecifications)} className='btn btn-danger mt-4'>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {specifications.length < 1 &&
                                                            <h4>No Specification Form</h4>
                                                        }

                                                        <button type='button' onClick={() => handleAddMore(setSpecifications)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Specifications
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="tab-pane fade"
                                        id="pills-size"
                                        role="tabpanel"
                                        aria-labelledby="pills-size-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4">Sizes</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        {sizes.map((s, index) => (

                                                            <div className="row text-dark">
                                                                <div className="col-lg-3 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Size
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name=""
                                                                        placeholder="XXL"
                                                                        id=""
                                                                        value={s.name || ''}
                                                                        onChange={(e) => handleInputChange(index, 'name', e.target.value, setSizes)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-6 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Price
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        placeholder="$20"
                                                                        className="form-control"
                                                                        name=""
                                                                        id=""
                                                                        value={s.price || ''}
                                                                        onChange={(e) => handleInputChange(index, 'price', e.target.value, setSizes)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-3 mt-2">
                                                                    <button type='button' onClick={() => handleRemove(index, setSizes)} className='btn btn-danger mt-4'>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {sizes < 1 &&
                                                            <h4>No Size Added</h4>
                                                        }
                                                        <button type='button' onClick={() => handleAddMore(setSizes)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Sizes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-color"
                                        role="tabpanel"
                                        aria-labelledby="pills-color-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4">Color</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        {colors.map((c, index) => (
                                                            <div className="row text-dark mb-3">
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name=""
                                                                        placeholder="Green"
                                                                        id=""
                                                                        value={c.name || ''}
                                                                        onChange={(e) => handleInputChange(index, 'name', e.target.value, setColors)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-2 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Code
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="#f4f7f6"
                                                                        className="form-control"
                                                                        name=""
                                                                        id=""
                                                                        value={c.color_code || ''}
                                                                        onChange={(e) => handleInputChange(index, 'color_code', e.target.value, setColors)}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-3 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Image
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name=""
                                                                        id=""
                                                                        onChange={(e) => handleImageChange(index, e, setColors)}

                                                                    />
                                                                </div>

                                                                <div className="col-lg-3 mt-2">
                                                                    {c.image && (
                                                                        <img
                                                                            src={c.image.preview}
                                                                            alt={`Preview for gallery item ${index + 1}`}
                                                                            style={{ width: '100%', height: '100px', objectFit: 'cover', borderRadius: 5 }}
                                                                        />
                                                                    )}
                                                                    {!c.image && (
                                                                        <img
                                                                            src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                                                                            alt={`Preview for gallery item ${index + 1}`}
                                                                            style={{ width: '100%', height: '100px', objectFit: 'cover', borderRadius: 5 }}
                                                                        />
                                                                    )}
                                                                </div>

                                                                <div className="col-lg-2 mt-2">
                                                                    <button type='button' onClick={() => handleRemove(index, setColors)} className='btn btn-danger mt-4'>Remove</button>
                                                                </div>

                                                            </div>
                                                        ))}

                                                        {colors < 1 &&
                                                            <h4>No Colors Added</h4>
                                                        }

                                                        <button type='button' onClick={() => handleAddMore(setColors)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Colors
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-outdoor-image"
                                        role="tabpanel"
                                        aria-labelledby="pills-outdoor-image-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4">Outdoor Image</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        {outdoor.map((item, index) => (
                                                            <div className="row text-dark mb-5">
                                                                <div className="col-lg-3">
                                                                    {item.image && (
                                                                        <img
                                                                            src={item.image.preview}
                                                                            alt={`Preview for gallery item ${index + 1}`}
                                                                            style={{ width: '100%', height: '100px', objectFit: 'contain', borderRadius: 5 }}
                                                                        />
                                                                    )}

                                                                    {!item.image && (
                                                                        <img
                                                                            src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                                                                            alt={`Preview for gallery item ${index + 1}`}
                                                                            style={{ width: '100%', height: '100px', objectFit: 'cover', borderRadius: 5 }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="col-lg-6 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Mobile Image
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name=""
                                                                        id=""
                                                                        onChange={(e) => handleImageChange(index, e, setoutdoor)}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-3 mt-2">
                                                                    <button onClick={() => handleRemove(index, setoutdoor)} type='button' className='btn btn-danger mt-4'>Remove</button>
                                                                </div>

                                                            </div>
                                                        ))}

                                                        {gallery < 1 &&
                                                            <h4>No Images Selected</h4>
                                                        }

                                                        <button type='button' onClick={() => handleAddMore(setoutdoor)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Images
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-mobile-image"
                                        role="tabpanel"
                                        aria-labelledby="pills-mobile-image-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4">Mobile Image</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        {mobile.map((item, index) => (
                                                            <div className="row text-dark mb-5">
                                                                <div className="col-lg-3">
                                                                    {item.image && (
                                                                        <img
                                                                            src={item.image.preview}
                                                                            alt={`Preview for gallery item ${index + 1}`}
                                                                            style={{ width: '100%', height: '100px', objectFit: 'contain', borderRadius: 5 }}
                                                                        />
                                                                    )}

                                                                    {!item.image && (
                                                                        <img
                                                                            src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                                                                            alt={`Preview for gallery item ${index + 1}`}
                                                                            style={{ width: '100%', height: '100px', objectFit: 'cover', borderRadius: 5 }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="col-lg-6 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        mobile Image
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name=""
                                                                        id=""
                                                                        onChange={(e) => handleImageChange(index, e, setmobile)}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-3 mt-2">
                                                                    <button onClick={() => handleRemove(index, setmobile)} type='button' className='btn btn-danger mt-4'>Remove</button>
                                                                </div>

                                                            </div>
                                                        ))}

                                                        {gallery < 1 &&
                                                            <h4>No Images Selected</h4>
                                                        }

                                                        <button type='button' onClick={() => handleAddMore(setmobile)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Images
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-outdoor-color"
                                        role="tabpanel"
                                        aria-labelledby="pills-outdoor-color-tab"
                                    >
                                        <div className="row gutters-sm shadow p-4 rounded">
                                            <h4 className="mb-4">Outdoor Color</h4>
                                            <div className="col-md-12">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        {outdoorColor.map((c, index) => (
                                                            <div className="row text-dark mb-3">
                                                                <div className="col-lg-5 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name=""
                                                                        placeholder="Green"
                                                                        id=""
                                                                        value={c.name || ''}
                                                                        onChange={(e) => handleInputChange(index, 'name', e.target.value, setOutdoorColor)}
                                                                    />
                                                                </div>
                                                                <div className="col-lg-5 mb-2">
                                                                    <label htmlFor="" className="mb-2">
                                                                        Code
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="#f4f7f6"
                                                                        className="form-control"
                                                                        name=""
                                                                        id=""
                                                                        maxLength={7}
                                                                        value={c.color_code || ''}
                                                                        onChange={(e) => handleInputChange(index, 'color_code', e.target.value, setOutdoorColor)}
                                                                    />
                                                                </div>

                                                                <div className="col-lg-2 mt-2">
                                                                    <button type='button' onClick={() => handleRemove(index, setOutdoorColor)} className='btn btn-danger mt-4'>Remove</button>
                                                                </div>

                                                            </div>
                                                        ))}

                                                        {colors < 1 &&
                                                            <h4>No Colors Added</h4>
                                                        }

                                                        <button type='button' onClick={() => handleAddMore(setOutdoorColor)} className="btn btn-primary mt-2">
                                                            <i className="fas fa-plus" /> Add More Colors
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddProduct