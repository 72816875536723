import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/layout/footer/footer';
import Header from './components/layout/header/header';
import Home from './components/pages/home/home';
import Contact from './components/pages/contact/contact';
import About from './components/pages/about/about';
import Registration from './components/pages/auth/rgistration/registration';
import Login from './components/pages/auth/login/login';
import Forgotpassword from './components/pages/auth/forgotpassword/forgotpassword';
import Confirmpassword from './components/pages/auth/confirmpassword/confirmpassword';
import Shop from './components/pages/shop/shop';
import Product from './components/pages/product/product';
import Checkout from './components/pages/checkout/checkout';
import Invoice from './components/pages/invoice/invoice';
import Confirmeder from './components/pages/confirmeder/confirmeder';
import Ordertrack from './components/pages/ordertrack/ordertrack';
import Ordertrackinfo from './components/pages/ordertrack/ordertrackinfo';
import Clientsposts from './components/pages/clientsposts/clientsposts';
import Fabricinfo from './components/pages/fabricinfo/fabricinfo';
import Confirmpayment from './components/pages/confirmpayment/confirmpayment';
import Termscondition from './components/pages/termsnadconditions/termscondition';
import Privacypolicy from './components/pages/privacypolicy/privacypolicy';
import Returnpolicy from './components/pages/returnpolicy/returnpolicy';
import Shippingpolicy from './components/pages/shippingpolicy/shippingpolicy';
import Pagenotfound from './components/pages/notfound/pagenotfound';
import ScrollToTop from './scrolltotop';
import Thankyou from './components/pages/thankyou/thankyou';
import Faq from './components/pages/faq/faq';
import { useEffect } from 'react';
import { fetchProducts } from "../src/store/productslice";
import { useDispatch } from 'react-redux';
import Dashboard from './vendor/Dashboard';
import Products from './vendor/Products';
import UpdateProduct from './vendor/UpdateProduct';
import Orders from "./vendor/Orders"
import Earning from './vendor/Earning';
import Notifications from './vendor/Notifications';
import Coupon from './vendor/Coupon';
import EditCoupon from "./vendor/EditCoupon"
import AddProduct from "./vendor/AddProduct"
import OrderDetail from './vendor/OrderDetail';
import OrderItemDetail from "./vendor/OrderItemDetail"
import Reviews from './vendor/Reviews';
import Settings from './vendor/Settings';
import ViewProduct from './vendor/ViewProduct';
import Contactus from './vendor/Contact-us';
import Account from './components/useradmin/Account';

import ShiprocketView from './vendor/ShiprocketView';
import UpdateShiprocketOrder from './vendor/UpdateShiprocketOrder';
import AddShiprocketOrder from './vendor/AddShiprocketOrder';

// import Orders from './components/useradmin/Orders';
import UserOrders  from './components/useradmin/Orders';
import UserNotifications from './components/useradmin/Notifications';
import UserSettings from './components/useradmin/Settings';
import UserOrderDetail from './components/useradmin/OrderDetail';
import CareInstructions from './components/pages/care-instructions/CareInstructions';
import RefersAndEarn from './components/pages/refers and earns/refersAndEarn';
import GiftCard from './components/pages/gift-card/giftCard';

function App() {
  const dispatch = useDispatch();
  let fetchCartData;
  function passfunction(fun){
    fetchCartData=fun
  }
  useEffect(()=>{
    fetchCartData()
  },[fetchCartData])

  return (
    <>
      <Header passfunction={passfunction}/>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/product/:productName" element={<Product fetchCartData={()=>fetchCartData()}/>} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="register" element={<Registration />} />
        <Route path="login" element={<Login/>} />
        <Route path="forgotpassword" element={<Forgotpassword />} />
        <Route path="confirmpassword" element={<Confirmpassword />} />
        <Route path="check-out" element={<Checkout fetchCartData={()=>fetchCartData()}/>} />
        <Route path="invoices" element={<Invoice />} />
        <Route path="order-confirmation" element={<Confirmeder />} />
        <Route path="track-order" element={<Ordertrack />} />
        <Route path="track-order-info" element={<Ordertrackinfo />} />
        <Route path="client-posts" element={<Clientsposts />} />
        <Route path="fabric-info" element={<Fabricinfo />} />
        <Route path="confirm-your-payment" element={<Confirmpayment />} />
        <Route path="terms-and-conditions" element={<Termscondition />} />
        <Route path="privacy-policy" element={<Privacypolicy />} />
        <Route path="return-policy" element={<Returnpolicy />} />
        <Route path="shppping-policy" element={<Shippingpolicy />} />
        <Route path="user-profile" element={<Account/>} />
        <Route path="user-profile/orders" element={<UserOrders/>} />
        <Route path="user-profile/order/detail/:oid" element={<UserOrderDetail/>} />
        <Route path="user-profile/notifications" element={<UserNotifications/>} />
        <Route path="user-profile/settings" element={<UserSettings/>} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="/care-instructions" element={<CareInstructions/>} />
        <Route path="faq's" element={<Faq />} />
        <Route path="refers-and-earn" element={<RefersAndEarn />} />
        <Route path="gift-card" element={<GiftCard />} />
        <Route path="thank-you" element={<Thankyou />} />
        <Route path="/vendor/dashboard/" element={<Dashboard/>} />
        <Route path="/vendor/products/" element={<Products/>} />
        <Route path="/vendor/update-product/:param" element={<UpdateProduct/>} />
        <Route path="/vendor/orders/" element={<Orders/>} />
        <Route path="/vendor/earning/" element={<Earning/>} />
        <Route path="/vendor/notifications/" element={<Notifications/>} />
        <Route path="/vendor/coupon/" element={<Coupon/>} />
        <Route path="/vendor/coupon/:coupon_id" element={<EditCoupon/>} />
        <Route path="/vendor/product/new/" element={<AddProduct/>} />
        <Route path="/vendor/product/:param" element={<OrderDetail/>} />
        <Route path="/vendor/orders/:oid/:id" element={<OrderItemDetail/>} />
        <Route path='/vendor/reviews/' element={<Reviews/>} />
        <Route path="/vendor/settings/" element={<Settings/>} />
        <Route path="/vendor/view-product/:param" element={<ViewProduct/>} />
        <Route path="/vendor/contact-us/" element={<Contactus/>} />
        {/* <Route path="*" element={<Pagenotfound />} /> */}
        <Route path="*" element={<Shop />} />
        <Route path="/vendor/ship-rockets-order" element={<ShiprocketView/>} />
        <Route path="/vendor/ship-rockets-order-update/:id" element={<UpdateShiprocketOrder/>} />
        <Route path="/vendor/ship-rockets-order-update/new" element={<AddShiprocketOrder/>} />
      </Routes>
      <Footer />
    </>
  );
}

// Wrap the App component with BrowserRouter
export default function Root() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}