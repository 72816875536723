import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import Chart from "chart.js/auto";
// import { Pie, Line } from "react-chartjs-2";
import { jwtDecode } from "jwt-decode";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Label } from 'recharts';
import Barchart from "./Barchart";



import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function Dashboard() {
  const [user, setUser] = useState({});
  const [rerender, setrerender] = useState();
  const [stats, setStats] = useState(null);
  const [products, setProducts] = useState(null);
  const [orders, setOrders] = useState(null);
  const [orderChartData, setOrderChartData] = useState(null);
  const [productsChartData, setProductsChartData] = useState(null);
  const [month, setmonth] = useState([]);
  const [count, setcount] = useState([]);
  const [ordercount,setordercout]=useState()
  const [revenue,setrevenue]=useState()
  const navigate = useNavigate();
  const [dis,setdis]=useState(false)
  const [flterData,setFilterData]=useState()
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
        try {
            const decoded = jwtDecode(token); 
            // console.log(decoded)
            setUser(decoded)
            if (decoded?.vendor_id === 0) {
                navigate('/'); 
            }
        } catch (error) {
            navigate('/login');
        }
    } else {
        navigate('/login');
    }
}, [navigate]);

const fetchStatsData = async () => {
  try {
    const response = await axios.get(`${apiurl}/vendor/stats/${user.vendor_id}/`,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`,
    }
    }).catch(
      (error) => {
        if(error.response){
          if(error.response.status===401){
            localStorage.removeItem("token");
            navigate('/login');
          }
        }
      }
    );
    setStats(response.data[0]);
    setordercout(response.data[0].orders)
    setrevenue(response.data[0].revenue)
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

  useEffect(() => {
    // console.log(localStorage.getItem("token"))
    if(user.vendor_id){ 
      fetchStatsData();
    }
  }, [user]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token")?.replace(/^"|"$/g, ''); // Clean the token
      const response = await axios.get(`${apiurl}/vendor/products/${user.vendor_id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
        },
      }).catch(
        (error) => {
          if(error.response){
            if(error.response.status===401){
              localStorage.removeItem("token");
              navigate('/login');
            }
          }
        }
      );
      setProducts(response.data); // Store fetched products in state
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Check if vendor_id exists before calling fetchData
    if (user.vendor_id) {
      fetchData();
    }
  }, [user]); // Dependency array includes 'user'
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiurl}/vendor/orders/${user.vendor_id}/`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
          }
      })
      .catch(
        (error) => {
          if(error.response){
            if(error.response.status===401){
              localStorage.removeItem("token");
              navigate('/login');
            }
          }
        }
      );
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if(user.vendor_id){
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const order_response = await axios.get(
          `${apiurl}/vendor-orders-report-chart/${user.vendor_id}/`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
            }
        }
        ).catch(
          (error) => {
            if(error.response){
              if(error.response.status===401){
                localStorage.removeItem("token");
                navigate('/login');
              }
            }
          }
        );
        setOrderChartData(order_response.data);

        const product_response = await axios.get(
          `${apiurl}/vendor-products-report-chart/${user.vendor_id}/`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
            }
        }
        ).catch(
          (error) => {
            if(error.response){
              if(error.response.status===401){
                localStorage.removeItem("token");
                navigate('/login');
              }
            }
          }
        );
        console.log(product_response.data)
        setProductsChartData(product_response.data);
      } catch (error) {
        // console.log(error);
      }
    };
    if(user.vendor_id){
      fetchChartData();
    }
  }, [user]);
  
  const fetching_report = async (a) => {
    try {
      const response = await axios.get(
        `${apiurl}/vendor/yearly-report/${user.vendor_id}/?time_period=${a}`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
          }
      }
      ).catch(
        (error) => {
          if(error.response){
            if(error.response.status===401){
              localStorage.removeItem("token");
              navigate('/login');
            }
          }
        }
      );
      setFilterData(response.data)
      setdis(true)
      const order_months = response.data?.map((item) => item.product__title);
      const order_counts = response.data?.map((item) => item.order_count);
      setcount(order_counts);
      setmonth(order_months);
      let revenue=0
      let count=0
      for (let i=0;i<response.data.length;i++){
        revenue+=response.data[i].total_revenue
        count+=response.data[i].order_count
      }
      setordercout(count)
      setrevenue(revenue)
      // setOrders(count)
    } catch (error) {
      console.error("Error fetching monthly-report data:", error);
    }
  };
  const fetchGeneralReport =()=>{
    fetchStatsData()
    setdis(false)
  }

  const product_labels = productsChartData?.map((item) => item.month);
  const product_count = productsChartData?.map((item) => item.product_count);
  const order_months = orderChartData?.map((item) => item.month);
  const order_counts = orderChartData?.map((item) => item.order_count);
  useEffect(() => {
    setcount(order_counts);
    setmonth(order_months);
  },[productsChartData]);
  // console.log("givawiawe", products)

  const order_data = {
    labels: month,
    datasets: [
      {
        label: "Total Orders",
        data: count,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const handleDeleteProduct = async (productPid) => {
    try {
        const response = await axios.delete(`${apiurl}/vendor-product-delete/${user.vendor_id}/${productPid}/`,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
            }
        })
        fetchData();
        setStats({
          products:(stats.products-1)
        }).catch(
          (error) => {
            if(error.response){
              if(error.response.status===401){
                localStorage.removeItem("token");
                navigate('/login');
              }
            }
          }
        );
    } catch (error) {
        console.log(error);
    }
}
  return (
    <div className="container-fluid pt-5 bg-secondary" id="main">
      <div className="row row-offcanvas row-offcanvas-left h-100">
        <Sidebar />
        <div className="col-md-9 col-lg-10 main mt-4">
          <div className="row mb-3 text-white">
            <div className="col-xl-4 col-lg-6 mb-2">
              <div className="card card-inverse card-success">
                <div className="card-block bg-dark p-3 rounded text-center">
                  <div className="rotate text-light">
                    <i className="bi bi-grid fa-5x" />
                  </div>
                  <h6 className="text-uppercase text-light">Products</h6>
                  <h1 className="display-1 text-light">{stats?.products || 0}</h1>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-2">
              <div className="card card-inverse card-danger">
                <div className="card-block bg-dark p-3 rounded text-center p-3">
                  <div className="rotate text-light">
                    <i className="bi bi-cart-check fa-5x" />
                  </div>
                  <h6 className="text-uppercase text-light">Orders</h6>
                  <h1 className="display-1 text-light">{ordercount}</h1>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 mb-2">
              <div className="card card-inverse card-warning">
                <div className="card-block bg-warning p-3 bg-dark p-3 rounded text-center">
                  <div className="rotate text-light">
                    <i className="bi bi-currency-dollar fa-5x" />
                  </div>
                  <h6 className="text-uppercase text-light">Revenue</h6>
                  <h1
                    className="display-1 text-light"
                    style={{ fontSize: "3rem", height: "6rem" }}
                  >
                    ₹{Math.floor(revenue) || 0}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {/*/row*/}
          <hr />
          <div className="row mb-1 mt-4">
            <div className="col">
              <h4>Chart Analytics</h4>
            </div>
          </div>
          <Link className="btn btn-dark me-2 mb-2" onClick={fetchGeneralReport}>General Report</Link>
          <Link className="btn btn-dark me-2 mb-2" onClick={() => fetching_report("daily")}>Today Report</Link>
          <Link className="btn btn-dark me-2 mb-2" onClick={() => fetching_report("monthly")}>Monthly Report</Link>
          <Link className="btn btn-dark me-2 mb-2" onClick={() => fetching_report("yearly")}>Yearly Report</Link> 
          <div className="row mb-1 mt-4">
              <div className="col-lg-6">
                <div className="card">
                <div className="card-body" style={{height:"25rem",width:"100%",backgroundColor:"aliceblue", display:dis?"none":"block"}}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={orderChartData}
                      margin={{
                        top: 15,
                        right: 5,
                        left: 1,
                        bottom: 10,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month">
                        <Label 
                          value="Months" // Custom X-axis label name
                          offset={0} // Adjusts the position of the label
                          stroke="#8884d8" 
                          strokeWidth={0.5}
                          position="insideBottom" // Position of the label
                          style={{ textAnchor: 'middle', color: 'black' }} // Style for the label
                        />
                      </XAxis>
                      <YAxis>
                        <Label
                          value="Order Count" // Custom Y-axis label name
                          angle={-90} // Rotate the label vertically
                          stroke="#8884d8" 
                          strokeWidth={0.5}
                          position="insideLeft" // Position of the label
                          style={{ textAnchor: 'middle',color:"#8884d8"}} // Center the label text
                        />
                      </YAxis>
                      <Tooltip />
                      <Legend content={({ payload }) => null} />
                      <Line 
                        type="monotone"
                        dataKey="order_count" 
                        stroke="#8884d8" 
                        activeDot={{ r: 8 }} 
                        name="Product Count"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                  </div>
                  <div className="card-body" style={{height:"25rem",width:"100%",backgroundColor:"aliceblue",display:dis?"block":"none",overflowX:"auto"}}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={flterData}
                      margin={{
                        top: 15,
                        right: 5,
                        left: 1,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="product__title" tick={{ fontSize: 9,fontWeight: 'bold' }}>
                      </XAxis>
                      <YAxis tick={{ fontSize: 9,fontWeight: 'bold' }}>
                        <Label
                          value="Order Count" // Custom Y-axis label name
                          angle={-90} // Rotate the label vertically
                          stroke="#8884d8" 
                          strokeWidth={0.5}
                          position="insideLeft" // Position of the label
                          style={{ textAnchor: 'middle',color:"#8884d8"}} // Center the label text
                        />
                      </YAxis>
                      <YAxis 
                        yAxisId="right" // Unique ID for right Y-axis
                        orientation="right" // Places Y-axis on the right side
                        tick={{ fontSize: 9, fontWeight: 'bold' }} 
                        domain={['dataMin', 'dataMax + 10']} // Set domain to show appropriate ticks
                      >
                        <Label
                          value="Total Revenue"
                          angle={90}
                          stroke="#9b59b6"
                          strokeWidth={0.5}
                          position="insideRight"
                          style={{ textAnchor: 'middle', color: "#9b59b6",fontSize:"12" }}
                        />
                      </YAxis>
                      <Tooltip />
                      <Legend content={({ payload }) => null} />
                      <Line 
                          type="monotone" 
                          dataKey={flterData && flterData.length > 0 ? (flterData[0].day ? "day" : (flterData[0].month ? "month" : "year")) : "year"} // Default to "year" if no data} 
                          stroke="#3498db" 
                          activeDot={{ r: 5 }} 
                          yAxisId="right" // Link to right Y-axis
                      />
                      <Line 
                        type="monotone" 
                        dataKey="order_count" 
                        stroke="#8884d8" 
                        activeDot={{ r: 5 }} 
                        name="Order Count"
                      />
                       <Line 
                          type="monotone" 
                          dataKey="total_revenue" 
                          stroke="#9b59b6" 
                          activeDot={{ r: 5 }} 
                          yAxisId="right" // Link to right Y-axis
                        />
                    </LineChart>
                  </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body" style={{height:"25rem",width:"100%",backgroundColor:"aliceblue"}}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={productsChartData}
                      margin={{
                        top: 15,
                        right: 5,
                        left: 1,
                        bottom: 10,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month">
                        <Label 
                          value="Months" // Custom X-axis label name
                          offset={0} // Adjusts the position of the label
                          stroke="#8884d8" 
                          strokeWidth={0.5}
                          position="insideBottom" // Position of the label
                          style={{ textAnchor: 'middle', color: 'black' }} // Style for the label
                        />
                      </XAxis>
                      <YAxis>
                        <Label
                          value="Product Count" // Custom Y-axis label name
                          angle={-90} // Rotate the label vertically
                          stroke="#8884d8" 
                          strokeWidth={0.5}
                          position="insideLeft" // Position of the label
                          style={{ textAnchor: 'middle',color:"#8884d8"}} // Center the label text
                        />
                      </YAxis>
                      <Tooltip />
                      <Legend content={({ payload }) => null} />
                      <Line 
                        type="monotone" 
                        dataKey="product_count" 
                        stroke="#8884d8" 
                        activeDot={{ r: 5 }} 
                        name="Product Count"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                  </div>
                </div>
              </div>
          </div>   
          {/* <div className="row mb-1 mt-4">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body" style={{height:"25rem",width:"100%",backgroundColor:"aliceblue"}}>
                  <Barchart/>
                </div>
              </div>
            </div>
          </div> */}
          <a id="layouts" />
          <div className="mb-3 mt-5" style={{ marginBottom: 300 }}>
            <nav className="mb-5 text-dark">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className="nav-link active "
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  {" "}
                  <i className="bi bi-grid-fill"></i>{" "}
                  <span style={{ color: "black" }}>Product</span>
                </button>
                <button
                  className="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  {" "}
                  <i
                    className="fas fa-shopping-cart"
                    style={{ color: "black" }}
                  ></i>{" "}
                  <span style={{ color: "black" }}>Orders</span>
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active table-responsive"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                {/* <h4>Products</h4> */}
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Orders</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((p, index) => (
                      <tr key={index}>
                        <th scope="row">{p.sku}</th>
                        <td>{p.title}</td>
                        <td>₹{p.price}</td>
                        <td>{p.stock_qty}</td>
                        <td>{p.order_count}</td>
                        <td>{p?.status?.toUpperCase()}</td>
                        <td>
                          <Link
                            to={`/vendor/view-product/${p.pid}`}
                            className="btn btn-primary mb-1 me-2 btn-dark"
                          >
                            <i className="fas fa-eye" />
                          </Link>
                          <Link to={`/vendor/update-product/${p.pid}`}  className="btn btn-dark mb-1 me-2">
                            <i className="fas fa-edit" />
                          </Link>
                          <button type='button ' onClick={() => handleDeleteProduct(p.pid)} className="btn btn-dark mb-1 me-2"><i className="fas fa-trash" /></button>
                        </td>
                      </tr>
                    ))}

                    {products < 1 && (
                      <h5 className="mt-4 p-3">No products yet</h5>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className="tab-pane fade table-responsive"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                {/* <h4>Orders</h4> */}
                <table className="table">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders?.map((o, index) => (
                      <tr key={index}>
                        <th scope="row">{o.oid}</th>
                        <td>{o.full_name}</td>
                        <td>{moment(o.date).format("MM/DD/YYYY")}</td>
                        <td>{o.order_status}</td>
                        <td>
                          <Link to={`/vendor/product/${o.oid}/`} className="btn btn-dark mb-1">
                            <i className="fas fa-eye" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                    {orders < 1 && <h5 className="mt-4 p-3">No orders yet</h5>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
