import React from 'react';
import './thankyou.css'
import { Link } from 'react-router-dom';

export default function Thankyou() {
  return (
    <>
    <div className='thank-you-main'>
        <div className='container'>
            <div className='thank-you text-center'>
                <img src='/assets/images/icons/check-mark.png' className='img-fluid w-10' />
                <h1>Thank You !</h1>
                <p className='w-75 mx-auto'>Thank You for your purchase. We have received your order and we will ship it as soon as possible. Feel free to contact us at contact@nstee.in for all your questions and queries.</p>
                <Link to="/">
                  <button className="backto-home">Back to Home</button>
                </Link>
            </div>
        </div>
    </div>
    </>
  )
}
