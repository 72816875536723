import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import Cart from "../../pages/cart/cart";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
export default function Header(props) {
  const [scrolled, setScrolled] = useState(false);
  // const userid = localStorage.getItem('token')
  const [userid, setUserId] = useState(localStorage.getItem("token"));
  const [userlogin, setuserlogin] = useState();
  const [user, setuser] = useState();
  useEffect(() => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage

      if (token) {
        // Check if token exists
        const decoded = jwtDecode(token); // Decode the token
        setuser(decoded); // Set the decoded user data
      } else {
        setuser("");
      }
    } catch (error) {
      console.error("Error decoding the token:", error); // Handle any errors
    }
  }, []);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const fetch_token = () => {
    setUserId(localStorage.getItem("token"));
    try {
        const token = localStorage.getItem("token"); // Get token from localStorage
  
        if (token) {
          // Check if token exists
          const decoded = jwtDecode(token); // Decode the token
          setuser(decoded); // Set the decoded user data
        } else {
          setuser("");
        }
      } catch (error) {
        console.error("Error decoding the token:", error); // Handle any errors
      }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let headerClasses = ["navbar", "navbar-expand-lg", "fixed-top"];
  if (scrolled) {
    headerClasses.push("scrolled");
  }
  const headerRef = useRef(null);
  const headeroverlayRef = useRef(null);

  const hidemenu = () => {
    fetch_token()
    if (headerRef.current.classList.contains("sidemenu-header-hide")) {
      headerRef.current.classList.remove("sidemenu-header-hide");
    } else {
      headerRef.current.classList.add("sidemenu-header-hide");
    }

    if (headeroverlayRef.current.classList.contains("header-overlay-hide")) {
      headeroverlayRef.current.classList.remove("header-overlay-hide");
    } else {
      headeroverlayRef.current.classList.add("header-overlay-hide");
    }
  };

  const logout = () => {
    const cookies = new Cookies();
    // console.log('logout is working fine')
    localStorage.removeItem("token");
  };
  return (
    <>
      <nav className={headerClasses.join(" ")}>
        <div className="div d-flex">
          <div
            onClick={hidemenu}
            className={scrolled ? "text-dark empty" : "text-white empty"}
          >
            <i className="fa-solid fa-bars"></i>
          </div>
          <Link
            className={
              scrolled
                ? "text-dark text-decoration-none"
                : "text-white text-decoration-none"
            }
            to="/shop"
          >
            Shop
          </Link>
        </div>
        <div className="headerlogo-div">
          <Link to="/">
            <img
              src={
                scrolled
                  ? "/Logos/nsteelogoblack.webp"
                  : "/Logos/nsteelogowhit.webp"
              }
              width={80}
              alt="Logo"
              className="logo"
            />
          </Link>
        </div>
        <div className="headercart-div">
          <Cart scroll={scrolled} passfunction={props.passfunction} />
        </div>
        <div className="sidemenu-header sidemenu-header-hide" ref={headerRef}>
          <div className="text-start" onClick={fetch_token}>
            <i className="fa-solid fa-xmark" onClick={hidemenu}></i>
          </div>
          <ul>
            <Link onClick={hidemenu} to="/">
              <li>Home</li>
            </Link>
            <Link onClick={hidemenu} to="/shop">
              <li>Shop</li>
            </Link>
            
            {/* {user && user?.vendor_id == 0 ? (
              
            ) : (
              ""
            )} */}
            {user?.vendor_id > 0 && (
              <Link to="/vendor/dashboard/" onClick={hidemenu}>
                <li>Admin</li>
              </Link>
            )}

            {user?.vendor_id < 1 && (
              <Link to="/user-profile" onClick={hidemenu}>
                <li>{user?.full_name}</li>
              </Link>
            )}
            <li>
              {!userid ? (
                <Link to="/login" onClick={hidemenu}>
                  <p> Login</p>{" "}
                </Link>
              ) : (
                <Link to="/" onClick={hidemenu}>
                  <p onClick={logout}>Logout</p>{" "}
                </Link>
              )}
            </li>
            <Link onClick={hidemenu} to="/track-order">
              <li>Track Your Order</li>
            </Link>
          </ul>
        </div>
        <div
          className="header-overlay header-overlay-hide"
          onClick={hidemenu}
          ref={headeroverlayRef}
        ></div>
      </nav>
    </>
  );
}
