import React, { useState, useEffect } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import Sidebar from './Sidebar';
import { apiurl } from "../urls/url";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";


function OrderDetail() {
  const [user, setUser] = useState({});
  const [order, setOrder] = useState([])
  const [orderItems, setOrderItems] = useState([])
  const param = useParams()
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
        try {
            const decoded = jwtDecode(token); 
            setUser(decoded)
            if (decoded?.vendor_id === 0) {
                navigate('/'); 
            }
        } catch (error) {
            navigate('/login');
        }
    } else {
        navigate('/login');
    }
}, [navigate]);
  
  // console.log("params",param.param)
  // console.log(order)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get(`${apiurl}/vendor/orders/1/${param.param}`)
        const response = await axios.get(`${apiurl}/vendor/orders/${user.vendor_id}/${param.param}`,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token").replace(/^"|"$/g, '')}`, // Use the cleaned token
          }
      })
      .catch(
        (error) => {
          if(error.response){
            if(error.response.status===401){
              localStorage.removeItem("token");
              navigate('/login');
            }
          }
        })
        // console.log(response)
        // console.log(response.data)
        setOrder(response.data);
        setOrderItems(response.data.orderitem);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    // console.log(order);
    if(user.vendor_id){
      fetchData();
    }
  }, [user]);
  return (
  <>
    <div className="container-fluid pt-5 bg-secondary" id="main" >
      <div className="row row-offcanvas row-offcanvas-left h-100 ">
        <Sidebar />
        <div className="col-md-9 col-lg-10 main bg-light mt-4">
          <div className="mb-3 mt-3" style={{ marginBottom: 300 }}>
            <div>
              <main className="mb-5">
                {/* Container for demo purpose */}
                <div className="container px-4">
                  {/* Section: Summary */}
                  <section className="mb-5">
                    <h3 className="mb-3">
                      {" "}
                      <i className="fas fa-shopping-cart text-secondry" /> {order.oid}{" "}
                    </h3>

                    <div className="row gx-xl-5">
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div
                          className="rounded shadow p-3"
                          // style={{ backgroundColor: "#B2DFDB" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <p className="mb-1">Total</p>
                                <h2 className="mb-0">
                                ₹{order?.total}
                                  <span
                                    className=""
                                    style={{ fontSize: "0.875rem" }}
                                  ></span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div
                          className="rounded shadow p-3"
                          // style={{ backgroundColor: "#D1C4E9" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <p className="mb-1">Payment Status</p>
                                <h2 className="mb-0">
                                  {order?.payment_status?.toUpperCase()}

                                  <span
                                    className=""
                                    style={{ fontSize: "0.875rem" }}
                                  ></span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div
                          className="rounded shadow p-3"
                          // style={{ backgroundColor: "#BBDEFB" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <p className="mb-1">Order Status</p>
                                <h2 className="mb-0">
                                  {order.order_status}
                                  <span
                                    className=""
                                    style={{ fontSize: "0.875rem" }}
                                  ></span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div
                          className="rounded shadow p-3"
                          // style={{ backgroundColor: "#bbfbeb" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <p className="mb-1">Shipping Amount</p>
                                <h2 className="mb-0">
                                ₹{order.shipping_amount}
                                  <span
                                    className=""
                                    style={{ fontSize: "0.875rem" }}
                                  ></span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0 mt-5">
                        <div
                          className="rounded shadow p-3"
                          // style={{ backgroundColor: "#bbf7fb" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <p className="mb-1">Tax Fee</p>
                                <h2 className="mb-0">
                                ₹{order.tax_fee}

                                  <span
                                    className=""
                                    style={{ fontSize: "0.875rem" }}
                                  ></span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0 mt-5">
                        <div
                          className="rounded shadow p-3"
                          // style={{ backgroundColor: "#bbf7fb" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <p className="mb-1">Payment Type</p>
                                <h2 className="mb-0">
                                {order?.razorpay_order_id ? (
                                    "Prepaid"
                                    ) : (
                                    "COD"
                                    )}
                                  <span
                                    className=""
                                    style={{ fontSize: "0.875rem" }}
                                  ></span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0 mt-5">
                        <div
                          className="rounded shadow p-3"
                          // style={{ backgroundColor: "#eebbfb" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <p className="mb-1">Service Fee</p>
                                <h2 className="mb-0">
                                ₹{order.service_fee}
                                  <span
                                    className=""
                                    style={{ fontSize: "0.875rem" }}
                                  ></span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0 mt-5">
                        <div
                          className="rounded shadow p-3"
                          // style={{ backgroundColor: "#bbc5fb" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="">
                                <p className="mb-1">Discount Fee</p>
                                <h2 className="mb-0">
                                  ₹{order.saved}
                                  <span
                                    className=""
                                    style={{ fontSize: "0.875rem" }}
                                  ></span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* Section: Summary */}
                  {/* Section: MSC */}
                  <div className="container mt-5">
                    <div className="row rounded shadow p-3">
                      <div className="ol-lg-12 mb-4 mb-lg-0">
                        <h3>Personal Information</h3>
                        <div><span style={{fontWeight:"bolder"}}>Name:</span>&nbsp;{order?.full_name}</div>
                        <div><span style={{fontWeight:"bolder"}}>Date:</span>&nbsp;{new Date(order?.date).toLocaleDateString()}</div>
                        <div><span style={{fontWeight:"bolder"}}>Phone No:</span>&nbsp;{order?.mobile}</div>
                        <div><span style={{fontWeight:"bolder"}}>Email:</span>&nbsp;{order?.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="container mt-5">
                    <div className="row rounded shadow p-3">
                      <div className="ol-lg-12 mb-4 mb-lg-0">
                        <h3>Billing Address</h3>
                        <div><span style={{fontWeight:"bolder"}}>Adress:</span>&nbsp;{order?.billing_address}</div>
                        <div><span style={{fontWeight:"bolder"}}>City:</span>&nbsp;{order?.billing_city}</div>
                        <div><span style={{fontWeight:"bolder"}}>State:</span>&nbsp;{order?.billing_state}</div>
                        <div><span style={{fontWeight:"bolder"}}>Country:</span>&nbsp;{order?.billing_country}</div>
                      </div>
                    </div>
                  </div>
                  <div className="container mt-5">
                    <div className="row rounded shadow p-3">
                      <div className="ol-lg-12 mb-4 mb-lg-0">
                        <h3>Shipping Address</h3>
                        <div><span style={{fontWeight:"bolder"}}>Adress:</span>&nbsp;{order?.shipping_address}</div>
                        <div><span style={{fontWeight:"bolder"}}>City:</span>&nbsp;{order?.shipping_city}</div>
                        <div><span style={{fontWeight:"bolder"}}>State:</span>&nbsp;{order?.shipping_state}</div>
                        <div><span style={{fontWeight:"bolder"}}>Country:</span>&nbsp;{order?.shipping_country}</div>
                      </div>
                    </div>
                  </div>
                  <section className="">
                    <div className="row rounded shadow p-3">
                      <div className="col-lg-12 mb-4 mb-lg-0 table-responsive">
                        <table className="table align-middle mb-0 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th>Product</th>
                              <th>Size</th>
                              <th>Price</th>
                              <th>Qty</th>
                              <th>Total</th>
                              <th className='text-danger'>Discount</th>
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {orderItems?.map((order, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={order?.product?.image}
                                      style={{ width: 80, height: 80, objectFit: "cover", borderRadius: 10 }}
                                      alt=""
                                    />
                                    <Link to={`/product/${order.product.slug}`} className="fw-bold text-dark ms-2 mb-0 text-decoration-none">
                                      {order?.product?.title}
                                    </Link>
                                  </div>
                                </td>
                                <td>{order.size}</td>
                                <td>
                                  <p className="fw-normal mb-1">₹{order.product.price}</p>
                                </td>
                                <td>
                                  <p className="fw-normal mb-1">{order.qty}</p>
                                </td>
                                <td>
                                  <span className="fw-normal mb-1">₹{order.sub_total}</span>
                                </td>
                                <td>
                                  <span className="fw-normal mb-1 text-danger"> ₹{order.saved}</span>
                                </td>
                                {/* <td>
                                  {order.tracking_id == null || order.tracking_id == 'undefined'
                                    ? <Link class="btn btn-dark" to={`/vendor/orders/${param.param}/${order.id}/`}> Add Tracking <i className='fas fa-plus'></i></Link>
                                    : <Link class="btn btn-dark" to={`/vendor/orders/${param.param}/${order.id}/`}> Edit Tracking <i className='fas fa-edit'></i></Link>
                                  }
                                </td> */}
                              </tr>
                            ))}
                            {orderItems.length < 1 &&
                              <h5 className='mt-4'>No Order Item</h5>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default OrderDetail